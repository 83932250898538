
export const SET_CURRENT_PAGES = "SET_CURRENT_PAGES";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SAVE_FILTER_DATA_SOURCE = "SAVE_FILTER_DATA_SOURCE";
export const SAVE_SORT_VALUE = "SAVE_SORT_VALUE";
export const SET_FILTER_CHANGED = "SET_FILTER_CHANGED";
export const SET_IS_EDITING = "SET_IS_EDITING";
export const SET_PAGE_DETAILS = "SET_PAGE_DETAILS";
const actions = {
	saveSortValue: (sortValue) => (dispatch) => {
		dispatch({
			type: SAVE_SORT_VALUE,
			payload: {
				sortValue: sortValue
			}
		});

	},
	setIsEditing: (page, data) => (dispatch) => {
		dispatch({
			type: SET_IS_EDITING,
			payload: {
				page,
				isEditing: data
			}
		});

	},
	setPageDetails: (page, currentPage, totalPages, totalRecords) => (dispatch) => {
		dispatch({
			type: "SET_PAGE_DETAILS",
			payload: {
				page,
				currentPage,
				totalPages,
				totalRecords
			}
		});

	},
	saveFilterDataSource: (dataSource) => (dispatch) => {
		dispatch({
			type: SAVE_FILTER_DATA_SOURCE,
			payload: {
				filterDataSource: dataSource
			}
		});

	},
	setFilterChanged: (values) => (dispatch) => {
		dispatch({
			type: SET_FILTER_CHANGED,
			payload: {
				filterChanged: values
			}
		});

	},
	saveSearch: (searchValue) => (dispatch) => {
		dispatch({
			type: SET_SEARCH_VALUE,
			payload: {
				searchValue: searchValue
			}
		});

	},

	setCurrentPages: (key, page, pageSize) => (dispatch) => {
		dispatch({
			type: SET_CURRENT_PAGES,
			payload: {
				key: key,
				page: page,
				pageSize: pageSize
			}
		});
	},

};

export default actions;
