import {
	ADD_TAX_SUCCESS,
	GET_ALL_TAXES_SUCCESS,
	GET_TAX_SUCCESS,
	UPDATE_TAX_SUCCESS,
	DELETE_TAX_SUCCESS,
	ADD_TAX,
	UPDATE_TAX,
	GET_ALL_TAXES,
	GET_TAX,
	DELETE_TAX,
	GET_ALL_TAXES_LIST

} from "../actions/Tax.action";

const initialState = {
	addTaxLoading: false,
	getTaxLoading: false,
	getTaxesLoading: false,
	updateTaxLoading: false,
	deleteTaxLoading: false,
	taxes: [],
	taxesList: [],
	currentTax: "",
};

const RESET_LOADING="RESET_LOADING"
function branch(state = initialState, action) {
	switch (action.type) {

		case RESET_LOADING:
			return Object.assign({}, state, {
				addTaxLoading: false,
				getTaxLoading: false,
				getTaxesLoading: false,
				updateTaxLoading: false,
				deleteTaxLoading: false,
			})
		case ADD_TAX_SUCCESS:
			return Object.assign({}, state, {
				taxes: action.payload.taxes,
			});
		case ADD_TAX:
			return Object.assign({}, state, {
				addTaxLoading: action.payload.addTaxLoading,
			});

		case GET_ALL_TAXES_SUCCESS:
			return Object.assign({}, state, {
				taxes: action.payload.taxes,
				currentTax: "",
			});
		case GET_ALL_TAXES_LIST:
			return Object.assign({}, state, {
				taxesList: action.payload.taxesList,
				currentTax: "",
			});
		case GET_ALL_TAXES:
			return Object.assign({}, state, {
				getTaxesLoading: action.payload.getTaxesLoading,

			});

		case GET_TAX_SUCCESS:
			return Object.assign({}, state, {
				currentTax: action.payload.currentTax,
			});
		case GET_TAX:
			return Object.assign({}, state, {
				getTaxLoading: action.payload.getTaxLoading,
			});

		case UPDATE_TAX_SUCCESS:
			return Object.assign({}, state, {
				taxes: action.payload.taxes,
			});
		case UPDATE_TAX:
			return Object.assign({}, state, {
				updateTaxLoading: action.payload.updateTaxLoading,
			});

		case DELETE_TAX_SUCCESS:
			return Object.assign({}, state, {
				taxes: action.payload.taxes,
			});
		case DELETE_TAX:
			return Object.assign({}, state, {
				deleteTaxLoading: action.payload.deleteTaxLoading,
			});

		default:
			return state;
	}
}

export default branch;
