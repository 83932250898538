import {
	ADD_COMPANY,
	ADD_COMPANY_SUCCESS,
	GET_ALL_COMPANIES_SUCCESS,
	GET_ALL_COMPANIES,
	GET_COMPANY_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY,
	DELETE_COMPANY_SUCCESS,
	ADD_OUTLET,
	ADD_OUTLET_SUCCESS,
	GET_ALL_OUTLETS,
	GET_ALL_OUTLETS_LIST,
	GET_ALL_OUTLETS_SUCCESS,
	GET_OUTLET,
	GET_OUTLET_SUCCESS,
	UPDATE_OUTLET_SUCCESS,
	DELETE_OUTLET_SUCCESS,
	DELETE_COMPANY,
	DELETE_OUTLET,
	UPDATE_OUTLET,
	GET_COMPANY,
	UPLOAD_LOGO,
	GET_ALL_COMPANIES_LIST

} from "../actions/Companies.action";

const RESET_LOADING="RESET_LOADING"

const initialState = {
	addOutletLoading: false,
	updateOutletLoading: false,
	getOutletLoading: false,
	getOutletsLoading: false,

	addCompanyLoading: false,
	updateCompanyLoading: false,
	getCompanyLoading: false,
	getCompaniesLoading: false,

	deleteCompanyLoading: false,
	deleteOutletLoading: false,

	companies: [],
	companiesList: [],
	currentCompany: "",
	outlets: [],
	outletsList: [],
	currentOutlet: "",
	addedCompany: "",

	logoUrl: ""
};

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				addOutletLoading: false,
				updateOutletLoading: false,
				getOutletLoading: false,
				getOutletsLoading: false,

				addCompanyLoading: false,
				updateCompanyLoading: false,
				getCompanyLoading: false,
				getCompaniesLoading: false,

				deleteCompanyLoading: false,
				deleteOutletLoading: false,
			})
		case UPLOAD_LOGO:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl,
			});
		case ADD_OUTLET:
			return Object.assign({}, state, {
				addOutletLoading: action.payload.addOutletLoading,
			});
		case UPDATE_OUTLET:
			return Object.assign({}, state, {
				updateOutletLoading: action.payload.updateOutletLoading,
			});
		case GET_OUTLET:
			return Object.assign({}, state, {
				getOutletLoading: action.payload.getOutletLoading,
			});
		case GET_ALL_OUTLETS:
			return Object.assign({}, state, {
				getOutletsLoading: action.payload.getOutletsLoading,
			});
		case GET_ALL_OUTLETS_LIST:
			return Object.assign({}, state, {
				outletsList: action.payload.outletsList,
			});

		case ADD_COMPANY:
			return Object.assign({}, state, {
				addCompanyLoading: action.payload.addCompanyLoading,
			});
		case UPDATE_COMPANY:
			return Object.assign({}, state, {
				updateCompanyLoading: action.payload.updateCompanyLoading,
			});
		case GET_COMPANY:
			return Object.assign({}, state, {
				getCompanyLoading: action.payload.getCompanyLoading,

			});
		case GET_ALL_COMPANIES:
			return Object.assign({}, state, {
				getCompaniesLoading: action.payload.getCompaniesLoading,
			});

		case GET_ALL_COMPANIES_LIST:
			return Object.assign({}, state, {
				companiesList: action.payload.companiesList,
			});

		case ADD_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				companies: action.payload.companies,
				addedCompany: action.payload.addedCompany
			});

		case GET_ALL_COMPANIES_SUCCESS:
			return Object.assign({}, state, {
				companies: action.payload.companies,
				currentCompany: "",
			});

		case GET_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				currentCompany: action.payload.currentCompany,
				logoUrl: action.payload.currentCompany?.logo
			});

		case UPDATE_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				companies: action.payload.companies,
			});

		case DELETE_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				companies: action.payload.companies,
			});
		case DELETE_COMPANY:
			return Object.assign({}, state, {
				deleteCompanyLoading: action.payload.deleteCompanyLoading,
			});

		case ADD_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				outlets: action.payload.outlets,
			});

		case GET_ALL_OUTLETS_SUCCESS:
			return Object.assign({}, state, {
				outlets: action.payload.outlets,
				currentOutlet: "",
			});

		case GET_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				currentOutlet: action.payload.currentOutlet,
			});

		case UPDATE_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				outlets: action.payload.outlets,
			});

		case DELETE_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				outlets: action.payload.outlets,
			});
		case DELETE_OUTLET:
			return Object.assign({}, state, {
				deleteOutletLoading: action.payload.deleteOutletLoading,
			});
		default:
			return state;
	}
}

export default branch;
