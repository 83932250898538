export const findParentLayout = (routes, targetRoute) => {
    for (const route of routes) {
        if (route?.type === "subMenu") {
            for (const subroute of route?.content) {
                if (subroute.type === "layout" && subroute.content && subroute.content.length > 0) {
                    const foundChild = subroute.content.find(child => child.route === targetRoute);
                    if (foundChild) {
                        return subroute;
                    } else {
                        const parentLayout = findParentLayout(subroute.content, targetRoute);
                        if (parentLayout) {
                            return parentLayout;
                        }
                    }
                }
            }
        }
    }
    return null; // Return null if the target route is not found within any layout container
};