import {
	GET_STATEMENTS,
	GET_STATEMENTS_SUCCESS,

} from "../actions/Statements.action";

const RESET_LOADING = "RESET_LOADING"

const initialState = {
	getStatementsLoading: false,
	statements: [],
	lastPayment: {},
	previousBalance: 0,
	balance: 0

};

function branch(state = initialState, action) {

	switch (action.type) {

		case RESET_LOADING:
			return Object.assign({}, state, {
				getStatementsLoading: false,
			});

		case GET_STATEMENTS_SUCCESS:
			return Object.assign({}, state, {
				statements: action.payload.statements,
				lastPayment: action.payload.lastPayment,
				previousBalance: action.payload.previousBalance,
				balance: action.payload.balance
			});

		case GET_STATEMENTS:
			return Object.assign({}, state, {
				getStatementsLoading: action.payload.getStatementsLoading,
			});

		default:
			return state;
	}
}

export default branch;
