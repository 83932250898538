import React from "react";
import CustomMenu from "../components/Menus/CustomMenu";

function Sidenav() {
	

	return <CustomMenu />;
}

export default Sidenav;
