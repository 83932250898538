import {
	ADD_LEAD_SOURCE_SUCCESS,
	ADD_LEAD_SOURCE,
	DELETE_LEAD_SOURCE,
	GET_ALL_LEAD_SOURCE_SUCCESS,
	GET_ALL_LEAD_SOURCE,
	GET_LEAD_SOURCE_SUCCESS,
	GET_LEAD_SOURCE,
	UPDATE_LEAD_SOURCE_SUCCESS,
	UPDATE_LEAD_SOURCE,
	DELETE_LEAD_SOURCE_SUCCESS,
	GET_LINKED_LEADS,
	GET_LINKED_LEADS_SUCCESS,
	GET_SALES_LEADS,
	GET_SALES_LEADS_SUCCESS,
	ADD_LEAD_PROCESS_SUCCESS,
	ADD_LEAD_PROCESS,
	GET_ALL_LEAD_PROCESS_SUCCESS,
	GET_ALL_LEAD_PROCESS,
	DELETE_LEAD_PROCESS,
	GET_LEAD_PROCESS_SUCCESS,
	GET_LEAD_PROCESS,
	DELETE_LEAD,
	UPDATE_LEAD_PROCESS_SUCCESS,
	UPDATE_LEAD_PROCESS,
	DELETE_LEAD_PROCESS_SUCCESS,
	ADD_SALE_REGION_SUCCESS,
	ADD_SALE_REGION,
	DELETE_SALE_REGION,
	GET_ALL_SALE_REGIONS_SUCCESS,
	GET_ALL_SALE_REGIONS,
	GET_SALE_REGION_SUCCESS,
	GET_SALE_REGION,
	UPDATE_SALE_REGION_SUCCESS,
	UPDATE_SALE_REGION,
	DELETE_SALE_REGION_SUCCESS,
	ADD_LEAD_SUCCESS,
	ADD_LEAD,
	GET_ALL_LEADS_SUCCESS,
	GET_ALL_LEADS,
	GET_ALL_LEADS_LIST,
	GET_LEAD_SUCCESS,
	GET_LEAD,
	UPDATE_LEAD_SUCCESS,
	UPDATE_LEAD,
	DELETE_LEAD_SUCCESS,
	GET_LEAD_QUOTATIONS,
	GET_LEAD_QUOTATIONS_SUCCESS,

	GET_ALL_LEAD_PROCESS_LIST,
	GET_ALL_LEAD_SOURCE_LIST,
	GET_ALL_SALE_REGIONS_LIST,
	GET_ALL_UNLINKED_LEADS,
	GET_ALL_UNLINKED_LEADS_SUCCESS,
	GET_LINKED_LEADS_CUSTOMERS_SUCCESS
} from "../actions/Leads.action";
const RESET_LOADING = "RESET_LOADING"
const initialState = {
	addLeadLoading: false,
	getLeadsLoading: false,
	getUnlinkedLeadsLoading: false,
	getLeadLoading: false,
	updateLeadLoading: false,
	deleteLeadLoading: false,

	getSalesLeadsLoading: false,
	getLinkedLeadsLoading: false,

	addLeadSourceLoading: false,
	getLeadSourcesLoading: false,
	getLeadSourceLoading: false,
	updateLeadSourceLoading: false,
	deleteLeadSourceLoading: false,

	addLeadProcessLoading: false,
	getLeadProcessesLoading: false,
	getLeadProcessLoading: false,
	updateLeadProcessLoading: false,
	deleteLeadProcessLoading: false,

	addSaleRegionLoading: false,
	getSalesRegionLoading: false,
	getSaleRegionLoading: false,
	updateSaleRegionLoading: false,
	deleteSaleRegionLoading: false,

	getLeadQuotationsLoading: false,

	linkedLeads: {},
	leadQuotations: [],
	leadSource: [],
	leadSourceList: [],
	currentLeadSource: "",
	salesLeads: [],

	leadProcess: [],
	leadProcessList: [],
	currentLeadProcess: "",
	saleRegions: [],
	saleRegionsList: [],
	currentSaleRegion: "",
	leads: [],
	leadsList: [],
	unlinkedLeads: [],
	currentLead: "",

	addedLead: "",
	addedSource: "",
	addedProcess: "",

};

function branch(state = initialState, action) {
	switch (action.type) {

		case RESET_LOADING:
			return Object.assign({}, state, {
				getLinkedLeadsLoading: false,
				addLeadLoading: false,
				getLeadsLoading: false,
				getLeadLoading: false,
				updateLeadLoading: false,
				deleteLeadLoading: false,

				getSalesLeadsLoading: false,

				addLeadSourceLoading: false,
				getLeadSourcesLoading: false,
				getLeadSourceLoading: false,
				updateLeadSourceLoading: false,
				deleteLeadSourceLoading: false,

				addLeadProcessLoading: false,
				getLeadProcessesLoading: false,
				getLeadProcessLoading: false,
				updateLeadProcessLoading: false,
				deleteLeadProcessLoading: false,

				addSaleRegionLoading: false,
				getSalesRegionLoading: false,
				getSaleRegionLoading: false,
				updateSaleRegionLoading: false,
				deleteSaleRegionLoading: false,

				getLeadQuotationsLoading: false,
			})

		case GET_LINKED_LEADS_SUCCESS:
			const linkedLeads = action.payload.linkedLeads?.reduce((accumulator, currentValue) => {
				accumulator[currentValue?.uuid] = currentValue;
				return accumulator;
			}, {})
			return Object.assign({}, state, {
				linkedLeads: linkedLeads,
			});

		case GET_LINKED_LEADS:
			return Object.assign({}, state, {
				getLinkedLeadsLoading: action.payload.getLinkedLeadsLoading,
			});
		case GET_SALES_LEADS:
			return Object.assign({}, state, {
				getSalesLeadsLoading: action.payload.getSalesLeadsLoading,

			});
		case GET_SALES_LEADS_SUCCESS:
			return Object.assign({}, state, {
				salesLeads: action.payload.leads,
			});

		case ADD_LEAD_SOURCE_SUCCESS:
			return Object.assign({}, state, {
				leadSource: action.payload.leadSource,
				addedSource: action.payload.addedSource
			});

		case ADD_LEAD_SOURCE:
			return Object.assign({}, state, {
				addLeadSourceLoading: action.payload.addLeadSourceLoading,
			});

		case GET_ALL_LEAD_SOURCE_SUCCESS:
			return Object.assign({}, state, {
				leadSource: action.payload.leadSource,
				currentLeadSource: "",
			});

		case GET_ALL_LEAD_SOURCE_LIST:
			return Object.assign({}, state, {
				leadSourceList: action.payload.leadSourceList,
				currentLeadSource: "",
			});
		case GET_ALL_LEAD_SOURCE:
			return Object.assign({}, state, {
				getLeadSourcesLoading: action.payload.getLeadSourcesLoading,
			});


		case GET_LEAD_QUOTATIONS:
			return Object.assign({}, state, {
				getLeadQuotationsLoading: action.payload.getLeadQuotationsLoading,

			});
		case GET_LEAD_QUOTATIONS_SUCCESS:
			return Object.assign({}, state, {
				leadQuotations: action.payload.leadQuotations,
			});
		case GET_LEAD_SOURCE_SUCCESS:
			return Object.assign({}, state, {
				currentLeadSource: action.payload.currentLeadSource,
			});
		case GET_LEAD_SOURCE:
			return Object.assign({}, state, {
				getLeadSourceLoading: action.payload.getLeadSourceLoading,
			});

		case UPDATE_LEAD_SOURCE_SUCCESS:
			return Object.assign({}, state, {
				leadSource: action.payload.leadSource,
			});

		case UPDATE_LEAD_SOURCE:
			return Object.assign({}, state, {
				updateLeadSourceLoading: action.payload.updateLeadSourceLoading,
			});

		case DELETE_LEAD_SOURCE_SUCCESS:
			return Object.assign({}, state, {
				leadSource: action.payload.leadSource,
			});
		case DELETE_LEAD_SOURCE:
			return Object.assign({}, state, {
				deleteLeadSourceLoading: action.payload.deleteLeadSourceLoading,
			});


		case ADD_LEAD_PROCESS_SUCCESS:
			return Object.assign({}, state, {
				leadProcess: action.payload.leadProcess,
				addedProcess: action.payload.addedProcess
			});

		case ADD_LEAD_PROCESS:
			return Object.assign({}, state, {
				addLeadProcessLoading: action.payload.addLeadProcessLoading,
			});

		case GET_ALL_LEAD_PROCESS_SUCCESS:
			return Object.assign({}, state, {
				leadProcess: action.payload.leadProcess,
				currentLeadProcess: "",
			});

		case GET_ALL_LEAD_PROCESS_LIST:
			return Object.assign({}, state, {
				leadProcessList: action.payload.leadProcessList,
				currentLeadProcess: "",
			});
		case GET_ALL_LEAD_PROCESS:
			return Object.assign({}, state, {
				getLeadProcessesLoading: action.payload.getLeadProcessesLoading,
			});

		case GET_LEAD_PROCESS_SUCCESS:
			return Object.assign({}, state, {
				currentLeadProcess: action.payload.currentLeadProcess,
			});
		case GET_LEAD_PROCESS:
			return Object.assign({}, state, {
				getLeadProcessLoading: action.payload.getLeadProcessLoading,
			});

		case UPDATE_LEAD_PROCESS_SUCCESS:
			return Object.assign({}, state, {
				leadProcess: action.payload.leadProcess,
			});

		case UPDATE_LEAD_PROCESS:
			return Object.assign({}, state, {
				updateLeadProcessLoading: action.payload.updateLeadProcessLoading,
			});

		case DELETE_LEAD_PROCESS_SUCCESS:
			return Object.assign({}, state, {
				leadProcess: action.payload.leadProcess,
			});
		case DELETE_LEAD_PROCESS:
			return Object.assign({}, state, {
				deleteLeadProcessLoading: action.payload.deleteLeadProcessLoading,
			});

		case ADD_SALE_REGION_SUCCESS:
			return Object.assign({}, state, {
				saleRegions: action.payload.saleRegions,
			});

		case ADD_SALE_REGION:
			return Object.assign({}, state, {
				addSaleRegionLoading: action.payload.addSaleRegionLoading,
			});

		case GET_ALL_SALE_REGIONS_SUCCESS:
			return Object.assign({}, state, {
				saleRegions: action.payload.saleRegions,
				currentSaleRegion: "",
			});

		case GET_ALL_SALE_REGIONS_LIST:
			return Object.assign({}, state, {
				saleRegionsList: action.payload.saleRegionsList,
				currentSaleRegion: "",
			});
		case GET_ALL_SALE_REGIONS:
			return Object.assign({}, state, {
				getSalesRegionLoading: action.payload.getSalesRegionLoading,
			});

		case GET_SALE_REGION_SUCCESS:
			return Object.assign({}, state, {
				currentSaleRegion: action.payload.currentSaleRegion,
			});

		case GET_SALE_REGION:
			return Object.assign({}, state, {
				getSaleRegionLoading: action.payload.getSaleRegionLoading,
			});

		case UPDATE_SALE_REGION_SUCCESS:
			return Object.assign({}, state, {
				saleRegions: action.payload.saleRegions,
			});
		case UPDATE_SALE_REGION:
			return Object.assign({}, state, {
				updateSaleRegionLoading: action.payload.updateSaleRegionLoading,
			});

		case DELETE_SALE_REGION_SUCCESS:
			return Object.assign({}, state, {
				saleRegions: action.payload.saleRegions,
			});
		case DELETE_SALE_REGION:
			return Object.assign({}, state, {
				deleteSaleRegionLoading: action.payload.deleteSaleRegionLoading,
			});
		case ADD_LEAD_SUCCESS:
			return Object.assign({}, state, {
				leads: action.payload.leads,
				addedLead: action.payload.addedLead
			});

		case ADD_LEAD:
			return Object.assign({}, state, {
				addLeadLoading: action.payload.addLeadLoading,
			});

		case GET_ALL_LEADS_SUCCESS:
			return Object.assign({}, state, {
				leads: action.payload.leads,
				currentLead: "",
			});
		case GET_ALL_UNLINKED_LEADS_SUCCESS:
			return Object.assign({}, state, {
				unlinkedLeads: action.payload.unlinkedLeads,

			});
		case GET_ALL_LEADS_LIST:
			return Object.assign({}, state, {
				leadsList: action.payload.leadsList,
				currentLead: "",
			});


		case GET_ALL_LEADS:
			return Object.assign({}, state, {
				getLeadsLoading: action.payload.getLeadsLoading,
			});
		case GET_ALL_UNLINKED_LEADS:
			return Object.assign({}, state, {
				getUnlinkedLeadsLoading: action.payload.getUnlinkedLeadsLoading,
			});


		case GET_LEAD_SUCCESS:
			return Object.assign({}, state, {
				currentLead: action.payload.currentLead,
			});
		case GET_LEAD:
			return Object.assign({}, state, {
				getLeadLoading: action.payload.getLeadLoading,
			});

		case UPDATE_LEAD_SUCCESS:
			return Object.assign({}, state, {
				leads: action.payload.leads,
				currentLead: action?.payload?.updatedLead
			});
		case UPDATE_LEAD:
			return Object.assign({}, state, {
				updateLeadLoading: action.payload.updateLeadLoading,
			});

		case DELETE_LEAD_SUCCESS:
			return Object.assign({}, state, {
				leads: action.payload.leads,
			});
		case DELETE_LEAD:
			return Object.assign({}, state, {
				deleteLeadLoading: action.payload.deleteLeadLoading,
			});
		default:
			return state;
	}
}

export default branch;
