import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import commonActions from "./redux/actions/Common.action";
import authActions from "./redux/actions/Auth.actions";
import Loading from "./components/Loader/Loading";
import { getMe } from "./config/config";

// PAGES
const ErrorPage = lazy(() => import("./pages/error/Error"));
const RESET_LOADING = "RESET_LOADING";


export const App = ({
	props,
	getMe,
	getGlobalConfig,
	getMyAccessPermissions,
	accessToken,
	resetLoading,
	setPrevUrl
}) => {

	useEffect(() => {
		resetLoading();
		getGlobalConfig();
		setPrevUrl(window?.location?.href);
	}, []);

	useEffect(() => {
		if (accessToken) {
			getMyAccessPermissions();
			getMe();
		};
	}, [accessToken]);

	return (
		<Suspense
			fallback={
				<Loading />
			}
		>
			<Switch>
				{/*ErrorPage*/}
				<Route exact path="/error">
					<ErrorPage />
				</Route>

				{/* Login and Signup*/}
				{/* <Route exact path="/login" component={LogIn} /> */}

				{/* Main Content*/}
				{/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
				<Route path="/" render={(props) => <DefaultLayout {...props} />} /> */}
				<Route path="/auth">
					<ToastContainer />
					<AuthLayout />
				</Route>
				<Route path="/">
					<ToastContainer />
					<DefaultLayout />
				</Route>

				<Route>
					<Redirect to="/error" />
				</Route>
			</Switch>
		</Suspense>
	);
};

const mapStateToProps = (state) => ({
	accessToken: state.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
	getGlobalConfig: () => {
		dispatch(commonActions.getGlobalConfig());
	},
	getMyAccessPermissions: () => {
		dispatch(authActions.getMyAccessPermissions());
	},
	getMe: () => {
		dispatch(authActions.getMe());
	},
	resetLoading: () => dispatch({
		type: RESET_LOADING
	}),
	setPrevUrl: (data) => {
		dispatch(authActions.setPrevUrl(data))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
