import {
	DELETE_CURRENCY,
	DELETE_CURRENCY_SUCCESS,
	ADD_CURRENCY,
	ADD_CURRENCY_SUCCESS,
	UPDATE_CURRENCY,
	UPDATE_CURRENCY_SUCCESS,
	GET_CURRENCIES,
	GET_CURRENCIES_SUCCESS,
	GET_CURRENCY,
	GET_CURRENCY_SUCCESS,
	UPLOAD_CURRENCY_LOGO,
	GET_CURRENCIES_LIST

} from "../actions/Currency.action";

const RESET_LOADING = "RESET_LOADING"

const initialState = {
	addCurrencyLoading: false,
	updateCurrencyLoading: false,
	getCurrencyLoading: false,
	getCurrenciesLoading: false,
	deleteCurrencyLoading: false,

	currencies: [],
	currenciesList: [],
	addedCurrency: "",
	currentCurrency: "",
	logoUrl: ""

};

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				addCurrencyLoading: false,
				updateCurrencyLoading: false,
				getCurrencyLoading: false,
				getCurrenciesLoading: false,
				deleteCurrencyLoading: false,

			})

		case ADD_CURRENCY_SUCCESS:
			return Object.assign({}, state, {
				currencies: action.payload.currencies,
				addedCurrency: action.payload.addedCurrency
			});
		case ADD_CURRENCY:
			return Object.assign({}, state, {
				addCurrencyLoading: action.payload.addCurrencyLoading,
			});

		case GET_CURRENCIES_SUCCESS:
			return Object.assign({}, state, {
				currencies: action.payload.currencies,
			});

			case GET_CURRENCIES_LIST:
			return Object.assign({}, state, {
				currenciesList: action.payload.currenciesList,
			});

		case GET_CURRENCIES:
			return Object.assign({}, state, {
				getCurrenciesLoading: action.payload.getCurrenciesLoading,

			});


		case GET_CURRENCY_SUCCESS:
			return Object.assign({}, state, {
				currentCurrency: action.payload.currentCurrency,
				logoUrl: action.payload.currentCurrency.logo
			});
		case UPLOAD_CURRENCY_LOGO:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl
			});

		case GET_CURRENCY:
			return Object.assign({}, state, {
				getCurrencyLoading: action.payload.getCurrencyLoading,

			});

		case UPDATE_CURRENCY_SUCCESS:
			return Object.assign({}, state, {
				currencies: action.payload.currencies,
			});
		case UPDATE_CURRENCY:
			return Object.assign({}, state, {
				editCurrencyLoading: action.payload.editCurrencyLoading,
			});

		case DELETE_CURRENCY_SUCCESS:
			return Object.assign({}, state, {
				currencies: action.payload.currencies,
			});
		case DELETE_CURRENCY:
			return Object.assign({}, state, {
				deleteCurrencyLoading: action.payload.deleteCurrencyLoading,
			});

		default:
			return state;
	}
}

export default branch;
