import { combineReducers } from "redux";
import auth from "./Auth.reducer";

// import reducers
import filters from "./Filter.reducer"
import invoices from "./Invoices.reducer";
import customers from "./Customers.reducer";
import common from "./Common.reducer";
import products from "./Products.reducer";
import sales from "./Sales.reducer";
import suppliers from "./Suppliers.reducer";
import tax from "./Tax.reducer";
import leads from "./Leads.reducer";
import warehouses from "./Warehouses.reducer";
import users from "./Users.reducer";
import companies from "./Companies.reducer";
import serviceCalls from "./ServiceCalls.reducer";
import languages from "./Language.reducer";
import customerSchema from "./CustomerSchema.reducer";
import businessCard from "./BusinessCard.reducer";
import quotations from "./Quotations.reducer"
import calendarView from "./ServiceCallsCalendarView.reducer"
import contactInfo from "./ContactInfo.reducer"
import roles from "./Roles.reducer"
import table from "./Table.reducer"
import salesCalls from "./SalesTasks.reducer"
import payments from "./Payment.reducer";
import currencies from "./Currency.reducer";
import receipts from "./Receipt.reducer";
import statements from "./Statement.reducer";
import requests from "./Requests.reducer";
// will combine reducer
const reducers = combineReducers({
	statements,
	auth,
	table,
	common,
	products,
	customers,
	sales,
	suppliers,
	salesCalls,
	languages,
	payments,
	currencies,
	receipts,
	tax,
	roles,
	leads,
	warehouses,
	users,
	companies,
	serviceCalls,
	invoices,
	customerSchema,
	businessCard,
	quotations,
	calendarView,
	contactInfo,
	filters,
	requests
});

export default reducers;
