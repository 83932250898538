import {
	addQuotation,
	getAllQuotations,
	deleteQuotation,
	getQuotation,
	getQuotationUsers,
	editQuotation,
	addQuotationStatus,
	updateQuotationStatus,
	deleteQuotationStatus,
	getQuotationStatus,
	getAllQuotationStatus,
	getAllQuotationStatusList,
	getAllQuotationLostReason,
	getAllQuotationLostReasonList,
	getQuotationLostReason,
	addQuotationLostReason,
	updateQuotationLostReason,
	deleteQuotationLostReason,
	getAllQuotationsFilter
} from "../../config/config";
import { toast } from "react-toastify";
export const API_REQUEST = "API_REQUEST";

export const SET_QUOTATION_FILTERS = "SET_QUOTATION_FILTERS";

export const ADD_QUOTATION_STATUS_SUCCESS = "ADD_QUOTATION_STATUS_SUCCESS";
export const GET_ALL_QUOTATION_STATUS_SUCCESS = "GET_ALL_QUOTATION_STATUS_SUCCESS";
export const GET_QUOTATION_STATUS_SUCCESS = "GET_QUOTATION_STATUS_SUCCESS";
export const UPDATE_QUOTATION_STATUS_SUCCESS = "UPDATE_QUOTATION_STATUS_SUCCESS";
export const DELETE_QUOTATION_STATUS_SUCCESS = "DELETE_QUOTATION_STATUS_SUCCESS";

export const ADD_QUOTATION_STATUS = "ADD_QUOTATION_STATUS";
export const GET_ALL_QUOTATION_STATUS = "GET_ALL_QUOTATION_STATUS";
export const GET_QUOTATION_STATUS = "GET_QUOTATION_STATUS";
export const UPDATE_QUOTATION_STATUS = "UPDATE_QUOTATION_STATUS";
export const DELETE_QUOTATION_STATUS = "DELETE_QUOTATION_STATUS";

export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART"
export const EMPTY_PRODUCT_CART = "EMPTY_PRODUCT_CART"
export const CHANGE_PAGE = "CHANGE_PAGE"
export const SET_VIEW = "SET_VIEW"
export const ADD_INVOICE_DISC = "ADD_INVOICE_DISC"
export const GET_ALL_QUOTATIONS = "GET_ALL_QUOTATIONS"
export const GET_QUOTATION_SUCCESS = "GET_QUOTATION_SUCCESS"
export const GET_QUOTATION_USERS = "GET_QUOTATION_USERS"
export const GET_QUOTATION = "GET_QUOTATION"
export const ADD_QUOTATION_SUCCESS = "ADD_QUOTATION_SUCCESS"
export const ADD_QUOTATION = "ADD_QUOTATION"
export const EDIT_QUOTATION_SUCCESS = "EDIT_QUOTATION_SUCCESS"
export const EDIT_QUOTATION = "EDIT_QUOTATION"
export const STORE_QUOTATION = "STORE_QUOTATION"
export const EMPTY_QUOTATION = "EMPTY_QUOTATION"
export const STORE_PRODUCTS = "STORE_PRODUCTS"
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS"
export const DELETE_QUOTATION = "DELETE_QUOTATION"
export const CALCULATE_TAX = "CALCULATE_TAX"
export const CHANGE_PRODUCT_LOADER = "CHANGE_PRODUCT_LOADER"

export const SET_SELECTED_REASON = "SET_SELECTED_REASON"

export const GET_ALL_QUOTATIONS_FILTER_SUCCESS = "GET_ALL_QUOTATIONS_FILTER_SUCCESS"
export const GET_ALL_QUOTATIONS_FILTER = "GET_ALL_QUOTATIONS_FILTER"

export const GET_ALL_QUOTATION_STATUS_LIST = "GET_ALL_QUOTATION_STATUS_LIST";

export const GET_ALL_QUOTATION_LOST_REASON_LIST = "GET_ALL_QUOTATION_LOST_REASON_LIST";
export const ADD_QUOTATION_LOST_REASON_SUCCESS = "ADD_QUOTATION_LOST_REASON_SUCCESS";
export const GET_ALL_QUOTATION_LOST_REASON_SUCCESS = "GET_ALL_QUOTATION_LOST_REASON_SUCCESS";
export const GET_QUOTATION_LOST_REASON_SUCCESS = "GET_QUOTATION_LOST_REASON_SUCCESS";
export const UPDATE_QUOTATION_LOST_REASON_SUCCESS = "UPDATE_QUOTATION_LOST_REASON_SUCCESS";
export const DELETE_QUOTATION_LOST_REASON_SUCCESS = "DELETE_QUOTATION_LOST_REASON_SUCCESS";

export const ADD_QUOTATION_LOST_REASON = "ADD_QUOTATION_LOST_REASON";
export const GET_ALL_QUOTATION_LOST_REASON = "GET_ALL_QUOTATION_LOST_REASON";
export const GET_QUOTATION_LOST_REASON = "GET_QUOTATION_LOST_REASON";
export const UPDATE_QUOTATION_LOST_REASON = "UPDATE_QUOTATION_LOST_REASON";
export const DELETE_QUOTATION_LOST_REASON = "DELETE_QUOTATION_LOST_REASON";


//GET QUOTATION
const getQuotations = (page, limit, status, filters, loading, setPage, setFailedPage, view, dispatch) => {
	if (loading)
		dispatch({
			type: GET_ALL_QUOTATIONS_FILTER,
			payload: {
				getQuotationsFilterLoading: true
			},
		});
	else
		dispatch({
			type: GET_ALL_QUOTATIONS,
			payload: {
				getQuotationsLoading: true
			},
		});
	getAllQuotations(page, limit, status, filters).then((res) => {

		if (loading)
			dispatch({
				type: GET_ALL_QUOTATIONS_FILTER,
				payload: {
					getQuotationsFilterLoading: false
				},
			});
		else
			dispatch({
				type: GET_ALL_QUOTATIONS,
				payload: {
					getQuotationsLoading: false
				},
			});
		if (res?.status === 200) {

			if (typeof setPage === 'function' && view) {
				setPage(page + 1);
			}
			if (typeof setPage === 'function' && !view) {
				setPage();
			}
			dispatch({
				type: "SET_PAGE_DETAILS",
				payload: {
					page: "quotations",
					currentPage: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords
				},
			});
			dispatch({
				type: GET_ALL_QUOTATIONS_FILTER_SUCCESS,
				payload: {
					quotations: res?.data?.quotations,
					page: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords,
					view,
					max: res?.data?.maxNetValue,
					min: res?.data?.minNetValue,
					sum: res?.data?.totalSumValue
				},
			});
		} else if (res?.status === 401) {
			window.location = "/session-ended";
		} else if (res?.status === 403) {
			window.location = "/forbidden";
		} else {
			toast.error(res?.data?.message || "error", { theme: "colored" });
		}
	}).catch(() => {
		if (typeof setFailedPage === 'function') {
			setFailedPage(page)
		}
	});
}

const actions = {

	setView: (data) => (dispatch) => {
		dispatch({ type: SET_VIEW, payload: { view: data } })
	},
	setSelectedReason: (data) => (dispatch) => {
		dispatch({ type: SET_SELECTED_REASON, payload: { selectedReason: data } })
	},
	//ADD PRODUCT TO CART
	addProductToCart: (data) => (dispatch) => {

		dispatch({ type: ADD_PRODUCT_TO_CART, payload: { product: data } })
	},


	changeProductLoader: (data) => (dispatch) => {

		dispatch({ type: CHANGE_PRODUCT_LOADER, payload: { productLoader: data } })
	},



	changePage: (data, flag = false) => (dispatch) => {

		dispatch({ type: CHANGE_PAGE, payload: { page: data, flag: flag } })
	},


	//DELETE PRODUCT FORM CART
	deleteProductFromCart: (data) => (dispatch) => {

		dispatch({ type: DELETE_PRODUCT_FROM_CART, payload: { id: data } })
	},

	//EMPTY PRODUCT CART
	emptyProductCart: () => (dispatch) => {

		dispatch({ type: EMPTY_PRODUCT_CART })
	},
	calculateTax: (data) => (dispatch) => {

		dispatch({ type: CALCULATE_TAX, payload: { data: data } })
	},
	addInvoiceDisc: (data) => (dispatch) => {

		dispatch({ type: ADD_INVOICE_DISC, payload: { invoiceDisc: data } })
	},
	//STORE QUOTATION
	storeQuotation: (data) => (dispatch) => {

		dispatch({ type: STORE_QUOTATION, payload: { data: data } })
	},
	//EMPTY QUOTATION
	emptyQuotation: (data) => (dispatch) => {

		dispatch({ type: EMPTY_QUOTATION })
	},
	//STORE PRODUCTS
	storeProducts: (data) => (dispatch) => {

		dispatch({ type: STORE_PRODUCTS, payload: { data: data } })
	},

	//GET QUOTATION
	getQuotation: (id) => (dispatch) => {
		dispatch({
			type: GET_QUOTATION,
			payload: {
				getQuotationLoading: true,
			},
		});

		getQuotation(id).then((res) => {
			dispatch({
				type: GET_QUOTATION,
				payload: {
					getQuotationLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_QUOTATION_SUCCESS,
					payload: {
						quotation: res?.data?.quotation,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET QUOTATION USERS
	getQuotationUsers: () => (dispatch) => {
		

		getQuotationUsers().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_QUOTATION_USERS,
					payload: {
						salesmen: res?.data?.salesmen,
						users:res?.data?.users
					},
				});
			} 
			else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//FETCH ALL QUOTATIONS
	getAllQuotations: (page, limit, status, filters, loading, setPage, setFailedPage = null, view) => (dispatch) => {

		getQuotations(page, limit, status, filters, loading, setPage, setFailedPage, view, dispatch)
	},
	//FETCH ALL QUOTATIONS
	getAllQuotationsFilter: (filter) => (dispatch) => {

		dispatch({
			type: GET_ALL_QUOTATIONS_FILTER,
			payload: {
				getQuotationsFilterLoading: true
			},
		});

		getAllQuotationsFilter(filter).then((res) => {
			dispatch({
				type: GET_ALL_QUOTATIONS_FILTER,
				payload: {
					getQuotationsFilterLoading: false
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_QUOTATIONS_FILTER_SUCCESS,
					payload: {
						quotations: res?.data?.quotations,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//ADD QUOTATION
	addQuotation: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_QUOTATION,
			payload: {
				addQuotationLoading: true,
			},
		});


		addQuotation(data).then((res) => {
			dispatch({
				type: ADD_QUOTATION,
				payload: {
					addQuotationLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_QUOTATION_SUCCESS,
					payload: {
						quotations: res?.data?.quotations,

					},
				});


				toast.success(res?.data?.message || "Success", { theme: "colored" });
				history.push(`edit-quotation/${res?.data?.addedQuotation?.uuid}`)
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		})
	},
	//EDIT QUOTATION
	editQuotation: (data, id, history) => (dispatch) => {
		dispatch({
			type: EDIT_QUOTATION,
			payload: {
				editQuotationLoading: true,
			},
		});

		editQuotation(data, id).then((res) => {
			dispatch({
				type: EDIT_QUOTATION,
				payload: {
					editQuotationLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: EDIT_QUOTATION_SUCCESS,
					payload: {
						editedQuotation: res?.data?.editedQuotation
					},
				});

				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//DELETE QUOTATION
	deleteQuotation: (id, status, page, limit, filters, loading, setPage, setFailedPage, view, deleteQuot) => (dispatch) => {
		dispatch({
			type: DELETE_QUOTATION,
			payload: {
				deleteQuotationLoading: true,
			},
		});
		deleteQuotation(id, status).then((res) => {
			dispatch({
				type: DELETE_QUOTATION,
				payload: {
					deleteQuotationLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				if (view) {
					deleteQuot(id)
				}
				else {
					getQuotations(page, limit, status, filters, loading, setPage, setFailedPage, view, dispatch)
				}

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//ADD QUOTATION STATUS
	addQuotationStatus: (data) => (dispatch) => {
		dispatch({
			type: ADD_QUOTATION_STATUS,
			payload: {
				addQuotationStatusLoading: true,
			},
		});

		addQuotationStatus(data).then((res) => {
			dispatch({
				type: ADD_QUOTATION_STATUS,
				payload: {
					addQuotationStatusLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_QUOTATION_STATUS_SUCCESS,
					payload: {
						quotationStatus: res?.data?.statuses,
						addedStatus: res?.data?.addedStatus
					},
				});
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL QUOTATION STATUS
	getAllQuotationStatus: () => (dispatch) => {
		dispatch({
			type: GET_ALL_QUOTATION_STATUS,
			payload: {
				getQuotationStatusesLoading: true,
			},
		});
		getAllQuotationStatus().then((res) => {
			dispatch({
				type: GET_ALL_QUOTATION_STATUS,
				payload: {
					getQuotationStatusesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_QUOTATION_STATUS_SUCCESS,
					payload: {
						quotationStatus: res?.data?.statuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL QUOTATION STATUS LIST
	getAllQuotationStatusList: () => (dispatch) => {
		getAllQuotationStatusList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_QUOTATION_STATUS_LIST,
					payload: {
						quotationStatusList: res?.data?.statuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("quotation status")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE QUOTATION STATUS
	getQuotationStatus: (id) => (dispatch) => {
		dispatch({
			type: GET_QUOTATION_STATUS,
			payload: {
				getQuotationStatusLoading: true,
			},
		});
		getQuotationStatus(id).then((res) => {
			dispatch({
				type: GET_QUOTATION_STATUS,
				payload: {
					getQuotationStatusLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: GET_QUOTATION_STATUS_SUCCESS,
					payload: {
						currentQuotationStatus: res?.data?.status,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE QUOTATION STATUS
	updateQuotationStatus: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_QUOTATION_STATUS,
			payload: {
				updateQuotationStatusLoading: true,
			},
		});

		updateQuotationStatus(data, id).then((res) => {
			dispatch({
				type: UPDATE_QUOTATION_STATUS,
				payload: {
					updateQuotationStatusLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_QUOTATION_STATUS_SUCCESS,
					payload: {
						quotationStatus: res?.data?.statuses,
					},
				});
				history.push("/quotation-status");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE QUOTATION STATUS
	deleteQuotationStatus: (id) => (dispatch) => {
		dispatch({
			type: DELETE_QUOTATION_STATUS,
			payload: {
				deleteQuotationStatusLoading: true,
			},
		});
		deleteQuotationStatus(id).then((res) => {
			dispatch({
				type: DELETE_QUOTATION_STATUS,
				payload: {
					deleteQuotationStatusLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_QUOTATION_STATUS_SUCCESS,
					payload: {
						quotationStatus: res?.data?.statuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//ADD QUOTATION LOSS
	addQuotationLostReason: (data) => (dispatch) => {
		dispatch({
			type: ADD_QUOTATION_LOST_REASON,
			payload: {
				addQuotationLostReasonLoading: true,
			},
		});

		addQuotationLostReason(data).then((res) => {
			dispatch({
				type: ADD_QUOTATION_LOST_REASON,
				payload: {
					addQuotationLostReasonLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_QUOTATION_LOST_REASON_SUCCESS,
					payload: {
						reasons: res?.data?.reasons,
						addedReason: res?.data?.addedReason
					},
				});
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL QUOTATION  REASONS
	getAllQuotationLostReason: () => (dispatch) => {
		dispatch({
			type: GET_ALL_QUOTATION_LOST_REASON,
			payload: {
				getQuotationLostReasonsLoading: true,
			},
		});
		getAllQuotationLostReason().then((res) => {
			dispatch({
				type: GET_ALL_QUOTATION_LOST_REASON,
				payload: {
					getQuotationLostReasonsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_QUOTATION_LOST_REASON_SUCCESS,
					payload: {
						reasons: res?.data?.reasons,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL QUOTATION REASONS LIST
	getAllQuotationLostReasonList: () => (dispatch) => {
		getAllQuotationLostReasonList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_QUOTATION_LOST_REASON_LIST,
					payload: {
						reasonsList: res?.data?.reasons,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("quotation status")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE QUOTATION REASON
	getQuotationLostReason: (id) => (dispatch) => {
		dispatch({
			type: GET_QUOTATION_LOST_REASON,
			payload: {
				getQuotationlostReasonLoading: true,
			},
		});
		getQuotationLostReason(id).then((res) => {
			dispatch({
				type: GET_QUOTATION_LOST_REASON,
				payload: {
					getQuotationLostReasonLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: GET_QUOTATION_LOST_REASON_SUCCESS,
					payload: {
						currentReason: res?.data?.reason,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE QUOTATION REASON
	updateQuotationLostReason: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_QUOTATION_LOST_REASON,
			payload: {
				updateQuotationLostReasonLoading: true,
			},
		});

		updateQuotationLostReason(data, id).then((res) => {
			dispatch({
				type: UPDATE_QUOTATION_LOST_REASON,
				payload: {
					updateQuotationLostReasonLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_QUOTATION_LOST_REASON_SUCCESS,
					payload: {
						reasons: res?.data?.reasons,
					},
				});
				history.push("/quotation-loss-reasons");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE QUOTATION REASON
	deleteQuotationLostReason: (id) => (dispatch) => {
		dispatch({
			type: DELETE_QUOTATION_LOST_REASON,
			payload: {
				deleteQuotationLostReasonLoading: true,
			},
		});
		deleteQuotationLostReason(id).then((res) => {
			dispatch({
				type: DELETE_QUOTATION_LOST_REASON,
				payload: {
					deleteQuotationLostReasonLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_QUOTATION_LOST_REASON_SUCCESS,
					payload: {
						reasons: res?.data?.reasons,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


};

export default actions;
