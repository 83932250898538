import { type } from "@testing-library/user-event/dist/type";
import {
	addSalesCallCategory,
	updateSalesCallCategory,
	deleteSalesCallCategory,
	getAllSalesCallCategory,
	getSalesCallCategory,
	getAllSalesCallCategoryList,
	updateSalesComments,
	addSalesComments,

	getPaginatedSalesCalls,

	addSalesCall,
	getAllSalesCalls,
	getSalesCall,
	updateSalesCall,
	deleteSalesCall,
	addSalesCallType,
	getAllSalesCallType,
	getSalesCallType,
	updateSalesCallType,
	deleteSalesCallType,
	addSalesCallStatus,
	getAllSalesCallStatus,
	getSalesCallStatus,
	updateSalesCallStatus,
	deleteSalesCallStatus,
	getAllSalesCallStatusList,
	getAllSalesCallTypeList,
	getAllSalesCallTypeCount,
	getAllSalesCallsFilter,
	getSalesComments,
	deleteSalesComment
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";

export const ADD_SALES_CALL_SUCCESS = "ADD_SALES_CALL_SUCCESS";
export const GET_ALL_SALES_CALLS_SUCCESS = "GET_ALL_SALES_CALLS_SUCCESS";
export const GET_ALL_SALES_CALLS_FILTER_SUCCESS = "GET_ALL_SALES_CALLS_FILTER_SUCCESS";
export const GET_SALES_CALL_SUCCESS = "GET_SALES_CALL_SUCCESS";
export const UPDATE_SALES_CALL_SUCCESS = "UPDATE_SALES_CALL_SUCCESS";
export const DELETE_SALES_CALL_SUCCESS = "DELETE_SALES_CALL_SUCCESS";

export const ADD_SALES_CALL = "ADD_SALES_CALL";
export const GET_ALL_SALES_CALLS = "GET_ALL_SALES_CALLS";
export const GET_ALL_SALES_CALLS_FILTER = "GET_ALL_SALES_CALLS_FILTER";
export const GET_SALES_CALL = "GET_SALES_CALL";
export const UPDATE_SALES_CALL = "UPDATE_SALES_CALL";
export const DELETE_SALES_CALL = "DELETE_SALES_CALL";


export const GET_ALL_SALES_CALL_TYPE_COUNT = "GET_ALL_SALES_CALL_TYPE_COUNT"
export const ADD_SALES_CALL_TYPE_SUCCESS = "ADD_SALES_CALL_TYPE_SUCCESS";
export const GET_ALL_SALES_CALL_TYPE_SUCCESS =
	"GET_ALL_SALES_CALL_TYPE_SUCCESS";
export const GET_SALES_CALL_TYPE_SUCCESS = "GET_SALES_CALL_TYPE_SUCCESS";
export const UPDATE_SALES_CALL_TYPE_SUCCESS =
	"UPDATE_SALES_CALL_TYPE_SUCCESS";
export const DELETE_SALES_CALL_TYPE_SUCCESS =
	"DELETE_SALES_CALL_TYPE_SUCCESS";

export const ADD_SALES_CALL_TYPE = "ADD_SALES_CALL_TYPE";
export const GET_ALL_SALES_CALL_TYPE =
	"GET_ALL_SALES_CALL_TYPE";
export const GET_SALES_CALL_TYPE = "GET_SALES_CALL_TYPE";
export const UPDATE_SALES_CALL_TYPE =
	"UPDATE_SALES_CALL_TYPE";
export const DELETE_SALES_CALL_TYPE =
	"DELETE_SALES_CALL_TYPE";

export const ADD_SALES_CALL_STATUS_SUCCESS =
	"ADD_SALES_CALL_STATUS_SUCCESS";
export const GET_ALL_SALES_CALL_STATUS_SUCCESS =
	"GET_ALL_SALES_CALL_STATUS_SUCCESS";
export const GET_SALES_CALL_STATUS_SUCCESS =
	"GET_SALES_CALL_STATUS_SUCCESS";
export const UPDATE_SALES_CALL_STATUS_SUCCESS =
	"UPDATE_SALES_CALL_STATUS_SUCCESS";
export const DELETE_SALES_CALL_STATUS_SUCCESS =
	"DELETE_SALES_CALL_STATUS_SUCCESS";

export const ADD_SALES_CALL_STATUS =
	"ADD_SALES_CALL_STATUS";
export const GET_ALL_SALES_CALL_STATUS =
	"GET_ALL_SALES_CALL_STATUS";
export const GET_SALES_CALL_STATUS =
	"GET_SALES_CALL_STATUS";
export const UPDATE_SALES_CALL_STATUS =
	"UPDATE_SALES_CALL_STATUS";
export const DELETE_SALES_CALL_STATUS =
	"DELETE_SALES_CALL_STATUS";


export const GET_ALL_SALES_CALL_TYPE_LIST =
	"GET_ALL_SALES_CALL_TYPE_LIST";
export const GET_ALL_SALES_CALL_STATUS_LIST =
	"GET_ALL_SALES_CALL_STATUS_LIST";


export const ADD_SALES_CALL_CATEGORY_SUCCESS = "ADD_SALES_CALL_CATEGORY_SUCCESS";
export const GET_ALL_SALES_CALL_CATEGORY_SUCCESS =
	"GET_ALL_SALES_CALL_CATEGORY_SUCCESS";
export const GET_SALES_CALL_CATEGORY_SUCCESS = "GET_SALES_CALL_CATEGORY_SUCCESS";
export const UPDATE_SALES_CALL_CATEGORY_SUCCESS =
	"UPDATE_SALES_CALL_CATEGORY_SUCCESS";
export const DELETE_SALES_CALL_CATEGORY_SUCCESS =
	"DELETE_SALES_CALL_CATEGORY_SUCCESS";
export const GET_ALL_SALES_CALL_CATEGORY_LIST =
	"GET_ALL_SALES_CALL_CATEGORY_LIST";

export const ADD_SALES_CALL_CATEGORY = "ADD_SALES_CALL_CATEGORY";
export const GET_ALL_SALES_CALL_CATEGORY =
	"GET_ALL_SALES_CALL_CATEGORY";
export const GET_SALES_CALL_CATEGORY = "GET_SALES_CALL_CATEGORY";
export const UPDATE_SALES_CALL_CATEGORY =
	"UPDATE_SALES_CALL_CATEGORY";
export const DELETE_SALES_CALL_CATEGORY =
	"DELETE_SALES_CALL_CATEGORY";

export const SAVE_CHECKLIST = "SAVE_CHECKLIST";
export const SAVE_TEMP_TASKS = "SAVE_TEMP_TASKS";

export const SET_EDIT_ID = "SET_EDIT_ID";
export const SET_EDIT_VISIBLE = "SET_EDIT_VISIBLE";

export const SET_CHECK_VISIBLE = "SET_CHECK_VISIBLE";
export const SET_CHECK_DATA = "SET_CHECK_DATA";

export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS";

export const ADD_COMMENTS = "ADD_COMMENTS";
export const ADD_COMMENTS_SUCCESS = "ADD_COMMENTS_SUCCESS";

export const GET_ALL_CALLS_FILTER_SUCCESS = "GET_ALL_CALLS_FILTER_SUCCESS"

const getSalesCalls = (page, limit, status, filters, loading, setPage, setFailedPage, view, dispatch) => {
	if (loading)
		dispatch({
			type: GET_ALL_SALES_CALLS_FILTER,
			payload: {
				getSalesCallsFilterLoading: true
			},
		});
	else
		dispatch({
			type: GET_ALL_SALES_CALLS,
			payload: {
				getSalesCallsLoading: true
			},
		});
	getPaginatedSalesCalls(page, limit, status, filters).then((res) => {

		if (loading)
			dispatch({
				type: GET_ALL_SALES_CALLS_FILTER,
				payload: {
					getSalesCallsFilterLoading: false
				},
			});
		else
			dispatch({
				type: GET_ALL_SALES_CALLS,
				payload: {
					getSalesCallsLoading: false
				},
			});
		if (res?.status === 200) {

			if (typeof setPage === 'function' && view) {

				setPage(page + 1);
			}
			if (typeof setPage === 'function' && !view) {
				setPage();

			}
			dispatch({
				type: "SET_PAGE_DETAILS",
				payload: {
					page: "salesCalls",
					currentPage: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords
				},
			});
			dispatch({
				type: GET_ALL_SALES_CALLS_FILTER_SUCCESS,
				payload: {
					salesCalls: res?.data?.salesCalls,
					page: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords,
					view,
					groupedSalesCalls: res?.data?.groupedByDate,
					groupedTypes: res?.data?.groupedtypes || {}
				},
			});
		} else if (res?.status === 401) {
			window.location = "/session-ended";
		} else if (res?.status === 403) {
			window.location = "/forbidden";
		} else {
			toast.error(res?.data?.message || "error", { theme: "colored" });
		}
	}).catch(() => {
		if (typeof setFailedPage === 'function') {
			setFailedPage(page)
		}
	});
}

const actions = {

	//ADD Sales Call
	addSalesCall: (data, isRedirect, history) => (dispatch) => {
		dispatch({
			type: ADD_SALES_CALL,
			payload: {
				addSalesCallLoading: true,
			},
		});

		addSalesCall(data).then((res) => {
			dispatch({
				type: ADD_SALES_CALL,
				payload: {
					addSalesCallLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_SALES_CALL_SUCCESS,
					payload: {
						salesCalls: res?.data?.salesCalls,
						groupedSalesCalls: res?.data?.groupedResponse,
						addedSalesCall: res?.data?.addedSalesCall
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				if (isRedirect) history.push("/sales-tasks");
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales CallS
	getAllSalesCalls: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SALES_CALLS,
			payload: {
				getSalesCallsLoading: true,
			},
		});
		getAllSalesCalls().then((res) => {
			dispatch({
				type: GET_ALL_SALES_CALLS,
				payload: {
					getSalesCallsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALLS_SUCCESS,
					payload: {
						salesCalls: res?.data?.salesCalls,
						groupedSalesCalls: res?.data?.groupedResponse
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales CallS
	getAllSalesCallsFilter: (filter) => (dispatch) => {
		dispatch({
			type: GET_ALL_SALES_CALLS_FILTER,
			payload: {
				getSalesCallsFilterLoading: true,
			},
		});
		getAllSalesCallsFilter(filter).then((res) => {
			dispatch({
				type: GET_ALL_SALES_CALLS_FILTER,
				payload: {
					getSalesCallsFilterLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_ALL_SALES_CALLS_FILTER_SUCCESS,
					payload: {
						salesCalls: res?.data?.salesCalls,
						groupedSalesCalls: res?.data?.groupedResponse
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	getPaginatedCalls: (page, limit, status, filters, loading, setPage, setFailedPage = null, view) => (dispatch) => {

		getSalesCalls(page, limit, status, filters, loading, setPage, setFailedPage, view, dispatch);
	},

	//FETCH ALL Sales CallS
	getComments: (id) => (dispatch) => {
		dispatch({
			type: GET_COMMENTS,
			payload: {
				getCommentsLoading: true,
			},
		});
		getSalesComments(id).then((res) => {
			dispatch({
				type: GET_COMMENTS,
				payload: {
					getCommentsLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_COMMENTS_SUCCESS,
					payload: {
						comments: res?.data?.salesCallActivities
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE Sales Call
	getSalesCall: (id) => (dispatch) => {
		dispatch({
			type: GET_SALES_CALL,
			payload: {
				getSalesCallLoading: true,
			},
		});
		getSalesCall(id).then((res) => {

			dispatch({
				type: GET_SALES_CALL,
				payload: {
					getSalesCallLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALES_CALL_SUCCESS,
					payload: {
						currentSalesCall: res?.data?.salesCall,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE Sales Call
	updateSalesCall: (data, id, isRedirect, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SALES_CALL,
			payload: {
				updateSalesCallLoading: true,
			},
		});

		updateSalesCall(data, id).then((res) => {
			dispatch({
				type: UPDATE_SALES_CALL,
				payload: {
					updateSalesCallLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALES_CALL_SUCCESS,
					payload: {
						salesCalls: res?.data?.salesCalls,
						groupedSalesCalls: res?.data?.groupedResponse,
						addedSalesCall: res?.data?.addedSalesCall
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				if (isRedirect) history?.push("/sales-tasks");
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales CallS
	updateComments: (data, id) => (dispatch) => {
		dispatch({
			type: UPDATE_COMMENTS,
			payload: {
				updateCommentsLoading: true,
			},
		});
		updateSalesComments(data, id).then((res) => {
			dispatch({
				type: UPDATE_COMMENTS,
				payload: {
					updateCommentsLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: UPDATE_COMMENTS_SUCCESS,
					payload: {
						comments: res?.data?.salesCallActivities
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales CallS
	deleteComment: (id) => (dispatch) => {
		dispatch({
			type: DELETE_COMMENT,
			payload: {
				deleteCommentLoading: true,
			},
		});
		deleteSalesComment(id).then((res) => {
			dispatch({
				type: DELETE_COMMENT,
				payload: {
					deleteCommentLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: DELETE_COMMENT_SUCCESS,
					payload: {
						comments: res?.data?.salesCallActivities
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales CallS
	addComments: (data) => (dispatch) => {
		dispatch({
			type: ADD_COMMENTS,
			payload: {
				addCommentsLoading: true,
			},
		});
		addSalesComments(data).then((res) => {
			dispatch({
				type: ADD_COMMENTS,
				payload: {
					addCommentsLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: ADD_COMMENTS_SUCCESS,
					payload: {
						comments: res?.data?.salesCallActivities
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//DELETE Sales Call
	deleteSalesCall: (details, deleteTask) => (dispatch) => {
		dispatch({
			type: DELETE_SALES_CALL,
			payload: {
				deleteSalesCallLoading: true,
			},
		});
		deleteSalesCall(details?.id, "pending").then((res) => {
			dispatch({
				type: DELETE_SALES_CALL,
				payload: {
					deleteSalesCallLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				console.log(typeof deleteTask)
				deleteTask(details);
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD Sales Call TYPE
	addSalesCallType: (data) => (dispatch) => {
		dispatch({
			type: ADD_SALES_CALL_TYPE,
			payload: {
				addSalesCallTypeLoading: true,
			},
		});

		addSalesCallType(data).then((res) => {
			dispatch({
				type: ADD_SALES_CALL_TYPE,
				payload: {
					addSalesCallTypeLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_SALES_CALL_TYPE_SUCCESS,
					payload: {
						salesCallTypes: res?.data?.salesCallTypes,
						addedType: res?.data?.addedSalesCallType
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call TYPE
	getAllSalesCallType: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SALES_CALL_TYPE,
			payload: {
				getSalesCallTypesLoading: true,
			},
		});
		getAllSalesCallType().then((res) => {
			dispatch({
				type: GET_ALL_SALES_CALL_TYPE,
				payload: {
					getSalesCallTypesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_TYPE_SUCCESS,
					payload: {
						salesCallTypes: res?.data?.salesCallTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call TYPE
	getAllSalesCallTypeList: () => (dispatch) => {
		getAllSalesCallTypeList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_TYPE_LIST,
					payload: {
						salesCallTypesList: res?.data?.salesCallTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("Sales Call type")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL Sales Call TYPE COUNT
	getAllSalesCallTypeCount: (from, to, status) => (dispatch) => {
		getAllSalesCallTypeCount(from, to, status).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_TYPE_COUNT,
					payload: {
						salesCallTypesCount: res?.data?.salesCallTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("Sales Call type")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE Sales Call TYPE
	getSalesCallType: (id) => (dispatch) => {
		dispatch({
			type: GET_SALES_CALL_TYPE,
			payload: {
				getSalesCallTypeLoading: true,
			},
		});
		getSalesCallType(id).then((res) => {
			dispatch({
				type: GET_SALES_CALL_TYPE,
				payload: {
					getSalesCallTypeLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALES_CALL_TYPE_SUCCESS,
					payload: {
						currentSalesCallType: res?.data?.salesCallType,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE Sales Call TYPE
	updateSalesCallType: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SALES_CALL_TYPE,
			payload: {
				updateSalesCallTypeLoading: true,
			},
		});

		updateSalesCallType(data, id).then((res) => {
			dispatch({
				type: UPDATE_SALES_CALL_TYPE,
				payload: {
					updateSalesCallTypeLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALES_CALL_TYPE_SUCCESS,
					payload: {
						salesCallTypes: res?.data?.salesCallTypes,
					},
				});
				history.push("/sales-task-type");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE Sales Call TYPE
	deleteSalesCallType: (id) => (dispatch) => {
		dispatch({
			type: DELETE_SALES_CALL_TYPE,
			payload: {
				deleteSalesCallTypeLoading: true,
			},
		});
		deleteSalesCallType(id).then((res) => {
			dispatch({
				type: DELETE_SALES_CALL_TYPE,
				payload: {
					deleteSalesCallTypeLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SALES_CALL_TYPE_SUCCESS,
					payload: {
						salesCallTypes: res?.data?.salesCallTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD Sales Call STATUS
	addSalesCallStatus: (data) => (dispatch) => {
		dispatch({
			type: ADD_SALES_CALL_STATUS,
			payload: {
				addSalesCallStatusLoading: true,
			},
		});

		addSalesCallStatus(data).then((res) => {
			dispatch({
				type: ADD_SALES_CALL_STATUS,
				payload: {
					addSalesCallStatusLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_SALES_CALL_STATUS_SUCCESS,
					payload: {
						salesCallStatus: res?.data?.salesCallStatuses,
						addedStatus: res?.data?.addedSalesCallStatus
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call STATUS
	getAllSalesCallStatus: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SALES_CALL_STATUS,
			payload: {
				getSalesCallStatusesLoading: true,
			},
		});
		getAllSalesCallStatus().then((res) => {
			dispatch({
				type: GET_ALL_SALES_CALL_STATUS,
				payload: {
					getSalesCallStatusesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_STATUS_SUCCESS,
					payload: {
						salesCallStatus: res?.data?.salesCallStatuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call STATUS
	getAllSalesCallStatusList: () => (dispatch) => {
		getAllSalesCallStatusList().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_STATUS_LIST,
					payload: {
						salesCallStatusList: res?.data?.salesCallStatuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("Sales Call status")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET SINGLE Sales Call STATUS
	getSalesCallStatus: (id) => (dispatch) => {
		dispatch({
			type: GET_SALES_CALL_STATUS,
			payload: {
				getSalesCallStatusLoading: true,
			},
		});
		getSalesCallStatus(id).then((res) => {
			dispatch({
				type: GET_SALES_CALL_STATUS,
				payload: {
					getSalesCallStatusLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALES_CALL_STATUS_SUCCESS,
					payload: {
						currentSalesCallStatus: res?.data?.salesCallStatus,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE Sales Call STATUS
	updateSalesCallStatus: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SALES_CALL_STATUS,
			payload: {
				updateSalesCallStatusLoading: true,
			},
		});

		updateSalesCallStatus(data, id).then((res) => {
			dispatch({
				type: UPDATE_SALES_CALL_STATUS,
				payload: {
					updateSalesCallStatusLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALES_CALL_STATUS_SUCCESS,
					payload: {
						salesCallStatus: res?.data?.salesCallStatuses,
					},
				});
				history.push("/sales-task-status");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE Sales Call STATUS
	deleteSalesCallStatus: (id) => (dispatch) => {
		dispatch({
			type: DELETE_SALES_CALL_STATUS,
			payload: {
				deleteSalesCallStatusLoading: true,
			},
		});
		deleteSalesCallStatus(id).then((res) => {
			dispatch({
				type: DELETE_SALES_CALL_STATUS,
				payload: {
					deleteSalesCallStatusLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SALES_CALL_STATUS_SUCCESS,
					payload: {
						salesCallStatus: res?.data?.salesCallStatuses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//ADD Sales Call CATEGORY
	addSalesCallCategory: (data) => (dispatch) => {
		dispatch({
			type: ADD_SALES_CALL_CATEGORY,
			payload: {
				addSalesCallCategoryLoading: true,
			},
		});

		addSalesCallCategory(data).then((res) => {
			dispatch({
				type: ADD_SALES_CALL_CATEGORY,
				payload: {
					addSalesCallCategoryLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_SALES_CALL_CATEGORY_SUCCESS,
					payload: {
						salesCallCategories: res?.data?.salesCallDepartments,
						addedCategory: res?.data?.addedSalesCallDepartment
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call CATEGORY
	getAllSalesCallCategory: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SALES_CALL_CATEGORY,
			payload: {
				getSalesCallCategoriesLoading: true,
			},
		});
		getAllSalesCallCategory().then((res) => {
			dispatch({
				type: GET_ALL_SALES_CALL_CATEGORY,
				payload: {
					getSalesCallCategoriesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_CATEGORY_SUCCESS,
					payload: {
						salesCallCategories: res?.data?.salesCallDepartments,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL Sales Call CATEGORY LIST
	getAllSalesCallCategoryList: () => (dispatch) => {
		getAllSalesCallCategoryList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_CALL_CATEGORY_LIST,
					payload: {
						salesCallCategoriesList: res?.data?.salesCallDepartments,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("Sales Call type")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE Sales Call CATEGORY
	deleteSalesCallCategory: (id) => (dispatch) => {
		dispatch({
			type: DELETE_SALES_CALL_CATEGORY,
			payload: {
				deleteSalesCallCategoryLoading: true,
			},
		});
		deleteSalesCallCategory(id).then((res) => {
			dispatch({
				type: DELETE_SALES_CALL_CATEGORY,
				payload: {
					deleteSalesCallCategoryLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SALES_CALL_CATEGORY_SUCCESS,
					payload: {
						salesCallCategories: res?.data?.salesCallDepartments,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE Sales Call CATEGORY
	updateSalesCallCategory: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SALES_CALL_CATEGORY,
			payload: {
				updateSalesCallCategoryLoading: true,
			},
		});

		updateSalesCallCategory(data, id).then((res) => {
			dispatch({
				type: UPDATE_SALES_CALL_CATEGORY,
				payload: {
					updateSalesCallCategoryLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALES_CALL_CATEGORY_SUCCESS,
					payload: {
						salesCallCategories: res?.data?.salesCallDepartments,
					},
				});
				history.push("/sales-support-department");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE Sales Call CATEGORY
	getSalesCallCategory: (id) => (dispatch) => {
		dispatch({
			type: GET_SALES_CALL_CATEGORY,
			payload: {
				getSalesCallCategoryLoading: true,
			},
		});
		getSalesCallCategory(id).then((res) => {
			dispatch({
				type: GET_SALES_CALL_CATEGORY,
				payload: {
					getSalesCallCategoryLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALES_CALL_CATEGORY_SUCCESS,
					payload: {
						currentSalesCallCategory: res?.data?.salesCallDepartment,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	saveChecklist: (data) => (dispatch) => {
		dispatch({ type: SAVE_CHECKLIST, payload: { checklist: data } })
	},
	saveTempTasks: (data) => (dispatch) => {
		dispatch({ type: SAVE_TEMP_TASKS, payload: { tasks: data } })
	},
	setEditId: (data) => (dispatch) => {
		dispatch({ type: SET_EDIT_ID, payload: { editId: data } })
	},
	setEditVisible: (data) => (dispatch) => {
		dispatch({ type: SET_EDIT_VISIBLE, payload: { editVisible: data } })
	},
	setCheckVisible: (data) => (dispatch) => {
		dispatch({ type: SET_CHECK_VISIBLE, payload: { checkVisible: data } })
	},
	setCheckData: (data) => (dispatch) => {
		dispatch({ type: SET_CHECK_DATA, payload: { checkData: data } })
	}


};

export default actions;
