import { act } from "react-dom/test-utils";
import {
	ADD_USER_SUCCESS,
	ADD_USER,
	GET_ALL_USERS_SUCCESS,
	GET_ALL_USERS,
	GET_USER_SUCCESS,
	GET_USER,
	UPDATE_USER_SUCCESS,
	UPDATE_USER,
	DELETE_USER_SUCCESS,
	ADD_USER_ROLE_SUCCESS,
	ADD_USER_ROLE,
	GET_ALL_USER_ROLES_SUCCESS,
	GET_ALL_USER_ROLES,
	GET_USER_ROLE_SUCCESS,
	GET_USER_ROLE,
	UPDATE_USER_ROLE_SUCCESS,
	UPDATE_USER_ROLE,
	DELETE_USER_ROLE_SUCCESS,
	DELETE_USER,
	DELETE_USER_ROLE,
	GET_ALL_USER_ROLES_LIST,
	GET_ALL_USERS_LIST
} from "../actions/Users.action";

const initialState = {
	addUserLoading: false,
	updateUserLoading: false,
	getUserLoading: false,
	getUsersLoading: false,
	deleteUserLoading: false,

	addUserRoleLoading: false,
	updateUserRoleLoading: false,
	getUserRolesLoading: false,
	getUserRoleLoading: false,
	deleteUserRoleLoading: false,

	users: [],
	usersList: [],
	currentUser: "",
	userRoles: [],
	userRolesList: [],
	currentUserRole: "",
	addedUser: "",
};
const RESET_LOADING="RESET_LOADING"

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				addUserLoading: false,
				updateUserLoading: false,
				getUserLoading: false,
				getUsersLoading: false,
				deleteUserLoading: false,

				addUserRoleLoading: false,
				updateUserRoleLoading: false,
				getUserRolesLoading: false,
				getUserRoleLoading: false,
				deleteUserRoleLoading: false,

			})

		case ADD_USER_SUCCESS:
			return Object.assign({}, state, {
				users: action.payload.users,
				addedUser: action?.payload?.addedUser
			});

		case ADD_USER:
			return Object.assign({}, state, {
				addUserLoading: action.payload.addUserLoading,
			});

		case GET_ALL_USERS_SUCCESS:
			return Object.assign({}, state, {
				users: action.payload.users,
				currentUser: "",
			});
		case GET_ALL_USERS_LIST:

			return Object.assign({}, state, {
				usersList: action.payload.usersList,
				currentUser: "",
			});
		case GET_ALL_USERS:
			return Object.assign({}, state, {
				getUsersLoading: action.payload.getUsersLoading,

			});

		case GET_USER_SUCCESS:
			return Object.assign({}, state, {
				currentUser: action.payload.currentUser,
			});

		case GET_USER:
			return Object.assign({}, state, {
				getUserLoading: action.payload.getUserLoading,
			});

		case UPDATE_USER_SUCCESS:
			return Object.assign({}, state, {
				users: action.payload.users,
			});

		case UPDATE_USER:
			return Object.assign({}, state, {
				updateUserLoading: action.payload.updateUserLoading,
			});

		case DELETE_USER_SUCCESS:
			return Object.assign({}, state, {
				users: action.payload.users,
			});
		case DELETE_USER:
			return Object.assign({}, state, {
				deleteUserLoading: action.payload.deleteUserLoading,
			});

		case ADD_USER_ROLE_SUCCESS:
			return Object.assign({}, state, {
				userRoles: action.payload.userRoles,
			});

		case ADD_USER_ROLE:
			return Object.assign({}, state, {
				addUserRoleLoading: action.payload.addUserRoleLoading,

			});

		case GET_ALL_USER_ROLES_SUCCESS:
			return Object.assign({}, state, {
				userRoles: action.payload.userRoles,
				currentUserRole: "",
			});

		case GET_ALL_USER_ROLES_LIST:
			return Object.assign({}, state, {
				userRolesList: action.payload.userRolesList,
				currentUserRole: "",
			});
		case GET_ALL_USER_ROLES:
			return Object.assign({}, state, {
				getUserRolesLoading: action.payload.getUserRolesLoading,
			});

		case GET_USER_ROLE_SUCCESS:
			return Object.assign({}, state, {
				currentUserRole: action.payload.currentUserRole,
			});

		case GET_USER_ROLE:
			return Object.assign({}, state, {
				getUserRoleLoading: action.payload.getUserRoleLoading,
			});

		case UPDATE_USER_ROLE_SUCCESS:
			return Object.assign({}, state, {
				userRoles: action.payload.userRoles,
			});

		case UPDATE_USER_ROLE:
			return Object.assign({}, state, {
				updateUserRoleLoading: action.payload.updateUserRoleLoading,
			});

		case DELETE_USER_ROLE_SUCCESS:
			return Object.assign({}, state, {
				userRoles: action.payload.userRoles,
			});
		case DELETE_USER_ROLE:
			return Object.assign({}, state, {
				deleteUserRoleLoading: action.payload.deleteUserRoleLoading,
			});

		default:
			return state;
	}
}

export default branch;
