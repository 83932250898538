
import { ADD_CONTACT_INFO ,DELETE_CONTACT_INFO,EDIT_CONTACT_INFO,EMPTY_CONTACT_INFOS,SET_CONTACT_INFOS} from "../actions/ContactInfo.action";
import { toast } from "react-toastify";

const initialState = {
	
	contactInfos:[],
 
};

function branch(state = initialState, action) {
	switch (action.type) {
   

		case ADD_CONTACT_INFO:
      const contactIndex = state.contactInfos.findIndex((contact) => contact.phone === action.payload.contactInfo.phone);
      if (contactIndex === -1) {
        return {
          ...state,
          contactInfos: [...state.contactInfos, action.payload.contactInfo]
        };
  
      }
      else{
        
        toast.error( "Phone number already found", { theme: "colored" });
        return {...state};
      }

    case DELETE_CONTACT_INFO:
       
        const updatedContactInfos = state.contactInfos.filter((contact) => contact.key !== action.payload.key);
        
       
          return {
            ...state,
            contactInfos: updatedContactInfos
          };
          
    case EDIT_CONTACT_INFO:
      const oldContactInfos=[...state.contactInfos];
     
      const index=oldContactInfos.findIndex((contact)=>contact.key===action.payload.contactInfo.key)
      const phoneIndex=oldContactInfos.filter((contact)=>contact.key != action.payload.contactInfo.key ).findIndex((contact)=>contact.phone === action.payload.contactInfo.phone )
       
     if(phoneIndex != -1){
      toast.error("Phone number already found" , { theme: "colored" });
     }
      if (index !== -1 && phoneIndex ===-1) {
       
        oldContactInfos[index] = { ...oldContactInfos[index], ...action.payload.contactInfo };
        
        return { ...state, contactInfos: oldContactInfos };
      }
      else{
       
        return {...state}
      }
      
    case EMPTY_CONTACT_INFOS:
        return Object.assign({}, state, {
        
         contactInfos:[],
         
        }); 
            
    case SET_CONTACT_INFOS:
      return Object.assign({}, state, {
          
           contactInfos:action?.payload?.contactInfo,
           
          }); 
              
        default:
			return state;
	}
}

export default branch;
