import {
	addUser,
	getAllUsers,
	getAllUsersList,
	getUser,
	updateUser,
	deleteUser,
	addUserRole,
	getAllUserRoles,
	getUserRole,
	updateUserRole,
	deleteUserRole,
	getAllUserRolesList
} from "../../config/config";

import { toast } from "react-toastify";



export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const ADD_USER_ROLE_SUCCESS = "ADD_USER_ROLE_SUCCESS";
export const GET_ALL_USER_ROLES_SUCCESS = "GET_ALL_USER_ROLES_SUCCESS";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS";

export const ADD_USER = "ADD_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const GET_ALL_USER_ROLES = "GET_ALL_USER_ROLES";
export const GET_USER_ROLE = "GET_USER_ROL";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const DELETE_USER_ROLE = "DELETE_USER_ROLE";

export const GET_ALL_USER_ROLES_LIST = "GET_ALL_USER_ROLES_LIST";
export const GET_ALL_USERS_LIST = "GET_ALL_USERS_LIST";

const actions = {
	//ADD USER
	addUser: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_USER,
			payload: {
				addUserLoading: true,
			},
		});

		addUser(data).then((res) => {
			dispatch({
				type: ADD_USER,
				payload: {
					addUserLoading: false,
				},
			});


			if (res?.status === 200) {
				dispatch({
					type: ADD_USER_SUCCESS,
					payload: {
						users: res?.data?.users,
						addedUser: res?.data?.addedUser
					},
				});
				history?.push("/users");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL USERS
	getAllUsers: () => (dispatch) => {
		dispatch({
			type: GET_ALL_USERS,
			payload: {
				getUsersLoading: true,
			},
		});

		getAllUsers().then((res) => {
			dispatch({
				type: GET_ALL_USERS,
				payload: {
					getUsersLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_USERS_SUCCESS,
					payload: {
						users: res?.data?.users,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL USERS LIST
	getAllUsersList: () => (dispatch) => {

		getAllUsersList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_USERS_LIST,
					payload: {
						usersList: res?.data?.users,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("users")
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE USER
	getUser: (id) => (dispatch) => {
		dispatch({
			type: GET_USER,
			payload: {
				getUserLoading: true,
			},
		});

		getUser(id).then((res) => {
			dispatch({
				type: GET_USER,
				payload: {
					getUserLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: GET_USER_SUCCESS,
					payload: {
						currentUser: res?.data?.user,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE USER
	updateUser: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_USER,
			payload: {
				updateUserLoading: true,
			},
		});


		updateUser(data, id).then((res) => {
			dispatch({
				type: UPDATE_USER,
				payload: {
					updateUserLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: {
						users: res?.data?.users,
					},
				});
				history.push("/users");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE USER
	deleteUser: (id) => (dispatch) => {
		dispatch({
			type: DELETE_USER,
			payload: {
				deleteUserLoading: true,
			},
		});
		deleteUser(id).then((res) => {
			dispatch({
				type: DELETE_USER,
				payload: {
					deleteUserLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_USER_SUCCESS,
					payload: {
						users: res?.data?.users,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD USER ROLE
	addUserRole: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_USER_ROLE,
			payload: {
				addUserRoleLoading: true,
			},
		});

		addUserRole(data).then((res) => {
			dispatch({
				type: ADD_USER_ROLE,
				payload: {
					addUserRoleLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_USER_ROLE_SUCCESS,
					payload: {
						userRoles: res?.data?.roles,
					},
				});
				history?.push("/user-roles");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL USER ROLES
	getAllUserRoles: () => (dispatch) => {
		dispatch({
			type: GET_ALL_USER_ROLES,
			payload: {
				getUserRolesLoading: true,
			},
		});
		getAllUserRoles().then((res) => {
			dispatch({
				type: GET_ALL_USER_ROLES,
				payload: {
					getUserRolesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_USER_ROLES_SUCCESS,
					payload: {
						userRoles: res?.data?.roles,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL USER ROLES
	getAllUserRolesList: () => (dispatch) => {
		getAllUserRolesList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_USER_ROLES_LIST,
					payload: {
						userRolesList: res?.data?.roles,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("roles")
				
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE USER ROLE
	getUserRole: (id) => (dispatch) => {
		dispatch({
			type: GET_USER_ROLE,
			payload: {
				getUserRoleLoading: true,
			},
		});
		getUserRole(id).then((res) => {
			dispatch({
				type: GET_USER_ROLE,
				payload: {
					getUserRoleLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_USER_ROLE_SUCCESS,
					payload: {
						currentUserRole: res?.data?.role,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE USER ROLE
	updateUserRole: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_USER_ROLE,
			payload: {
				updateUserRoleLoading: true,
			},
		});

		updateUserRole(data, id).then((res) => {
			dispatch({
				type: UPDATE_USER_ROLE,
				payload: {
					updateUserRoleLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_USER_ROLE_SUCCESS,
					payload: {
						userRoles: res?.data?.roles,
					},
				});
				history.push("/user-roles");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE USER ROLE
	deleteUserRole: (id) => (dispatch) => {
		dispatch({
			type: DELETE_USER_ROLE,
			payload: {
				deleteUserRoleLoading: true,
			},
		});
		deleteUserRole(id).then((res) => {
			dispatch({
				type: DELETE_USER_ROLE,
				payload: {
					deleteUserRoleLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_USER_ROLE_SUCCESS,
					payload: {
						userRoles: res?.data?.roles,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
