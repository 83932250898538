import {
	SAVE_FILTERS,
	GET_GROUP_PRODUCTS,
	GET_GROUP_PRODUCTS_SUCCESS,
	SORT_GROUPS,
	SORT_GROUPS_SUCCESS,
	SORT_PRODUCTS,
	SORT_PRODUCTS_SUCCESS,
	ADD_PRODUCT_SUCCESS,
	ADD_PRODUCT,
	GET_ALL_PRODUCTS_SUCCESS,
	GET_ALL_PRODUCTS,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT,
	DELETE_PRODUCT_SUCCESS,
	ADD_PRODUCT_TYPE_SUCCESS,
	ADD_PRODUCT_TYPE,
	GET_ALL_PRODUCT_TYPES_SUCCESS,
	GET_ALL_PRODUCT_TYPES,
	GET_PRODUCT_TYPE_SUCCESS,
	GET_PRODUCT_TYPE,
	UPDATE_PRODUCT_TYPE_SUCCESS,
	UPDATE_PRODUCT_TYPE,
	DELETE_PRODUCT_TYPE_SUCCESS,
	ADD_PRODUCT_GROUP_SUCCESS,
	ADD_PRODUCT_GROUP,
	GET_ALL_PRODUCT_GROUPS_SUCCESS,
	GET_ALL_PRODUCT_GROUPS,
	GET_PRODUCT_GROUP_SUCCESS,
	GET_PRODUCT_GROUP,
	UPDATE_PRODUCT_GROUP_SUCCESS,
	UPDATE_PRODUCT_GROUP,
	DELETE_PRODUCT_GROUP_SUCCESS,
	ADD_PRODUCT_CATEGORY_SUCCESS,
	ADD_PRODUCT_CATEGORY,
	GET_ALL_PRODUCT_CATEGORIES_SUCCESS,
	GET_ALL_PRODUCT_CATEGORIES,
	GET_PRODUCT_CATEGORY_SUCCESS,
	GET_PRODUCT_CATEGORY,
	UPDATE_PRODUCT_CATEGORY_SUCCESS,
	UPDATE_PRODUCT_CATEGORY,
	DELETE_PRODUCT_CATEGORY_SUCCESS,
	ADD_PRODUCT_BRAND_SUCCESS,
	ADD_PRODUCT_BRAND,
	GET_ALL_PRODUCT_BRANDS_SUCCESS,
	GET_ALL_PRODUCT_BRANDS,
	GET_PRODUCT_BRAND_SUCCESS,
	GET_PRODUCT_BRAND,
	UPDATE_PRODUCT_BRAND_SUCCESS,
	UPDATE_PRODUCT_BRAND,
	DELETE_PRODUCT_BRAND_SUCCESS,
	ADD_PRODUCT_UNIT_SUCCESS,
	ADD_PRODUCT_UNIT,
	GET_ALL_PRODUCT_UNITS_SUCCESS,
	GET_ALL_PRODUCT_UNITS,
	GET_PRODUCT_UNIT_SUCCESS,
	GET_PRODUCT_UNIT,
	UPDATE_PRODUCT_UNIT_SUCCESS,
	UPDATE_PRODUCT_UNIT,
	DELETE_PRODUCT_UNIT_SUCCESS,
	UPLOAD_IMAGE,
	UPLOAD_IMAGE_SUCCESS,
	UPLOAD_LOGO,
	DELETE_PRODUCT,
	DELETE_PRODUCT_BRAND,
	DELETE_PRODUCT_CATEGORY,
	DELETE_PRODUCT_TYPE,
	DELETE_PRODUCT_UNIT,
	DELETE_PRODUCT_GROUP,
	GET_ALL_PRODUCT_CATEGORIES_LIST,
	GET_ALL_PRODUCT_BRANDS_LIST,
	GET_ALL_PRODUCT_GROUPS_LIST,
	GET_ALL_PRODUCT_TYPES_LIST,
	GET_ALL_PRODUCT_UNITS_LIST,
	GET_ALL_PRODUCTS_LIST,
	SET_MENU_GROUPS
} from "../actions/Products.action";
const RESET_LOADING = "RESET_LOADING"
const initialState = {
	filters: {
		selectedGroup: null,
		view: true,
		filterTreeValue: [],
		sortValue: "",
		searchValue: ""
	},
	addProductLoading: false,
	updateProductLoading: false,
	getProductLoading: false,
	getProductsLoading: false,
	products: [],
	productsList: [],
	currentProduct: "",

	productTypes: [],
	productTypesList: [],
	productBrands: [],
	productBrandsList: [],
	productUnits: [],
	productUnitsList: [],

	currentProductUnit: "",
	currentProductType: "",
	addProductTypeLoading: false,
	updateProductTypeLoading: false,
	getProductTypeLoading: false,
	getProductTypesLoading: false,
	currentProductBrand: "",
	addProductBrandLoading: false,
	updateProductBrandLoading: false,
	getProductBrandLoading: false,
	getProductBrandsLoading: false,
	productGroups: [],
	productGroupsList: [],
	currentProductGroup: "",
	addedGroup: "",
	addedBrand: "",
	addedType: "",
	adddedUnit: "",

	addProductGroupLoading: false,
	updateProductGroupLoading: false,
	getProductGroupLoading: false,
	getProductGroupsLoading: false,
	productCategories: [],
	productCategoriesList: [],

	currentProductCategory: "",
	addProductCategoryLoading: false,
	updateProductCategoryLoading: false,
	getProductCategoryLoading: false,
	getProductCategoriesLoading: false,

	addProductUnitLoading: false,
	updateProductUnitLoading: false,
	getProductUnitLoading: false,
	getProductUnitsLoading: false,

	deleteProductBrandLoading: false,
	deleteProductCategoryLoading: false,
	deleteProductGroupLoading: false,
	deleteProductLoading: false,
	deleteProductUnit: false,
	deleteProductTypeLoading: false,

	groupProducts: [],
	sortProductsLoading: false,
	sortGroupsLoading: false,
	getGroupProductsLoading: false,
	menuGroups: [],

	logoUrl: "",
	logoLoading: false,
	addedCategory: "",

};


function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {

				addProductLoading: false,
				updateProductLoading: false,
				getProductLoading: false,
				getProductsLoading: false,


				addProductTypeLoading: false,
				updateProductTypeLoading: false,
				getProductTypeLoading: false,
				getProductTypesLoading: false,
				addProductBrandLoading: false,
				updateProductBrandLoading: false,
				getProductBrandLoading: false,
				getProductBrandsLoading: false,

				addProductGroupLoading: false,
				updateProductGroupLoading: false,
				getProductGroupLoading: false,
				getProductGroupsLoading: false,
				addProductCategoryLoading: false,
				updateProductCategoryLoading: false,
				getProductCategoryLoading: false,
				getProductCategoriesLoading: false,

				addProductUnitLoading: false,
				updateProductUnitLoading: false,
				getProductUnitLoading: false,
				getProductUnitsLoading: false,

				deleteProductBrandLoading: false,
				deleteProductCategoryLoading: false,
				deleteProductGroupLoading: false,
				deleteProductLoading: false,
				deleteProductUnit: false,
				deleteProductTypeLoading: false,
				sortProductsLoading: false,
				sortGroupsLoading: false,
				getGroupProductsLoading: false,
				logoLoading: false,


			});
		case SAVE_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.filters,
			});
		case UPLOAD_LOGO:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl,
			});
		case UPLOAD_IMAGE_SUCCESS:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl,
			});
		case UPLOAD_IMAGE:
			return Object.assign({}, state, {
				logoLoading: action.payload.logoLoading,
			});
		case ADD_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				products: action.payload.products,
				menuGroups: action?.payload?.menuGroups
			});
		case ADD_PRODUCT:
			return Object.assign({}, state, {
				addProductLoading: action.payload.addProductLoading,
			});
		case UPDATE_PRODUCT:
			return Object.assign({}, state, {
				updateProductLoading: action.payload.updateProductLoading,
			});
		case GET_PRODUCT:
			return Object.assign({}, state, {
				getProductLoading: action.payload.getProductLoading,
			});
		case GET_ALL_PRODUCTS:
			return Object.assign({}, state, {
				getProductsLoading: action.payload.getProductsLoading,
			});
		case ADD_PRODUCT_TYPE:
			return Object.assign({}, state, {
				addProductTypeLoading: action.payload.addProductTypeLoading,

			});
		case UPDATE_PRODUCT_TYPE:
			return Object.assign({}, state, {
				updateProductTypeLoading: action.payload.updateProductTypeLoading,
			});
		case GET_PRODUCT_TYPE:
			return Object.assign({}, state, {
				getProductTypeLoading: action.payload.getProductTypeLoading,
			});
		case GET_ALL_PRODUCT_TYPES:
			return Object.assign({}, state, {
				getProductTypesLoading: action.payload.getProductTypesLoading,
			});

		case GET_ALL_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				products: action.payload.products,
				currentProduct: "",
			});

		case GET_ALL_PRODUCTS_LIST:
			return Object.assign({}, state, {
				productsList: action.payload.productsList,
				currentProduct: "",
			});
		case GET_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				currentProduct: action.payload.currentProduct,
				logoUrl: action.payload.currentProduct?.logo
			});

		case UPDATE_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				products: action.payload.products,
				menuGroups: action?.payload?.menuGroups
			});

		case DELETE_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				products: action.payload.products,
			});
		case DELETE_PRODUCT:
			return Object.assign({}, state, {
				deleteProductLoading: action.payload.deleteProductLoading,
			});

		case ADD_PRODUCT_TYPE_SUCCESS:
			return Object.assign({}, state, {
				productTypes: action.payload.productTypes,
				addedType: action.payload.addedType
			});

		case GET_ALL_PRODUCT_TYPES_SUCCESS:
			return Object.assign({}, state, {
				productTypes: action.payload.productTypes,
				currentProductType: "",
			});

		case GET_ALL_PRODUCT_TYPES_LIST:
			return Object.assign({}, state, {
				productTypesList: action.payload.productTypesList,
				currentProductType: "",
			});

		case GET_PRODUCT_TYPE_SUCCESS:
			return Object.assign({}, state, {
				currentProductType: action.payload.currentProductType,
			});

		case UPDATE_PRODUCT_TYPE_SUCCESS:
			return Object.assign({}, state, {
				productTypes: action.payload.productTypes,
			});

		case DELETE_PRODUCT_TYPE_SUCCESS:
			return Object.assign({}, state, {
				productTypes: action.payload.productTypes,

			});
		case DELETE_PRODUCT_TYPE:
			return Object.assign({}, state, {
				deleteProductType: action.payload.deleteProductTypeLoading
			});


		case ADD_PRODUCT_BRAND_SUCCESS:
			return Object.assign({}, state, {
				productBrands: action.payload.productBrands,
				addedBrand: action.payload.addedBrand
			});

		case GET_ALL_PRODUCT_BRANDS_SUCCESS:
			return Object.assign({}, state, {
				productBrands: action.payload.productBrands,
				currentProductBrand: "",
			});

		case GET_ALL_PRODUCT_BRANDS_LIST:
			return Object.assign({}, state, {
				productBrandsList: action.payload.productBrandsList,
				currentProductBrand: "",
			});

		case GET_PRODUCT_BRAND_SUCCESS:
			return Object.assign({}, state, {
				currentProductBrand: action.payload.currentProductBrand,
			});

		case UPDATE_PRODUCT_BRAND:
			return Object.assign({}, state, {
				updateProductBrandLoading: action.payload.updateProductBrandLoading,
			});
		case ADD_PRODUCT_BRAND:
			return Object.assign({}, state, {
				addProductBrandLoading: action.payload.addProductBrandLoading,

			});

		case GET_ALL_PRODUCT_BRANDS:
			return Object.assign({}, state, {
				getProductBrandsLoading: action.payload.getProductBrandsLoading,
			});

		case GET_PRODUCT_BRAND:
			return Object.assign({}, state, {
				getProductBrandLoading: action.payload.getProductBrandLoading,
			});

		case UPDATE_PRODUCT_BRAND_SUCCESS:
			return Object.assign({}, state, {
				productBrands: action.payload.productBrands,
			});

		case DELETE_PRODUCT_BRAND_SUCCESS:
			return Object.assign({}, state, {
				productBrands: action.payload.productBrands,
			});
		case DELETE_PRODUCT_BRAND:
			return Object.assign({}, state, {
				deleteProductBrandLoading: action.payload.deleteProductBrandLoading,
			});


		case ADD_PRODUCT_GROUP_SUCCESS:
			return Object.assign({}, state, {
				productGroups: action.payload.productGroups,
				addedGroup: action.payload.addedGroup
			});
		case ADD_PRODUCT_GROUP:
			return Object.assign({}, state, {
				addProductGroupLoading: action.payload.addProductGroupLoading,
			});

		case GET_ALL_PRODUCT_GROUPS_SUCCESS:
			return Object.assign({}, state, {
				productGroups: action.payload.productGroups,
				currentProductGroup: "",
			});

		case GET_ALL_PRODUCT_GROUPS_LIST:
			return Object.assign({}, state, {
				productGroupsList: action.payload.productGroupsList,
				currentProductGroup: "",
			});
		case GET_ALL_PRODUCT_GROUPS:
			return Object.assign({}, state, {
				getProductGroupsLoading: action.payload.getProductGroupsLoading,

			});

		case GET_PRODUCT_GROUP_SUCCESS:
			return Object.assign({}, state, {
				currentProductGroup: action.payload.currentProductGroup,
			});
		case GET_PRODUCT_GROUP:
			return Object.assign({}, state, {
				getProductGroupLoading: action.payload.getProductGroupLoading,
			});

		case UPDATE_PRODUCT_GROUP_SUCCESS:
			return Object.assign({}, state, {
				productGroups: action.payload.productGroups,
			});
		case UPDATE_PRODUCT_GROUP:
			return Object.assign({}, state, {
				updateProductGroupLoading: action.payload.updateProductGroupLoading,
			});

		case DELETE_PRODUCT_GROUP_SUCCESS:
			return Object.assign({}, state, {
				productGroups: action.payload.productGroups,
			});
		case DELETE_PRODUCT_GROUP:
			return Object.assign({}, state, {
				deleteProductGroupLoading: action.payload.deleteProductGroupLoading,
			});

		case ADD_PRODUCT_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				productCategories: action.payload.productCategories,
				addedCategory: action.payload.addedCategory
			});

		case ADD_PRODUCT_CATEGORY:
			return Object.assign({}, state, {
				addProductCategoryLoading: action.payload.addProductCategoryLoading,

			});

		case GET_ALL_PRODUCT_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				productCategories: action.payload.productCategories,
				currentProductCategory: "",
			});

		case GET_ALL_PRODUCT_CATEGORIES_LIST:
			return Object.assign({}, state, {
				productCategoriesList: action.payload.productCategoriesList,
				currentProductCategory: "",
			});
		case GET_ALL_PRODUCT_CATEGORIES:
			return Object.assign({}, state, {
				getProductCategoriesLoading: action.payload.getProductCategoriesLoading,

			});

		case GET_PRODUCT_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				currentProductCategory: action.payload.currentProductCategory,
			});
		case GET_PRODUCT_CATEGORY:
			return Object.assign({}, state, {
				getProductCategoryLoading: action.payload.getProductCategoryLoading,
			});

		case UPDATE_PRODUCT_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				productCategories: action.payload.productCategories,
			});
		case UPDATE_PRODUCT_CATEGORY:
			return Object.assign({}, state, {
				updateProductCategoryLoading: action.payload.updateProductCategoryLoading,
			});

		case DELETE_PRODUCT_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				productCategories: action.payload.productCategories,
			});
		case DELETE_PRODUCT_CATEGORY:
			return Object.assign({}, state, {
				deleteProductCategoryLoading: action.payload.deleteProductCategoryLoading,
			});


		case ADD_PRODUCT_UNIT_SUCCESS:
			return Object.assign({}, state, {
				productUnits: action.payload.productUnits,
				addedUnit: action.payload.addedUnit,
			});
		case ADD_PRODUCT_UNIT:
			return Object.assign({}, state, {
				addProductUnitLoading: action.payload.addProductUnit,
				adddedUnit: action.payload.adddedUnit
			});

		case GET_ALL_PRODUCT_UNITS_SUCCESS:
			return Object.assign({}, state, {
				productUnits: action.payload.productUnits,
				currentProductUnit: "",
			});

		case GET_ALL_PRODUCT_UNITS_LIST:
			return Object.assign({}, state, {
				productUnitsList: action.payload.productUnitsList,
				currentProductUnit: "",
			});
		case GET_ALL_PRODUCT_UNITS:
			return Object.assign({}, state, {
				getProductUnitsLoading: action.payload.getProductUnitsLoading,

			});

		case GET_PRODUCT_UNIT_SUCCESS:
			return Object.assign({}, state, {
				currentProductUnit: action.payload.currentProductUnit,
			});
		case GET_PRODUCT_UNIT:
			return Object.assign({}, state, {
				getProductUnitLoading: action.payload.getProductUnitLoading,
			});

		case UPDATE_PRODUCT_UNIT_SUCCESS:
			return Object.assign({}, state, {
				productUnits: action.payload.productUnits,
			});
		case UPDATE_PRODUCT_UNIT:
			return Object.assign({}, state, {
				updateProductUnitLoading: action.payload.updateProductUnitLoading,
			});

		case DELETE_PRODUCT_UNIT_SUCCESS:
			return Object.assign({}, state, {
				productUnits: action.payload.productUnits,
			});
		case DELETE_PRODUCT_UNIT:
			return Object.assign({}, state, {
				deleteProductUnitLoading: action.payload.deleteProductUnitLoading,
			});

		case SORT_PRODUCTS:
			return Object.assign({}, state, {
				sortProductsLoading: action.payload.sortProductsLoading,
			});

		case SORT_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				groupProducts: action.payload.groupProducts,
				menuGroups: action?.payload?.menuGroups
			});

		case SORT_GROUPS:
			return Object.assign({}, state, {
				sortGroupsLoading: action.payload.sortGroupsLoading,
			});

		case SORT_GROUPS_SUCCESS:
			return Object.assign({}, state, {
				productGroupsList: action.payload.productGroupsList,
			});

		case GET_GROUP_PRODUCTS:
			return Object.assign({}, state, {
				getGroupProductsLoading: action.payload.getGroupProductsLoading,
			});
		case SET_MENU_GROUPS:
			return Object.assign({}, state, {
				menuGroups: action.payload.menuGroups
			});

		case GET_GROUP_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				menuGroups: action?.payload?.menuGroups
			});


		default:
			return state;
	}
}

export default branch;
