import {
	addLanguage,
	getAllLanguages,
	updateLanguage,
	deleteLanguage,
	getLanguage,
	getAllLanguagesList
} from "../../config/config";

import { toast } from "react-toastify";

export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const GET_ALL_LANGUAGES_SUCCESS = "GET_ALL_LANGUAGES_SUCCESS";
export const GET_ALL_LANGUAGES_LIST_SUCCESS = "GET_ALL_LANGUAGES_LIST_SUCCESS";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";

export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES";
export const GET_ALL_LANGUAGES_LIST = "GET_ALL_LANGUAGES_LIST";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

export const GET_LANGUAGE = 'GET_LANGUAGE';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';

const actions = {
	//ADD LANGUAGE
	addLanguage: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_LANGUAGE,
			payload: {
				addLanguageLoading: true,
			},
		});

		addLanguage(data).then((res) => {
			dispatch({
				type: ADD_LANGUAGE,
				payload: {
					addLanguageLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: ADD_LANGUAGE_SUCCESS,
					payload: {
						languages: res?.data,
						addedLanguage: res?.data?.addedLanguage
					},
				});
				history?.push("/languages");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL LANGUAGES
	getAllLanguages: () => (dispatch) => {
		dispatch({
			type: GET_ALL_LANGUAGES,
			payload: {
				getLanguagesLoading: true,
			},
		});
		getAllLanguages().then((res) => {
			dispatch({
				type: GET_ALL_LANGUAGES,
				payload: {
					getLanguagesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LANGUAGES_SUCCESS,
					payload: {
						languages: res?.data,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL LANGUAGES LIST
	getAllLanguagesList: () => (dispatch) => {
		dispatch({
			type: GET_ALL_LANGUAGES_LIST,
			payload: {
				getLanguagesListLoading: true,
			},
		});
		getAllLanguagesList().then((res) => {
			dispatch({
				type: GET_ALL_LANGUAGES_LIST,
				payload: {
					getLanguagesListLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LANGUAGES_LIST_SUCCESS,
					payload: {
						languagesList: res?.data,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//GET LANGUAGE
	getLanguage: (id) => (dispatch) => {
		dispatch({
			type: GET_LANGUAGE,
			payload: {
				getLanguageLoading: true,
			},
		});
		getLanguage(id).then((res) => {
			dispatch({
				type: GET_LANGUAGE,
				payload: {
					getLanguageLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LANGUAGE_SUCCESS,
					payload: {
						currentLanguage: res?.data,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//UPDATE LANGUAGE
	updateLanguage: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_LANGUAGE,
			payload: {
				updateLanguageLoading: true,
			},
		});

		updateLanguage(data, id).then((res) => {
			dispatch({
				type: UPDATE_LANGUAGE,
				payload: {
					updateLanguageLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_LANGUAGE_SUCCESS,
					payload: {
						languages: res?.data,
					},
				});
				history.push("/languages");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE LANGUAGE
	deleteLanguage: (id) => (dispatch) => {
		dispatch({
			type: DELETE_LANGUAGE,
			payload: {
				deleteLanguageLoading: true,
			},
		});
		deleteLanguage(id).then((res) => {
			dispatch({
				type: DELETE_LANGUAGE,
				payload: {
					deleteLanguageLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_LANGUAGE_SUCCESS,
					payload: {
						languages: res?.data,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
