import {
	DELETE_RECEIPT,
	DELETE_RECEIPT_SUCCESS,
	ADD_RECEIPT,
	ADD_RECEIPT_SUCCESS,
	UPDATE_RECEIPT,
	UPDATE_RECEIPT_SUCCESS,
	GET_RECEIPTS,
	GET_RECEIPTS_SUCCESS,
	GET_RECEIPT,
	GET_RECEIPT_SUCCESS,
	SET_RECEIPT_RANGE_VALUES,
	GET_CUSTOMER_RECEIPTS,
	GET_CUSTOMER_RECEIPTS_SUCCESS

} from "../actions/Receipts.action";

const RESET_LOADING = "RESET_LOADING"

const initialState = {
	addReceiptLoading: false,
	updateReceiptLoading: false,
	getReceiptLoading: false,
	getReceiptsLoading: false,
	getCustomerReceiptsLoading: false,
	deleteReceiptLoading: false,

	receipts: [],
	customerReceipts: [],
	addedReceipt: "",
	currentReceipt: "",
	rangeValues: []
};

function branch(state = initialState, action) {

	switch (action.type) {

		case RESET_LOADING:
			return Object.assign({}, state, {
				addReceiptLoading: false,
				updateReceiptLoading: false,
				getReceiptLoading: false,
				getReceiptsLoading: false,
				getCustomerReceiptsLoading:false,
				deleteReceiptLoading: false,

			});
		case SET_RECEIPT_RANGE_VALUES:
			return Object.assign({}, state, {
				rangeValues: action.payload.rangeValues,
			});

		case ADD_RECEIPT_SUCCESS:
			return Object.assign({}, state, {
				receipts: action.payload.receipts,
				addedReceipt: action.payload.addedReceipt
			});
		case ADD_RECEIPT:
			return Object.assign({}, state, {
				addReceiptLoading: action.payload.addReceiptLoading,
			});

		case GET_RECEIPTS_SUCCESS:
			return Object.assign({}, state, {
				receipts: action.payload.receipts,
			});

		case GET_RECEIPTS:
			return Object.assign({}, state, {
				getReceiptsLoading: action.payload.getReceiptsLoading,

			});

		case GET_CUSTOMER_RECEIPTS_SUCCESS:
			return Object.assign({}, state, {
				customerReceipts: action.payload.customerReceipts,
			});

		case GET_CUSTOMER_RECEIPTS:
			return Object.assign({}, state, {
				getCustomerReceiptsLoading: action.payload.getCustomerReceiptsLoading,

			});
		case GET_RECEIPT_SUCCESS:
			return Object.assign({}, state, {
				currentReceipt: action.payload.currentReceipt,
			});

		case GET_RECEIPT:
			return Object.assign({}, state, {
				getReceiptLoading: action.payload.getReceiptLoading,
			});

		case UPDATE_RECEIPT_SUCCESS:
			return Object.assign({}, state, {
				receipts: action.payload.receipts,
			});
		case UPDATE_RECEIPT:
			return Object.assign({}, state, {
				editReceiptLoading: action.payload.editReceiptLoading,
			});

		case DELETE_RECEIPT_SUCCESS:
			return Object.assign({}, state, {
				receipts: action.payload.receipts,
			});
		case DELETE_RECEIPT:
			return Object.assign({}, state, {
				deleteReceiptLoading: action.payload.deleteReceiptLoading,
			});

		default:
			return state;
	}
}

export default branch;
