

export const CHANGE_FIRST_DAY_OF_WEEK = "CHANGE_FIRST_DAY_OF_WEEK";

const actions = {

	changeFirstDayOfWeek: (data) => (dispatch) => {

	dispatch({type:CHANGE_FIRST_DAY_OF_WEEK,payload:{day:data}})
	},
	
	
};

export default actions;
