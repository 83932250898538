
import { equalArrays } from "../../utils/equalArrays";
import {
    ADD_INVOICE_PRODUCT_TO_CART,
    DELETE_INVOICE_PRODUCT_FROM_CART,
    ADD_INVOICE,
    EDIT_INVOICE_SUCCESS,
    EDIT_INVOICE,
    EMPTY_INVOICE_PRODUCT_CART,
    CHANGE_INVOICE_PAGE,
    STORE_INVOICE,
    ADD_INVOICE_DISC,
    GET_ALL_INVOICES,
    SET_INVOICE_RANGE_VALUES,
    GET_INVOICE_USERS,
    GET_INVOICE_SUCCESS,
    STORE_INVOICE_PRODUCTS,
    EMPTY_INVOICE,
    CALCULATE_INVOICE_TAX,
    GET_INVOICE,
    CHANGE_INVOICE_PRODUCT_LOADER,
    DELETE_INVOICE,
    SET_INVOICE_VIEW,
    GET_ALL_INVOICES_FILTER,
    GET_ALL_INVOICES_FILTER_SUCCESS,
    GET_INVOICE_PARAMETERS,
    GET_INVOICE_PARAMETERS_LIST,
    GET_INVOICE_PARAMETERS_SUCCESS,
    EDIT_INVOICE_PARAMETERS,
    EDIT_INVOICE_PARAMETERS_SUCCESS,
    SET_AS_DELIVERED,
    SET_ALL_DELIVERED,
    UPLOAD_INVOICE_FOOTER_LOGO,
    UPLOAD_INVOICE_HEADER_LOGO
} from "../actions/Invoices.action";

const initialState = {
    parametersList: [],
    parameters: [],
    products: [],
    subTotal: 0,
    discount: 0,
    cost: 0,
    salesData: {},
    tax: [],
    page: 1,
    invoiceDisc: { percentageDiscount: 0, amountDiscount: 0 },
    rangeValues: [],
    currentInvoice: {},
    flag: false,
    view: true,
    headerLogoUrl: "",
    footerLogoUrl: "",
    invoices: {
        newQuotations: [],
        oldQuotations: [],
        totalRecords: 0,
        totalPages: 1,
        page: 1,
        sum: 0,
        min: 0,
        max: 0
    },

    productLoader: false,
    addInvoiceLoading: false,
    getInvoiceLoading: false,
    getInvoicesLoading: false,
    getInvoicesFilterLoading: false,
    editInvoiceLoading: false,
    deleteInvoiceLoading: false,
    getParametersLoading: false,
    editParametersLoading: false,
    salesmen: [],
    users: []
};
let taxes = [];
const RESET_LOADING = "RESET_LOADING"
function branch(state = initialState, action) {
    switch (action.type) {

        case GET_INVOICE_USERS:
            return Object.assign({}, state, {
                users: action.payload.users,
                salesmen: action.payload.salesmen
            });

        case UPLOAD_INVOICE_HEADER_LOGO:
            return Object.assign({}, state, {
                headerLogoUrl: action.payload.headerLogoUrl
            });
        case UPLOAD_INVOICE_FOOTER_LOGO:
            return Object.assign({}, state, {
                footerLogoUrl: action.payload.footerLogoUrl
            });

        case RESET_LOADING:
            return Object.assign({}, state, {

                productLoader: false,
                addInvoiceLoading: false,
                getInvoiceLoading: false,
                getInvoicesLoading: false,
                getInvoicesFilterLoading: false,
                editInvoiceLoading: false,
                deleteInvoiceLoading: false,
                getParametersLoading: false,
                editParametersLoading: false,


            })
        case SET_INVOICE_VIEW:
            return Object.assign({}, state, {
                view: action.payload.view,
            });

        case ADD_INVOICE_PRODUCT_TO_CART:
            const productIndex = state.products.findIndex((product) => product.id === action.payload.product.id);
            let match = false;

            taxes = [...state.tax]
            //edit
            if (productIndex !== -1) {
                const updatedProducts = [...state.products];
                if (
                    action.payload.product.price === updatedProducts[productIndex]?.price &&
                    action.payload.product.discount === updatedProducts[productIndex]?.discount &&
                    action.payload.product.qty === updatedProducts[productIndex]?.qty &&
                    action.payload.product.remark === updatedProducts[productIndex]?.remark &&
                    action.payload.product.validity === updatedProducts[productIndex]?.validity &&
                    equalArrays(action.payload.product.linkedCustomers, updatedProducts[productIndex]?.linkedCustomers)
                ) {
                    match = true
                }

                if (!match) {
                    let oldTotalQty = updatedProducts[productIndex].linkedCustomers.reduce((acc, obj) => acc + obj.qty, 0) + updatedProducts[productIndex].qty;
                    state.subTotal -= parseFloat(oldTotalQty * updatedProducts[productIndex]?.price)
                    state.cost -= parseFloat(oldTotalQty * updatedProducts[productIndex]?.unitCost*((updatedProducts[productIndex]?.validity || 12)/12))
                    state.discount -= parseFloat(updatedProducts[productIndex]?.discount)

                    updatedProducts[productIndex]?.newTaxes?.forEach((obj) => {
                        const { taxUuid, taxAmount } = obj;
                        const index = taxes.findIndex(t => t.taxUuid === taxUuid)
                        if (index !== -1) {
                            taxes[index].taxAmount -= taxAmount;
                        }
                        else {
                            taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
                        }
                    });

                    updatedProducts[productIndex] = {
                        ...action.payload.product
                    };
                    let newTotalQty = action.payload.product.linkedCustomers.reduce((acc, obj) => acc + obj.qty, 0) + action.payload.product.qty;
                    state.subTotal += parseFloat(newTotalQty * action.payload.product?.price)
                    state.cost += parseFloat(newTotalQty * action.payload.product?.unitCost*((action.payload.product?.validity || 12)/12))
                    state.discount += parseFloat(action.payload.product?.discount);

                    action.payload.product?.newTaxes.forEach((obj) => {
                        const { taxUuid, taxAmount } = obj;
                        const index = taxes.findIndex(t => t.taxUuid === taxUuid)
                        if (index !== -1) {
                            taxes[index].taxAmount += taxAmount;
                        }
                        else {
                            taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
                        }
                    });
                    state.tax = [...taxes];

                    return {
                        ...state,
                        products: updatedProducts
                    };
                }
                else {
                    return {
                        ...state
                    };
                }

            }
            //add
            else {
                let newTotalQty = action.payload.product.linkedCustomers.reduce((acc, obj) => acc + obj.qty, 0) + action.payload.product.qty;
                state.subTotal += parseFloat(newTotalQty * action.payload.product?.price)
                state.cost += parseFloat(newTotalQty * action.payload.product?.unitCost*((action.payload.product?.validity || 12)/12))
                state.discount += parseFloat(action.payload.product?.discount);

                action.payload.product?.newTaxes.forEach((obj) => {
                    const { taxUuid, taxAmount } = obj;
                    const index = taxes.findIndex(t => t.taxUuid === taxUuid)
                    if (index !== -1) {
                        taxes[index].taxAmount += taxAmount;
                    }
                    else {
                        taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
                    }
                });

                state.tax = [...taxes];

                return {
                    ...state,
                    products: [
                        ...state.products, action.payload.product

                    ]
                };
            }

        case SET_AS_DELIVERED:

            let productsIndex = state.products?.findIndex(
                (product) => product.id === action.payload.product.id
            );

            //edit
            if (productsIndex !== -1) {
                const updated = [...state.products];

                updated[productsIndex] = {
                    ...action.payload.product
                };

                return {
                    ...state,
                    products: updated
                };



            }
            //add
            else {
                return {
                    ...state,
                    products:
                        [
                            ...state.products,
                            action.payload.product,

                        ]
                };
            }

        case SET_ALL_DELIVERED:

            let updatedCopy = [...state.products];

            updatedCopy = updatedCopy.map(obj => ({
                ...obj,
                totalDeliveredQty: obj?.qty - obj?.totalDeliveredQty,
                linkedCustomers: obj.linkedCustomers?.map((cust) => ({
                    uuid: cust?.uuid,
                    qty: cust?.qty,
                    deliveredAddedQty: cust?.qty-cust?.deliveredQty
                }))
            }));
            return {
                ...state,
                products: updatedCopy
            };



        case DELETE_INVOICE_PRODUCT_FROM_CART:

            taxes = [...state.tax];
            const oldProducts = [...state.products];
            const index = oldProducts.findIndex((prod) => prod.id === action.payload.id)
            let oldTotalQty = oldProducts[index].linkedCustomers.reduce((acc, obj) => acc + obj.qty, 0) + oldProducts[index].qty;

            state.subTotal -= parseFloat(oldTotalQty * oldProducts[index]?.price)
            state.cost -= parseFloat(oldTotalQty * oldProducts[index]?.unitCost*((oldProducts[index]?.validity || 12)/12))
            state.discount -= parseFloat(oldProducts[index]?.discount);

            oldProducts[index]?.newTaxes?.forEach((obj) => {
                const { taxUuid, taxAmount } = obj;
                const index = taxes.findIndex(t => t.taxUuid === taxUuid)
                if (index !== -1) {
                    taxes[index].taxAmount -= taxAmount;
                }

            });

            const updatedProducts = state.products.filter((product) => product.id !== action.payload.id);


            if (updatedProducts?.length === 0) {
                state.invoiceDisc = { percentageDiscount: 0, amountDiscount: 0 }
            }
            return {
                ...state,
                products: updatedProducts,
            };

        case EMPTY_INVOICE_PRODUCT_CART:
            return Object.assign({}, state, {
                subTotal: 0,
                products: [],
                cost: 0,
                tax: [],
                discount: 0,
                salesData: {},
                invoiceDisc: { percentageDiscount: 0, amountDiscount: 0 }
            });


        case ADD_INVOICE:
            return Object.assign({}, state, {
                addInvoiceLoading: action.payload.addInvoiceLoading
            });
        case EDIT_INVOICE_SUCCESS:
            return Object.assign({}, state, {
                currentInvoice: action.payload.editedInvoice
            });
        case EDIT_INVOICE:
            return Object.assign({}, state, {
                editInvoiceLoading: action.payload.editInvoiceLoading
            });
        case GET_INVOICE_SUCCESS:
            return Object.assign({}, state, {
                currentInvoice: action.payload.invoice,
            });
        case GET_INVOICE:
            return Object.assign({}, state, {
                getInvoiceLoading: action.payload.getInvoiceLoading
            });


        case DELETE_INVOICE:
            return Object.assign({}, state, {
                deleteInvoiceLoading: action.payload.deleteInvoiceLoading,
            });

        case GET_ALL_INVOICES:
            return Object.assign({}, state, {
                getInvoicesLoading: action.payload.getInvoicesLoading,
            });
        case GET_ALL_INVOICES_FILTER_SUCCESS:
            let oldInvoices = [];

            if (action.payload.page === 1 || !action.payload.view)
                oldInvoices = [];

            else {
                oldInvoices = [...state?.invoices?.newInvoices];
            }
            return Object.assign({}, state, {
                invoices: {
                    newInvoices: action.payload.invoices,
                    oldInvoices: [...oldInvoices],
                    totalRecords: action.payload.totalRecords,
                    totalPages: action.payload.totalPages,
                    page: action.payload.page,
                    sum: action.payload.sum,
                    min: action.payload.min,
                    max: action.payload.max
                }
            });

        case GET_ALL_INVOICES_FILTER:
            return Object.assign({}, state, {
                getInvoicesFilterLoading: action.payload.getInvoicesFilterLoading,
            });
        case SET_INVOICE_RANGE_VALUES:
            return Object.assign({}, state, {
                rangeValues: action.payload.rangeValues,
            });


        case CALCULATE_INVOICE_TAX:

            taxes = []
            action.payload?.data?.forEach((product) => {

                product?.newTaxes?.forEach((obj) => {
                    const { taxUuid, taxAmount } = obj;
                    const index = taxes.findIndex(t => t.taxUuid === taxUuid)
                    if (index !== -1) {
                        taxes[index].taxAmount += taxAmount;
                    }
                    else {
                        taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
                    }
                });

            })

            return Object.assign({}, state, {
                tax: taxes,
            });

        case ADD_INVOICE_DISC:


            return Object.assign({}, state, {
                invoiceDisc: action.payload.invoiceDisc,
            });
        case STORE_INVOICE:
            return Object.assign({}, state, {
                salesData: action.payload.data,
            });
        case CHANGE_INVOICE_PRODUCT_LOADER:
            return Object.assign({}, state, {
                productLoader: action.payload.productLoader,
            });
        case EMPTY_INVOICE:
            return Object.assign({}, state, {
                salesData: {},
            });
        case STORE_INVOICE_PRODUCTS:
            taxes = [];
            action.payload?.data?.salesDetails?.forEach((obj) => {
                const { salesDetailsTaxes } = obj;
                salesDetailsTaxes?.forEach((t) => {
                    const { amount, tax } = t;
                    const index = taxes.findIndex(o => o.taxUuid === tax.uuid)

                    if (index !== -1) {
                        taxes[index].taxAmount += amount;
                    }
                    else {
                        taxes.push({ taxUuid: tax.uuid, taxAmount: amount })
                    }


                })


            });
            return Object.assign({}, state, {
                products: action.payload.data.salesDetails,
                subTotal: action.payload.data.subtotal,
                discount: action.payload.data.discount,
                cost: action?.payload?.data?.cost,
                tax: taxes,

            });

        case CHANGE_INVOICE_PAGE:
            return Object.assign({}, state, {
                page: action.payload.page,
                flag: action.payload.flag
            });



        case GET_INVOICE_PARAMETERS_SUCCESS:
            return Object.assign({}, state, {
                parameters: action.payload.parameters,
                headerLogoUrl: action?.payload?.parameters?.headerInfo?.logo,
                footerLogoUrl: action?.payload?.parameters?.footerInfo?.logo,

            });

        case GET_INVOICE_PARAMETERS_LIST:
            return Object.assign({}, state, {
                parametersList: action.payload.parametersList,
                headerLogoUrl: action?.payload?.parametersList?.headerInfo?.logo,
                footerLogoUrl: action?.payload?.parametersList?.footerInfo?.logo,

            });

        case GET_INVOICE_PARAMETERS:
            return Object.assign({}, state, {
                getParametersLoading: action.payload.getParametersLoading
            });

        case EDIT_INVOICE_PARAMETERS_SUCCESS:
            return Object.assign({}, state, {
                parameters: action.payload.parameters
            });

        case EDIT_INVOICE_PARAMETERS:
            return Object.assign({}, state, {
                editParametersLoading: action.payload.editParametersLoading
            });
        default:
            return state;
    }

}

export default branch;
