import {
    getRequests,
    updateRequest,
    getEmailServer,
    getEmailDestinations,
    addEmailDestinations,
    addEmailServer,
    getRequestsCount
} from "../../config/config";

import { toast } from "react-toastify";

export const GET_REQUESTS = "GET_REQUESTS";
export const GET_EMAIL_SERVER = "GET_EMAIL_SERVER";
export const GET_EMAIL_DESTINATIONS = "GET_EMAIL_DESTINATIONS";

export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_EMAIL_SERVER_SUCCESS = "GET_EMAIL_SERVER_SUCCESS";
export const GET_EMAIL_DESTINATIONS_SUCCESS = "GET_EMAIL_DESTINATIONS_SUCCESS";

export const ADD_EMAIL_SERVER = "ADD_EMAIL_SERVER";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const ADD_EMAIL_DESTINATIONS = "ADD_EMAIL_DESTINATIONS";

export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const ADD_EMAIL_SERVER_SUCCESS = "ADD_EMAIL_SERVER_SUCCESS";
export const ADD_EMAIL_DESTINATIONS_SUCCESS = "ADD_EMAIL_DESTINATIONS_SUCCESS";
export const GET_REQUESTS_COUNT='GET_REQUESTS_COUNT';

const actions = {
    //Add email server
    addEmailServer: (data) => (dispatch) => {
        dispatch({
            type: ADD_EMAIL_SERVER,
            payload: {
                addServerLoading: true,
            },
        });

        addEmailServer(data).then((res) => {
            dispatch({
                type: ADD_EMAIL_SERVER,
                payload: {
                    addServerLoading: false,
                },
            });

            if (res?.status === 200) {
                dispatch({
                    type: ADD_EMAIL_SERVER_SUCCESS,
                    payload: {
                        emailServer: res?.data?.crmEmailServer,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });

            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //Add email destinations
    addEmailDestinations: (data) => (dispatch) => {
        dispatch({
            type: ADD_EMAIL_DESTINATIONS,
            payload: {
                addDestinationsLoading: true,
            },
        });

        addEmailDestinations(data).then((res) => {
            dispatch({
                type: ADD_EMAIL_DESTINATIONS,
                payload: {
                    addDestinationsLoading: false,
                },
            });

            if (res?.status === 200) {
                dispatch({
                    type: ADD_EMAIL_DESTINATIONS_SUCCESS,
                    payload: {
                        emailDestinations: res?.data?.emailDestination,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });

            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //FETCH ALL Requests
    getAllRequests: (filters) => (dispatch) => {
        dispatch({
            type: GET_REQUESTS,
            payload: {
                getRequestsLoading: true,
            },
        });
        getRequests(filters).then((res) => {
            dispatch({
                type: GET_REQUESTS,
                payload: {
                    getRequestsLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_REQUESTS_SUCCESS,
                    payload: {
                        requests: res?.data?.requests,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },
    //GET REQUESTS COUNT
    getRequestsCount: (filters) => (dispatch) => {
        getRequestsCount().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_REQUESTS_COUNT,
                    payload: {
                        requestsCount: res?.data?.count,
                    },
                });
            } 
        });
    },

    //update Request
    updateRequest: (data, id, filters, history) => (dispatch) => {
        dispatch({
            type: UPDATE_REQUEST,
            payload: {
                updateRequestLoading: true,
            },
        });

        updateRequest(data, id, filters).then((res) => {
            dispatch({
                type: UPDATE_REQUEST,
                payload: {
                    updateRequestLoading: false,
                },
            });


            if (res?.status === 200) {
                dispatch({
                    type: UPDATE_REQUEST_SUCCESS,
                    payload: {
                        requests: res?.data?.requests,

                    },
                });
                if (res?.data?.leadUuid)
                    history.push(`/edit-lead/${res?.data?.leadUuid}`)
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //FETCH email server
    getEmailServer: () => (dispatch) => {
        dispatch({
            type: GET_EMAIL_SERVER,
            payload: {
                getServerLoading: true,
            },
        });
        getEmailServer().then((res) => {
            dispatch({
                type: GET_EMAIL_SERVER,
                payload: {
                    getServerLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_EMAIL_SERVER_SUCCESS,
                    payload: {
                        emailServer: res?.data?.crmEmailServer,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },


    //FETCH email detsiantions
    getEmailDestinations: () => (dispatch) => {
        dispatch({
            type: GET_EMAIL_DESTINATIONS,
            payload: {
                getDestinationsLoading: true,
            },
        });
        getEmailDestinations().then((res) => {
            dispatch({
                type: GET_EMAIL_DESTINATIONS,
                payload: {
                    getDestinationsLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_EMAIL_DESTINATIONS_SUCCESS,
                    payload: {
                        emailDestinations: res?.data?.emailDestination,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

};

export default actions;
