import {
	getAllStatements,
	getAllStatementsBranch
   
} from "../../config/config";


export const GET_STATEMENTS = "GET_STATEMENTS";
export const GET_STATEMENTS_SUCCESS = "GET_STATEMENTS_SUCCESS";


const actions = {
	
	//GET STATEMENTS Branch
	getAllStatementsBranch: (id,from,to,branchUuid) => (dispatch) => {
		dispatch({
			type: GET_STATEMENTS,
			payload: {
				getStatementsLoading: true
			},
		});
		getAllStatementsBranch(id,from,to,branchUuid).then((res) => {

			dispatch({
				type: GET_STATEMENTS,
				payload: {
					getStatementsLoading: false
				},
			});

			if (res?.status === 200) {
				
				dispatch({
					type: GET_STATEMENTS_SUCCESS,
					payload: {
						statements: res?.data?.statement,
						lastPayment:res?.data?.lastPayment,
						previousBalance:res?.data?.previousBalance,
						balance:res?.data?.balance

					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},
	

	//GET STATEMENTS
	getAllStatements: (id,from,to) => (dispatch) => {
		dispatch({
			type: GET_STATEMENTS,
			payload: {
				getStatementsLoading: true
			},
		});
		getAllStatements(id,from,to).then((res) => {

			dispatch({
				type: GET_STATEMENTS,
				payload: {
					getStatementsLoading: false
				},
			});

			if (res?.status === 200) {
				
				dispatch({
					type: GET_STATEMENTS_SUCCESS,
					payload: {
						statements: res?.data?.statement,
						lastPayment:res?.data?.lastPayment,
						previousBalance:res?.data?.previousBalance,
						balance:res?.data?.balance

					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},
};

export default actions;
