import axios from "axios";
import { CLOUD9_API } from "../constants";
export const PrivateApiCall = axios.create({
    baseURL: CLOUD9_API,
});
PrivateApiCall.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem("access_token");
        req.headers["incoming-origin"] = `delivery`;
        req.headers.Authorization = `Bearer ${token}`;
        if (req.controller) {
            req.signal = req.controller.signal;
            delete req.controller;
        }
        return req;
    },
    (err) => {
        throw err;
    }
);

PrivateApiCall.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        throw err;
    }
);

export const PublicApiCall = axios.create({
    baseURL: CLOUD9_API,
});

PublicApiCall.interceptors.request.use(
    (req) => {
        req.headers["incoming-origin"] = `delivery`;
        return req;
    },
    (err) => {
        throw err;
    }
);

PublicApiCall.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        throw err;
    }
);