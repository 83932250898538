import {
	ADD_SALE_SUCCESS,
	GET_ALL_SALES_SUCCESS,
	UPDATE_SALE_SUCCESS,
	DELETE_SALE_SUCCESS,
	GET_ALL_SALES_LIST
} from "../actions/Sales.action";

const initialState = {
	sales: [],
	salesList: [],
	salesmen: [],
	users: [],

	addSaleLoading: false,
	getSalesLoading: false,
	getSaleLoading: false,
	updateSaleLoading: false,
};

const RESET_LOADING = "RESET_LOADING"

function branch(state = initialState, action) {
	switch (action.type) {

		
		case RESET_LOADING:
			return Object.assign({}, state, {
				addSaleLoading: false,
				getSalesLoading: false,
				getSaleLoading: false,
				updateSaleLoading: false,

			})
		case ADD_SALE_SUCCESS:
			return Object.assign({}, state, {
				sales: action.payload.sales,
			});

		case GET_ALL_SALES_SUCCESS:
			return Object.assign({}, state, {
				sales: action.payload.sales,
			});
		case GET_ALL_SALES_LIST:
			return Object.assign({}, state, {
				salesList: action.payload.salesList,
			});

		case UPDATE_SALE_SUCCESS:
			return Object.assign({}, state, {
				sales: action.payload.sales,
			});

		case DELETE_SALE_SUCCESS:
			return Object.assign({}, state, {
				sales: action.payload.sales,
			});

		default:
			return state;
	}
}

export default branch;
