

import { SyncOutlined } from "@ant-design/icons";

function Loading() {


    return (
    <div className="loader-wrapper">
        <div className="loader">
            <SyncOutlined spin style={{ fontSize: "4rem", color: "#1890ff" }} />
            <div className="loader-text">Loading..</div>
        </div>
    </div>
    )
}
export default Loading;