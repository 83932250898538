import {
	addCompany,
	getAllCompanies,
	getCompany,
	updateCompany,
	deleteCompany,
	addOutlet,
	getAllOutlets,
	getAllOutletsList,
	getOutlet,
	updateOutlet,
	deleteOutlet,
	getAllCompaniesList
} from "../../config/config";

import { toast } from "react-toastify";

export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_COMPANIES_LIST = "GET_ALL_COMPANIES_LIST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY = "DELETE_COMPANY";

export const ADD_OUTLET_SUCCESS = "ADD_OUTLET_SUCCESS";
export const ADD_OUTLET = "ADD_OUTLET";
export const GET_ALL_OUTLETS_SUCCESS = "GET_ALL_OUTLETS_SUCCESS";
export const GET_ALL_OUTLETS = "GET_ALL_OUTLETS";
export const GET_OUTLET_SUCCESS = "GET_OUTLET_SUCCESS";
export const GET_OUTLET = "GET_OUTLET";
export const UPDATE_OUTLET_SUCCESS = "UPDATE_OUTLET_SUCCESS";
export const UPDATE_OUTLET = "UPDATE_OUTLET";
export const DELETE_OUTLET_SUCCESS = "DELETE_OUTLET_SUCCESS";
export const DELETE_OUTLET = "DELETE_OUTLET";

export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const GET_ALL_OUTLETS_LIST = "GET_ALL_OUTLETS_LIST";

const actions = {
	uploadLogo: (data) => (dispatch) => {
		dispatch({
			type: UPLOAD_LOGO,
			payload: {
				logoUrl: data,
			},
		});
	},
	//ADD COMPANY
	addCompany: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_COMPANY,
			payload: {
				addCompanyLoading: true,
			},
		});

		addCompany(data).then((res) => {
			dispatch({
				type: ADD_COMPANY,
				payload: {
					addCompanyLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_COMPANY_SUCCESS,
					payload: {
						companies: res?.data?.companies,
						addedCompany: res?.data?.addedCompany
					},
				});
				history?.push("/brands");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL COMPANIES
	getAllCompanies: () => (dispatch) => {
		dispatch({
			type: GET_ALL_COMPANIES,
			payload: {
				getCompaniesLoading: true
			},
		});
		getAllCompanies().then((res) => {
			dispatch({
				type: GET_ALL_COMPANIES,
				payload: {
					getCompaniesLoading: false
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_COMPANIES_SUCCESS,
					payload: {
						companies: res?.data?.companies,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL COMPANIES LIST
	getAllCompaniesList: () => (dispatch) => {

		getAllCompaniesList().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_COMPANIES_LIST,
					payload: {
						companiesList: res?.data?.companies,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {

				toast.error("companies")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE COMPANY
	getCompany: (id) => (dispatch) => {
		dispatch({
			type: GET_COMPANY,
			payload: {
				getCompanyLoading: true
			},
		});
		getCompany(id).then((res) => {
			dispatch({
				type: GET_COMPANY,
				payload: {
					getCompanyLoading: false
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_COMPANY_SUCCESS,
					payload: {
						currentCompany: res?.data?.company,
						logoUrl: res?.data?.company?.logo
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE COMPANY
	updateCompany: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_COMPANY,
			payload: {
				updateCompanyLoading: true
			},
		});

		updateCompany(data, id).then((res) => {
			dispatch({
				type: UPDATE_COMPANY,
				payload: {
					updateCompanyLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_COMPANY_SUCCESS,
					payload: {
						companies: res?.data?.companies,
					},
				});
				history.push("/brands");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE COMPANY
	deleteCompany: (id) => (dispatch) => {
		dispatch({
			type: DELETE_COMPANY,
			payload: {
				deleteCompanyLoading: true,
			},
		});
		deleteCompany(id).then((res) => {
			dispatch({
				type: DELETE_COMPANY,
				payload: {
					deleteCompanyLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_COMPANY_SUCCESS,
					payload: {
						companies: res?.data?.companies,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD OUTLET
	addOutlet: (data, history, promiseData) => (dispatch) => {
		dispatch({
			type: ADD_OUTLET,
			payload: {
				addOutletLoading: true,
			},
		});

		const promises = promiseData?.map(({ promise }) => promise);
		Promise.all(promises)
			.then((res) => {

				promiseData?.forEach(({ part }, index) => {
					if (res[index]?.status === 200) {
						data[part] = res[index]?.data?.fileName;
					}
				});


				addOutlet(data).then((res) => {
					dispatch({
						type: ADD_OUTLET,
						payload: {
							addOutletLoading: false,
						},
					});

					if (res?.status === 200) {
						dispatch({
							type: ADD_OUTLET_SUCCESS,
							payload: {
								outlets: res?.data?.branches,
							},
						});
						history.push("/outlets");
						toast.success("Outlet added" || "Success", { theme: "colored" });
					} else if (res?.status === 401) {
						window.location = "/session-ended";
					} else if (res?.status === 403) {
						window.location = "/forbidden";
					} else {
						toast.error(res?.data?.message || "error", { theme: "colored" });
					}
				});
			});

	},
	//FETCH ALL OUTLETS
	getAllOutlets: () => (dispatch) => {
		dispatch({
			type: GET_ALL_OUTLETS,
			payload: {
				getOutletsLoading: true,
			},
		});

		getAllOutlets().then((res) => {
			dispatch({
				type: GET_ALL_OUTLETS,
				payload: {
					getOutletsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_OUTLETS_SUCCESS,
					payload: {
						outlets: res?.data?.branches,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL OUTLETS
	getAllOutletsList: () => (dispatch) => {

		getAllOutletsList().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_OUTLETS_LIST,
					payload: {
						outletsList: res?.data?.branches,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("outlets")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE OUTLET
	getOutlet: (id) => (dispatch) => {
		dispatch({
			type: GET_OUTLET,
			payload: {
				getOutletLoading: true,
			},
		});
		getOutlet(id).then((res) => {
			dispatch({
				type: GET_OUTLET,
				payload: {
					getOutletLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_OUTLET_SUCCESS,
					payload: {
						currentOutlet: res?.data?.branch,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE OUTLET
	updateOutlet: (data, id, history, promiseData) => (dispatch) => {
		dispatch({
			type: UPDATE_OUTLET,
			payload: {
				updateOutletLoading: true,
			},
		});

		const promises = promiseData?.map(({ promise }) => promise);
		Promise.all(promises)
			.then((res) => {
				promiseData?.forEach(({ part }, index) => {
					if (res[index]?.status === 200) {
						data[part] = res[index]?.data?.fileName;
					}
				});

				updateOutlet(data, id).then((res) => {
					dispatch({
						type: UPDATE_OUTLET,
						payload: {
							updateOutletLoading: false,
						},
					});
					if (res?.status === 200) {
						dispatch({
							type: UPDATE_OUTLET_SUCCESS,
							payload: {
								outlets: res?.data?.branches,
							},
						});
						history.push("/outlets");
						toast.success("Outlet edited" || "Success", { theme: "colored" });
					} else if (res?.status === 401) {
						window.location = "/session-ended";
					} else if (res?.status === 403) {
						window.location = "/forbidden";
					} else {
						toast.error(res?.data?.message || "error", { theme: "colored" });
					}
				});
			})
	},

	//DELETE OUTLET
	deleteOutlet: (id) => (dispatch) => {
		dispatch({
			type: DELETE_OUTLET,
			payload: {
				deleteOutletLoading: true,
			},
		});
		deleteOutlet(id).then((res) => {
			dispatch({
				type: DELETE_OUTLET,
				payload: {
					deleteOutletLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_OUTLET_SUCCESS,
					payload: {
						outlets: res?.data?.branches,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
