
import {
	UPDATE_AUTOMATION_SUCCESS,
	DELETE_AUTOMATION_SUCCESS,
	ADD_AUTOMATION_SUCCESS,
	GET_AUTOMATIONS,
	GET_AUTOMATIONS_SUCCESS,
	GET_AUTOMATION_SUCCESS,
	UPLOAD_IMAGE,
	GET_TRANSLATIONS_SUCCESS,
	GET_GLOBAL_CONFIG_SUCCESS,
	ADD_ZONE_SUCCESS,
	ADD_ZONE,
	GET_ALL_ZONES_SUCCESS,
	GET_ALL_ZONES,
	GET_ZONE_SUCCESS,
	GET_ZONE,
	UPDATE_ZONE_SUCCESS,
	UPDATE_ZONE,
	DELETE_ZONE_SUCCESS,
	UPLOAD_IMAGE_SUCCESS,
	DOWNLOAD_TRANSLATIONS_SUCCESS,
	UPDATE_TRANSLATIONS_SUCCESS,
	UPDATE_TRANSLATIONS,
	GET_TRANSLATIONS,
	DELETE_ZONE,
	UPLOAD_LOGO,
	EDIT_PARAMETERS,
	EDIT_PARAMETERS_SUCCESS,
	GET_PARAMETERS,
	GET_PARAMETERS_SUCCESS,
	UPLOAD_HEADER_LOGO,
	UPLOAD_FOOTER_LOGO,
	GET_PARAMETERS_LIST,
	ADD_AUTOMATION,
	GET_AUTOMATION,
	UPDATE_AUTOMATION,
	DELETE_AUTOMATION
} from "../actions/Common.action";

const RESET_LOADING="RESET_LOADING"

const initialState = {
	addZoneLoading: false,
	updateZoneLoading: false,
	getZoneLoading: false,
	getZonesLoading: false,
	deleteZoneLoading: false,

	addAutomationLoading: false,
	updateAutomationLoading: false,
	getAutomationLoading: false,
	getAutomationsLoading: false,
	deleteAutomationLoading: false,

	getParametersLoading: false,
	editParametersLoading: false,
	parameters: {},
	parametersList: {},

	updateLoading:false,

	translations: [],
	modules:[],
	automations: [],
	translationsTableData: [],
	downloadedTranslations: [],
	countries: [],
	currencies: [],
	zones: [],
	currentZone: "",
	currentAutomation: {},
	logoUrl: "",
	languages: [],

	headerLogoUrl: null,
	footerLogoUrl: null
};



function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				addZoneLoading: false,
				updateZoneLoading: false,
				getZoneLoading: false,
				getZonesLoading: false,
				deleteZoneLoading: false,

				addAutomationLoading: false,
				updateAutomationLoading: false,
				getAutomationLoading: false,
				getAutomationsLoading: false,
				deleteAutomationLoading: false,

				getParametersLoading: false,
				editParametersLoading: false,
				updateLoading:false
			})
		case UPLOAD_IMAGE_SUCCESS:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl
			});

		case UPLOAD_IMAGE:
			return Object.assign({}, state, {
				logoLoading: action.payload.logoLoading
			});
		case UPLOAD_HEADER_LOGO:
			return Object.assign({}, state, {
				headerLogoUrl: action.payload.headerLogoUrl
			});
		case UPLOAD_FOOTER_LOGO:
			return Object.assign({}, state, {
				footerLogoUrl: action.payload.footerLogoUrl
			});

		case GET_PARAMETERS_SUCCESS:
			return Object.assign({}, state, {
				parameters: action.payload.parameters,
				headerLogoUrl: action?.payload?.parameters?.headerInfo?.logo,
				footerLogoUrl: action?.payload?.parameters?.footerInfo?.logo,

			});

		case GET_PARAMETERS_LIST:
			return Object.assign({}, state, {
				parametersList: action.payload.parametersList,
				headerLogoUrl: action?.payload?.parametersList?.headerInfo?.logo,
				footerLogoUrl: action?.payload?.parametersList?.footerInfo?.logo,

			});

		case GET_PARAMETERS:
			return Object.assign({}, state, {
				getParametersLoading: action.payload.getParametersLoading
			});

		case EDIT_PARAMETERS_SUCCESS:
			return Object.assign({}, state, {
				parameters: action.payload.parameters
			});

		case EDIT_PARAMETERS:
			return Object.assign({}, state, {
				editParametersLoading: action.payload.editParametersLoading
			});
		case GET_TRANSLATIONS_SUCCESS:
			return Object.assign({}, state, {
				translations: action.payload.translations,
				getLoading: action.payload.getLoading,
				languages: action.payload.languages,
			});
		case GET_TRANSLATIONS:
			return Object.assign({}, state, {
				getLoading: action.payload.getLoading,
			});
		case DOWNLOAD_TRANSLATIONS_SUCCESS:
			return Object.assign({}, state, {
				downloadedTranslations: action.payload.downloadedTranslations,
			});
		case UPDATE_TRANSLATIONS_SUCCESS:
			return Object.assign({}, state, {
				translations: action.payload.translations,
				updateLoading: action.payload.updateLoading,
			});
		case UPDATE_TRANSLATIONS:
			return Object.assign({}, state, {
				updateLoading: action.payload.updateLoading,
			});
		

		case GET_GLOBAL_CONFIG_SUCCESS:
			return Object.assign({}, state, {
				countries: action.payload.countries,
				currencies: action.payload.currencies,
				modules:action.payload.modules
			});

		case ADD_ZONE_SUCCESS:
			return Object.assign({}, state, {
				zones: action.payload.zones,
			});
		case ADD_ZONE:
			return Object.assign({}, state, {
				addZoneLoading: action.payload.addZoneLoading,
			});

		case GET_ALL_ZONES_SUCCESS:
			return Object.assign({}, state, {
				zones: action.payload.zones,
				currentZone: "",
			});
		case GET_ALL_ZONES:
			return Object.assign({}, state, {
				getZonesLoading: action.payload.getZonesLoading,
			});

		case GET_ZONE_SUCCESS:
			return Object.assign({}, state, {
				currentZone: action.payload.currentZone,
			});
		case GET_ZONE:
			return Object.assign({}, state, {
				getZoneLoading: action.payload.getZoneLoading,
			});

		case UPDATE_ZONE_SUCCESS:
			return Object.assign({}, state, {
				zones: action.payload.zones,
			});
		case UPDATE_ZONE:
			return Object.assign({}, state, {
				updateZoneLoading: action.payload.updateZoneLoading,
			});

		case DELETE_ZONE_SUCCESS:
			return Object.assign({}, state, {
				zones: action.payload.zones,
			});
		case DELETE_ZONE:
			return Object.assign({}, state, {
				deleteZoneLoading: action.payload.deleteZoneLoading,
			});



		case ADD_AUTOMATION_SUCCESS:
			return Object.assign({}, state, {
				automations: action.payload.automations,
			});
		case ADD_AUTOMATION:
			return Object.assign({}, state, {
				addAutomationLoading: action.payload.addAutomationLoading,
			});

		case GET_AUTOMATIONS_SUCCESS:
			return Object.assign({}, state, {
				automations: action.payload.automations,
				currentAutomation: {},
			});
		case GET_AUTOMATIONS:
			return Object.assign({}, state, {
				getAutomationsLoading: action.payload.getAutomationsLoading,
			});

		case GET_AUTOMATION_SUCCESS:
			return Object.assign({}, state, {
				currentAutomation: action.payload.currentAutomation,
			});
		case GET_AUTOMATION:
			return Object.assign({}, state, {
				getAutomationLoading: action.payload.getAutomationLoading,
			});

		case UPDATE_AUTOMATION_SUCCESS:
			return Object.assign({}, state, {
				automations: action.payload.automations,
			});
		case UPDATE_AUTOMATION:
			return Object.assign({}, state, {
				updateAutomationLoading: action.payload.updateAutomationLoading,
			});

		case DELETE_AUTOMATION_SUCCESS:
			return Object.assign({}, state, {
				automations: action.payload.automations,
			});
		case DELETE_AUTOMATION:
			return Object.assign({}, state, {
				deleteAutomationLoading: action.payload.deleteAutomationLoading,
			});
		default:
			return state;
	}
}

export default branch;
