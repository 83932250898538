import React, { useState, useEffect } from "react";
import "./CustomMenu.css";
import { Layout, Menu, Drawer } from "antd";
import { useHistory, Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/logo.png"
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import GetScreenSize from "../../utils/screenSize";
import { findParentKey } from "../../utils/findParentKey";
import { routes } from "../../layouts/routes";
import authActions from "../../redux/actions/Auth.actions";
const { Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = [
	"sub-1",
	"sub-2",
	"sub-3",
	"sub-4",
	"sub-5",
	"sub-6",
	"sub-7",
	"sub-8",
];

function CustomMenu({ userAccessPermissions, headerHidden, openKeys, setOpenKeys }) {
	const history = useHistory();
	const location = useLocation();
	const [collapse, setCollapse] = useState(true);
	const [visible, setVisible] = useState(false);
	const screenSize = GetScreenSize();
	const width=screenSize?.width;

	const handleOpenChange = (keys) => {

		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (latestOpenKey) {
			setOpenKeys([latestOpenKey]);
		} else {
			setOpenKeys([]);
		}
	};

	useEffect(() => {
		let submenu = findParentKey(routes, location?.pathname);
		if (submenu && submenu?.key) {
			setOpenKeys([submenu?.key])
		}

	}, [location?.pathname])

	useEffect(() => {
		if (visible) {
			let submenu = findParentKey(routes, location?.pathname);

			if (submenu && submenu?.key) {
				setOpenKeys([submenu?.key])
			}
			else {
				setOpenKeys([])
			}
		}
	}, [visible])

	useEffect(() => {
		if (!collapse) {
			let submenu = findParentKey(routes, location?.pathname);

			if (submenu && submenu?.key) {
				setOpenKeys([submenu?.key])
			} else {
				setOpenKeys([])
			}
		}
	}, [collapse])

	useEffect(() => {
		
		if (width < 767) {
			setCollapse(true);
			document.getElementById("close-sider").style.display = "none"
			document.getElementById("sider-open").style.display = "none"
			document.getElementById("menu-open").style.display = "flex"
		}
		else {
			setVisible(false);
			document.getElementById("menu-open").style.display = "none"
			if (collapse) {
				document.getElementById("close-sider").style.display = "none"
				document.getElementById("sider-open").style.display = "flex"

			}
			else {

				document.getElementById("close-sider").style.display = "flex"
				document.getElementById("sider-open").style.display = "none"
			}
		}
	}, [width]);


	useEffect(() => {
		if (headerHidden) {

			document.getElementById("close-sider").style.top = "12px"
			document.getElementById("sider-open").style.top = "12px"
			document.getElementById("menu-open").style.top = "12px"

		}
		else {

			document.getElementById("close-sider").style.top = "10px"
			document.getElementById("sider-open").style.top = "10px"
			document.getElementById("menu-open").style.top = "10px"

		}
	}, [headerHidden]);

	const hideMenu = (e) => {
		setVisible(true);
		// document.getElementById("menu-open").style.display = "none"
	};

	const handleOpen = (e) => {
		setCollapse(false);
		document.getElementById("close-sider").style.display = "flex"
		document.getElementById("sider-open").style.display = "none"

	}

	const handleClose = (e) => {

		setCollapse(true);
		document.getElementById("close-sider").style.display = "none"
		document.getElementById("sider-open").style.display = "flex"

	};

	const drawerTitle = (
		<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

			<div className="logo">
				<img src={logo} alt="" className="img-logo" style={{ width: "40px", height: "40px" }} />
			</div>
			<CloseOutlined
				className="close-drawer-icon"
				style={{
					color: "white",
					paddingLeft: "300px",
					paddingBottom: "8px",
					cursor: "pointer"

				}}

				onClick={() => {

					setVisible(false);


				}} />
		</div>

	);

	return (
		<>

			<div id="menu-open" onClick={(e) => {
				hideMenu(e);
			}}>

				<MenuOutlined


				/>
			</div>
			<div id="sider-open" onClick={(e) => {
				handleOpen(e);
			}}>
				<MenuOutlined


				/>
			</div>
			<div id="close-sider" onClick={(e) => {
				handleClose(e);
			}}>
				<MenuOutlined


				/>
			</div>


			{width > 767 ?
				<Sider
					collapsible
					collapsed={collapse}
					width={250}
					breakpoint={"sm"}
					collapsedWidth={0}
				// onCollapse={(collapsed, type) => {
				// 	collapse === true ? setCollapse(false) : setCollapse(true);
				// }}
				>
					<div className="logo">
						<img src={logo} alt="" className="img-logo" style={{ width: "40px", height: "40px" }} />
					</div>
					<Menu
						theme="dark"
						defaultSelectedKeys={["1"]}
						mode="inline"
						openKeys={openKeys}
						onOpenChange={handleOpenChange}
					>

						{routes.map((route, i) => {
							if (route?.type === "link") {
								if (
									userAccessPermissions.includes(route?.accessName) ||
									userAccessPermissions.includes("all") ||
									route?.accessName === "dashboard"
								) {
									return (

										<Menu.Item
											key={route?.key}
											icon={route.icon}
											className={
												route.route === location?.pathname
													? "ant-menu-item-selected"
													: ""
											}
											onClick={() => {
												handleClose();
											}}
										>
											<Link to={route.route}>{route.name}</Link>

										</Menu.Item>

									);
								} else return "";
							}
							else if (route?.type === "subMenu") {
								const found = userAccessPermissions.some((r) =>
									route?.accessChilds.includes(r)
								);
								if (found === true || userAccessPermissions.includes("all")) {

									return (
										<SubMenu key={route?.key} icon={route.icon} title={route.name}>
											{route?.content?.map((subroute, j) => {
												if (subroute?.type === "subMenu-l2") {
													const found = userAccessPermissions.some((r) =>
														subroute?.accessChildsL2.includes(r)
													);
													if (
														found === true ||
														userAccessPermissions.includes("all")
													) {
														return (
															<SubMenu
																key={subroute?.key}
																title={subroute.name}
																icon={subroute.icon}
															>
																{subroute?.content?.map((subroute2, k) => {
																	if (
																		userAccessPermissions.includes(
																			subroute2?.accessName
																		) ||
																		userAccessPermissions.includes("all")
																	) {
																		return (

																			<Menu.Item
																				key={subroute2?.key}
																				icon={subroute2?.icon}
																				className={
																					subroute2.routes?.includes(location.pathname)

																						? "ant-menu-item-selected"
																						: ""
																				}
																				onClick={() => {
																					handleClose();
																				}}
																			>

																				<Link to={subroute2.route}>{subroute2.name}
																				</Link>
																			</Menu.Item>

																		);
																	} else return "";
																})}
															</SubMenu>
														);
													} else return "";
												}
												else if (subroute?.type === "layout") {

													const found = userAccessPermissions.some((r) =>
														subroute?.accessChilds.includes(r)
													);

													let firstRoute = subroute?.content[0];//if all permissions then take first one becausebdefinitely it will be found

													if (!userAccessPermissions?.includes("all")) {
														firstRoute = subroute?.content?.find((r) =>
															userAccessPermissions?.includes(r?.accessName)
														);
													}


													if (found || userAccessPermissions.includes("all")) {
														return (
															<Menu.Item
																key={subroute?.key}
																icon={subroute?.icon}
																className={
																	subroute.routes?.includes(location.pathname)
																		? "ant-menu-item-selected"
																		: ""
																}
																onClick={() => {
																	handleClose();
																}}
															>
																<Link to={firstRoute?.route}>{subroute?.name}</Link>
															</Menu.Item>
														);
													} else return "";

												}
												else {
													if (
														userAccessPermissions.includes(subroute?.accessName) ||
														userAccessPermissions.includes("all")
													) {
														return (
															<Menu.Item
																key={subroute?.key}
																icon={subroute?.icon}
																className={
																	subroute.route === location.pathname
																		? "ant-menu-item-selected"
																		: ""
																}
																onClick={() => {
																	handleClose();
																}}
															>
																<Link to={subroute.route}>{subroute.name}</Link>
															</Menu.Item>
														);
													} else return "";
												}
											})}
										</SubMenu>
									);
								} else return "";
							}
						})}
					</Menu>
				</Sider>
				:
				<Drawer
					open={visible}
					placement="left"
					width={width}
					title={drawerTitle}
					closable={false}
					maskClosable={false}

				>
					<Menu
						theme="dark"
						defaultSelectedKeys={["1"]}
						mode="inline"
						openKeys={openKeys}
						onOpenChange={handleOpenChange}
					>
						{routes.map((route, i) => {
							if (route?.type === "link") {
								if (
									userAccessPermissions.includes(route?.accessName) ||
									userAccessPermissions.includes("all") ||
									route?.accessName === "dashboard"
								) {
									return (
										<Menu.Item
											key={route?.key}
											icon={route.icon}
											className={
												route.route === location.pathname
													? "ant-menu-item-selected"
													: ""
											}
											onClick={() => {
												if (width < 767) {
													setVisible(false);
												}
											}}
										>
											<Link to={route.route}>{route.name}</Link>
										</Menu.Item>
									);
								} else return "";
							}
							else if (route?.type === "subMenu") {
								const found = userAccessPermissions.some((r) =>
									route?.accessChilds.includes(r)
								);
								if (found === true || userAccessPermissions.includes("all")) {
									return (
										<SubMenu key={route?.key} icon={route.icon} title={route?.name}

										>
											{route?.content?.map((subroute, j) => {
												if (subroute?.type === "subMenu-l2") {
													const found = userAccessPermissions.some((r) =>
														subroute?.accessChildsL2.includes(r)
													);
													if (
														found === true ||
														userAccessPermissions.includes("all")
													) {
														return (
															<SubMenu
																key={subroute?.key}
																title={subroute.name}
																icon={subroute.icon}
															>
																{subroute?.content?.map((subroute2, k) => {
																	if (
																		userAccessPermissions.includes(
																			subroute2?.accessName
																		) ||
																		userAccessPermissions.includes("all")
																	) {
																		return (
																			<Menu.Item
																				key={subroute2?.key}
																				icon={subroute2?.icon}
																				className={
																					subroute2.routes?.includes(location.pathname)
																						? "ant-menu-item-selected"
																						: ""
																				}
																				onClick={() => {
																					if (width < 767) {
																						setVisible(false);
																					}
																				}}
																			>
																				<Link to={subroute2.route}>
																					{subroute2.name}
																				</Link>
																			</Menu.Item>
																		);
																	} else return "";
																})}
															</SubMenu>
														);
													} else return "";
												}
												else if (subroute?.type === "layout") {

													const foundSub = userAccessPermissions.some((r) =>
														subroute?.accessChilds.includes(r)
													);
													let firstRoute = subroute?.content[0];//if all permissions then take first one becausebdefinitely it will be found

													if (!userAccessPermissions?.includes("all")) {
														firstRoute = subroute?.content?.find((r) =>
															userAccessPermissions?.includes(r?.accessName)
														);
													}
													if (foundSub || userAccessPermissions.includes("all")) {
														return (
															<Menu.Item
																key={subroute?.key}
																icon={subroute?.icon}
																className={
																	subroute.routes?.includes(location.pathname)
																		? "ant-menu-item-selected"
																		: ""
																}
																onClick={() => {
																	if (width < 767) {
																		setVisible(false);
																	}
																}}
															>
																<Link to={firstRoute?.route}>{subroute?.name}</Link>
															</Menu.Item>
														);
													} else return "";

												} else {
													if (
														userAccessPermissions.includes(subroute?.accessName) ||
														userAccessPermissions.includes("all")
													) {
														return (
															<Menu.Item
																key={subroute?.key}
																icon={subroute?.icon}
																className={
																	subroute.route === location.pathname
																		? "ant-menu-item-selected"
																		: ""
																}
																onClick={() => {
																	if (width < 767) {
																		setVisible(false);
																	}
																}}
															>
																<Link to={subroute.route}>{subroute.name}</Link>
															</Menu.Item>
														);
													} else return "";
												}
											})}
										</SubMenu>
									);
								} else return "";
							}
						})}
					</Menu>
				</Drawer>
			}
		</>
	);
}

const mapStateToProps = (state) => ({
	userAccessPermissions: state.auth.accessPermissions,
	headerHidden: state.auth.headerHidden,
	openKeys: state.auth.openKeys
});

const mapDispatchToProps = (dispatch) => ({
	setOpenKeys: (keys) => {
		dispatch(authActions.setOpenKeys(keys));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMenu);
