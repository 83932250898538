import { act } from "react-dom/test-utils";
import {
	ADD_SUPPLIER,
	ADD_SUPPLIER_SUCCESS,
	GET_ALL_SUPPLIERS_SUCCESS,
	GET_ALL_SUPPLIERS,
	GET_SUPPLIER_SUCCESS,
	GET_SUPPLIER,
	UPDATE_SUPPLIER_SUCCESS,
	UPDATE_SUPPLIER,
	DELETE_SUPPLIER_SUCCESS,
	DELETE_SUPPLIER,
	GET_ALL_SUPPLIERS_LIST
} from "../actions/Suppliers.action";

const initialState = {
	addSupplierLoading: false,
	getSupplierLoading: false,
	getSuppliersLoading: false,
	updateSupplierLoading: false,
	deleteSupplierLoading: false,

	suppliers: [],
	suppliersList: [],
	addedSupplier: "",
	currentSupplier: "",
};

const RESET_LOADING="RESET_LOADING"

function branch(state = initialState, action) {
	switch (action.type) {

		case RESET_LOADING:
			return Object.assign({}, state, {
				addSupplierLoading: false,
				getSupplierLoading: false,
				getSuppliersLoading: false,
				updateSupplierLoading: false,
				deleteSupplierLoading: false,
			})
		case ADD_SUPPLIER_SUCCESS:
			return Object.assign({}, state, {
				suppliers: action.payload.suppliers,
				addedSupplier: action.payload.addedSupplier
			});
		case ADD_SUPPLIER:
			return Object.assign({}, state, {
				addSupplierLoading: action.payload.addSupplierLoading,
			});

		case GET_ALL_SUPPLIERS_SUCCESS:
			return Object.assign({}, state, {
				suppliers: action.payload.suppliers,
				currentSupplier: "",
			});
		case GET_ALL_SUPPLIERS_LIST:
			return Object.assign({}, state, {
				suppliersList: action.payload.suppliersList,
				currentSupplier: "",
			});
		case GET_ALL_SUPPLIERS:
			return Object.assign({}, state, {
				getSuppliersLoading: action.payload.getSuppliersLoading
			});

		case GET_SUPPLIER_SUCCESS:
			return Object.assign({}, state, {
				currentSupplier: action.payload.currentSupplier,
			});

		case GET_SUPPLIER:
			return Object.assign({}, state, {
				getSupplierLoading: action.payload.getSupplierLoading
			});
		case UPDATE_SUPPLIER_SUCCESS:
			return Object.assign({}, state, {
				suppliers: action.payload.suppliers,
			});

		case UPDATE_SUPPLIER:
			return Object.assign({}, state, {
				updateSupplierLoading: action.payload.updateSupplierLoading
			});

		case DELETE_SUPPLIER_SUCCESS:
			return Object.assign({}, state, {
				suppliers: action.payload.suppliers,
			});
		case DELETE_SUPPLIER:
			return Object.assign({}, state, {
				deleteSupplierLoading: action.payload.deleteSupplierLoading,
			});

		default:
			return state;
	}
}

export default branch;
