
import {
	DELETE_COMMENT_SUCCESS,
	SAVE_CHECKLIST,
	SAVE_TEMP_TASKS,
	SET_EDIT_ID,
	SET_EDIT_VISIBLE,
	SET_CHECK_DATA,
	SET_CHECK_VISIBLE,
	ADD_SALES_CALL_SUCCESS,
	ADD_SALES_CALL,
	GET_ALL_SALES_CALLS_SUCCESS,
	GET_ALL_SALES_CALLS_FILTER_SUCCESS,
	GET_ALL_SALES_CALLS_FILTER,
	GET_ALL_SALES_CALLS,
	GET_SALES_CALL_SUCCESS,
	GET_SALES_CALL,
	UPDATE_SALES_CALL_SUCCESS,
	UPDATE_SALES_CALL,
	DELETE_SALES_CALL_SUCCESS,
	ADD_SALES_CALL_TYPE_SUCCESS,
	ADD_SALES_CALL_TYPE,
	GET_ALL_SALES_CALL_TYPE_SUCCESS,
	GET_ALL_SALES_CALL_TYPE,
	GET_SALES_CALL_TYPE_SUCCESS,
	GET_SALES_CALL_TYPE,
	UPDATE_SALES_CALL_TYPE_SUCCESS,
	UPDATE_SALES_CALL_TYPE,
	DELETE_SALES_CALL_TYPE_SUCCESS,
	ADD_SALES_CALL_STATUS_SUCCESS,
	ADD_SALES_CALL_STATUS,
	GET_ALL_SALES_CALL_STATUS_SUCCESS,
	GET_ALL_SALES_CALL_STATUS,
	GET_SALES_CALL_STATUS_SUCCESS,
	GET_SALES_CALL_STATUS,
	UPDATE_SALES_CALL_STATUS_SUCCESS,
	UPDATE_SALES_CALL_STATUS,
	DELETE_SALES_CALL_STATUS_SUCCESS,
	DELETE_SALES_CALL,
	DELETE_SALES_CALL_STATUS,
	DELETE_SALES_CALL_TYPE,
	GET_ALL_SALES_CALL_STATUS_LIST,
	GET_ALL_SALES_CALL_TYPE_LIST,
	GET_ALL_SALES_CALL_CATEGORY,
	GET_SALES_CALL_CATEGORY,
	UPDATE_SALES_CALL_CATEGORY,
	DELETE_SALES_CALL_CATEGORY,
	ADD_SALES_CALL_CATEGORY,
	GET_ALL_SALES_CALL_CATEGORY_SUCCESS,
	GET_SALES_CALL_CATEGORY_SUCCESS,
	UPDATE_SALES_CALL_CATEGORY_SUCCESS,
	DELETE_SALES_CALL_CATEGORY_SUCCESS,
	ADD_SALES_CALL_CATEGORY_SUCCESS,
	GET_ALL_SALES_CALL_CATEGORY_LIST,
	GET_ALL_SALES_CALL_TYPE_COUNT,
	GET_COMMENTS,
	GET_COMMENTS_SUCCESS,
	UPDATE_COMMENTS_SUCCESS,
	UPDATE_COMMENTS,
	ADD_COMMENTS,
	ADD_COMMENTS_SUCCESS,
	DELETE_COMMENT,
	GET_ALL_CALLS_FILTER_SUCCESS
} from "../actions/SalesTasks.action";

const initialState = {
	deleteCommentLoading: false,
	getCommentsLoading: false,
	addCommentLoading: false,
	updateCommentsLoading: false,
	addSalesCallLoading: false,
	updateSalesCallLoading: false,
	getSalesCallLoading: false,
	getSalesCallsLoading: false,
	getSalesCallsFilterLoading: false,
	deleteSalesCallLoading: false,

	addSalesCallCategoryLoading: false,
	updateSalesCallCategoryLoading: false,
	getSalesCallCategoryLoading: false,
	getSalesCallCategoriesLoading: false,
	deleteSalesCallCategoryLoading: false,

	addSalesCallTypeLoading: false,
	updateSalesCallTypeLoading: false,
	getSalesCallTypeLoading: false,
	getSalesCallTypesLoading: false,
	deleteSalesCallTypeLoading: false,

	addSalesCallStatusLoading: false,
	updateSalesCallStatusLoading: false,
	getSalesCallStatusLoading: false,
	getSalesCallStatusesLoading: false,
	deleteSalesCallStatusLoading: false,

	comments: [],
	salesCalls: [],
	allSalesCalls: {
		oldSalesCalls: [],
		newSalesCalls: [],
		groupedTypes: {},
		grouped: {},
		groupedSalesCalls: {}
	},
	addedSalesCall: "",
	groupedSalesCalls: [],
	currentSalesCall: "",
	currentSalesCallCategory: "",
	salesCallTypes: [],
	salesCallCategories: [],
	salesCallTypesList: [],
	salesCallCategoriesList: [],
	currentSalesCallType: "",
	salesCallStatus: [],
	salesCallStatusList: [],
	currentSalesCallStatus: "",

	addedType: "",
	addedStatus: "",
	addedCategory: "",
	checklist: [],
	tempTasks: {},
	editId: null,
	salesEditVisible: false,
	checkData: null,
	checkVisible: false,
	salesCallCallTypesCount: [],
};

const RESET_LOADING = "RESET_LOADING"
function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				deleteCommentLoading: false,
				getCommentsLoading: false,
				addCommentLoading: false,
				updateCommentsLoading: false,
				addSalesCallLoading: false,
				updateSalesCallLoading: false,
				getSalesCallLoading: false,
				getSalesCallsLoading: false,
				getSalesCallsFilterLoading: false,
				deleteSalesCallLoading: false,

				addSalesCallCategoryLoading: false,
				updateSalesCallCategoryLoading: false,
				getSalesCallCategoryLoading: false,
				getSalesCallCategoriesLoading: false,
				deleteSalesCallCategoryLoading: false,

				addSalesCallTypeLoading: false,
				updateSalesCallTypeLoading: false,
				getSalesCallTypeLoading: false,
				getSalesCallTypesLoading: false,
				deleteSalesCallTypeLoading: false,

				addSalesCallStatusLoading: false,
				updateSalesCallStatusLoading: false,
				getSalesCallStatusLoading: false,
				getSalesCallStatusesLoading: false,
				deleteSalesCallStatusLoading: false,

				editId: null,
				salesEditVisible: false,
				checkData: null,
				checkVisible: false,

			})
		case ADD_SALES_CALL_SUCCESS:
			return Object.assign({}, state, {
				salesCalls: action.payload.salesCalls,
				groupedSalesCalls: action.payload.groupedSalesCalls,
				addedSalesCall: action.payload.addedSalesCall

			});
		case ADD_SALES_CALL:
			return Object.assign({}, state, {
				addSalesCallLoading: action.payload.addSalesCallLoading,
			});

		case GET_ALL_SALES_CALLS_SUCCESS:
			return Object.assign({}, state, {
				salesCalls: action.payload.salesCalls,
				currentSalesCall: "",
				groupedSalesCalls: action.payload.groupedSalesCalls,
			});

		case GET_ALL_SALES_CALLS:
			return Object.assign({}, state, {
				getSalesCallsLoading: action.payload.getSalesCallsLoading
			});
		// case GET_ALL_SALES_CALLS_FILTER_SUCCESS:

		// 	return Object.assign({}, state, {
		// 		groupedSalesCalls: action.payload.groupedSalesCalls,
		// 		salesCalls: action.payload.salesCalls,
				

		// 	});



		case GET_ALL_SALES_CALLS_FILTER_SUCCESS:
			let oldCalls = [];
			if (action.payload.page === 1 || !action.payload.view) {
				oldCalls = [];
			}
			else {
				oldCalls = [...state.allSalesCalls.newSalesCalls];
			}

			return Object.assign({}, state, {
				allSalesCalls: {
					newSalesCalls: action.payload.salesCalls,
					oldSalesCalls: [...oldCalls],
					grouped: action.payload.groupedSalesCalls || {},
					groupedTypes: action.payload.groupedTypes || {},
					totalRecords: action.payload.totalRecords || 1,
					totalPages: action.payload.totalPages || 1,
					page: action.payload.page || 1,
				}
			});


		case GET_ALL_SALES_CALLS_FILTER:
			return Object.assign({}, state, {
				getSalesCallsFilterLoading: action.payload.getSalesCallsFilterLoading
			});

		case GET_SALES_CALL_SUCCESS:
			return Object.assign({}, state, {
				currentSalesCall: action.payload.currentSalesCall,
			});

		case GET_SALES_CALL:
			return Object.assign({}, state, {
				getSalesCallLoading: action.payload.getSalesCallLoading
			});

		case UPDATE_SALES_CALL_SUCCESS:
			return Object.assign({}, state, {
				salesCalls: action.payload.salesCalls,
				groupedSalesCalls: action.payload.groupedSalesCalls,
				addedSalesCall: action.payload.addedSalesCall
			});

		case UPDATE_SALES_CALL:
			return Object.assign({}, state, {
				updateSalesCallLoading: action.payload.updateSalesCallLoading
			});

		case DELETE_SALES_CALL_SUCCESS:
			return Object.assign({}, state, {
				salesCalls: action.payload.salesCalls,
				groupedSalesCalls: action.payload.groupedSalesCalls,
			});
		case DELETE_SALES_CALL:
			return Object.assign({}, state, {
				deleteSalesCallLoading: action.payload.deleteSalesCallLoading,
			});

		case ADD_SALES_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				salesCallTypes: action.payload.salesCallTypes,
				addedType: action.payload.addedType
			});
		case ADD_SALES_CALL_TYPE:
			return Object.assign({}, state, {
				addSalesCallTypeLoading: action.payload.addSalesCallTypeLoading
			});
		case GET_ALL_SALES_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				salesCallTypes: action.payload.salesCallTypes,
				currentsalesCallType: "",

			});

		case GET_ALL_SALES_CALL_TYPE_LIST:
			return Object.assign({}, state, {
				salesCallTypesList: action.payload.salesCallTypesList,
				currentSalesCallType: "",

			});
		case GET_ALL_SALES_CALL_TYPE_COUNT:
			return Object.assign({}, state, {
				salesCallTypesCount: action.payload.salesCallTypesCount,


			});

		case GET_COMMENTS:
			return Object.assign({}, state, {
				getCommentsLoading: action.payload.getCommentsLoading

			});

		case GET_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case UPDATE_COMMENTS:
			return Object.assign({}, state, {
				updateCommentsLoading: action.payload.updateCommentsLoading

			});

		case UPDATE_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case DELETE_COMMENT:
			return Object.assign({}, state, {
				deleteCommentLoading: action.payload.deleteCommentLoading

			});

		case DELETE_COMMENT_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case ADD_COMMENTS:
			return Object.assign({}, state, {
				addCommentsLoading: action.payload.addCommentsLoading

			});

		case ADD_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});
		case GET_ALL_SALES_CALL_TYPE:
			return Object.assign({}, state, {
				getSalesCallTypesLoading: action.payload.getSalesCallTypesLoading
			});
		case GET_ALL_CALLS_FILTER_SUCCESS:
			return Object.assign({}, state, {
				salesCalls: action.payload.salesCalls,
				groupedSalesCalls: action.payload.groupedSalesCalls,
			});


		case SAVE_CHECKLIST:
			return Object.assign({}, state, {
				checklist: action.payload.checklist
			});
		case SET_EDIT_ID:
			return Object.assign({}, state, {
				editId: action.payload.editId
			});
		case SET_EDIT_VISIBLE:
			return Object.assign({}, state, {
				salesEditVisible: action.payload.editVisible
			});
		case SET_CHECK_DATA:
			return Object.assign({}, state, {
				checkData: action.payload.checkData
			});
		case SET_CHECK_VISIBLE:
			return Object.assign({}, state, {
				checkVisible: action.payload.checkVisible
			});
		case SAVE_TEMP_TASKS:
			return Object.assign({}, state, {
				tempTasks: action.payload.tasks
			});

		case GET_SALES_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				currentSalesCallType: action.payload.currentSalesCallType,
			});
		case GET_SALES_CALL_TYPE:
			return Object.assign({}, state, {
				getSalesCallTypeLoading: action.payload.getSalesCallTypeLoading
			});

		case UPDATE_SALES_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				salesCallTypes: action.payload.salesCallTypes,
			});

		case UPDATE_SALES_CALL_TYPE:
			return Object.assign({}, state, {
				updateSalesCallTypeLoading: action.payload.updateSalesCallTypeLoading
			});

		case DELETE_SALES_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				salesCallTypes: action.payload.salesCallTypes,
			});
		case DELETE_SALES_CALL_TYPE:
			return Object.assign({}, state, {
				deleteSalesCallTypeLoading: action.payload.deleteSalesCallTypeLoading,
			});

		case ADD_SALES_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				salesCallStatus: action.payload.salesCallStatus,
				addedStatus: action.payload.addedStatus
			});

		case ADD_SALES_CALL_STATUS:
			return Object.assign({}, state, {
				addSalesCallStatusLoading: action.payload.addSalesCallStatusLoading
			});

		case GET_ALL_SALES_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				salesCallStatus: action.payload.salesCallStatus,
				currentSalesCallStatus: "",
			});

		case GET_ALL_SALES_CALL_STATUS_LIST:
			return Object.assign({}, state, {
				salesCallStatusList: action.payload.salesCallStatusList,
				currentSalesCallStatus: "",
			});

		case GET_ALL_SALES_CALL_STATUS:
			return Object.assign({}, state, {
				getsalesCallStatusesLoading: action.payload.getSalesCallStatusesLoading,
			});


		case GET_SALES_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				currentSalesCallStatus: action.payload.currentSalesCallStatus,
			});

		case GET_SALES_CALL_STATUS:
			return Object.assign({}, state, {
				getSalesCallStatusLoading: action.payload.getSalesCallStatusLoading
			});

		case UPDATE_SALES_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				salesCallStatus: action.payload.salesCallStatus,
			});

		case UPDATE_SALES_CALL_STATUS:
			return Object.assign({}, state, {
				updateSalesCallStatusLoading: action.payload.updateSalesCallStatusLoading
			});

		case DELETE_SALES_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				salesCallStatus: action.payload.salesCallStatus,
			});
		case DELETE_SALES_CALL_STATUS:
			return Object.assign({}, state, {
				deleteSalesCallStatusLoading: action.payload.deleteSalesCallStatusLoading,
			});



		case ADD_SALES_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				salesCallCategories: action.payload.salesCallCategories,
				addedCategory: action.payload.addedCategory
			});
		case ADD_SALES_CALL_CATEGORY:
			return Object.assign({}, state, {
				addSalesCallCategoryLoading: action.payload.addSalesCallCategoryLoading
			});
		case GET_ALL_SALES_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				salesCallCategories: action.payload.salesCallCategories,
				currentsalesCallCategory: "",

			});

		case GET_ALL_SALES_CALL_CATEGORY_LIST:
			return Object.assign({}, state, {
				salesCallCategoriesList: action.payload.salesCallCategoriesList,

			});
		case GET_ALL_SALES_CALL_CATEGORY:
			return Object.assign({}, state, {
				getSalesCallCategoriesLoading: action.payload.getSalesCallCategoriesLoading
			});

		case GET_SALES_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				currentSalesCallCategory: action.payload.currentSalesCallCategory,
			});
		case GET_SALES_CALL_CATEGORY:
			return Object.assign({}, state, {
				getSalesCallCategoryLoading: action.payload.getSalesCallCategoryLoading
			});

		case UPDATE_SALES_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				salesCallCategories: action.payload.salesCallCategories,
			});

		case UPDATE_SALES_CALL_CATEGORY:
			return Object.assign({}, state, {
				updateSalesCallCategoryLoading: action.payload.updateSalesCallCategoryLoading
			});

		case DELETE_SALES_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				salesCallCategories: action.payload.salesCallCategories,
			});
		case DELETE_SALES_CALL_CATEGORY:
			return Object.assign({}, state, {
				deleteSalesCallCategoryLoading: action.payload.deleteSalesCallCategoryLoading
			});
		default:
			return state;
	}
}

export default branch;
