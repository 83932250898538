import React, { lazy } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";


// COMPONENTS
import Footer from "./Footer";

// PAGES
const Login = lazy(() => import("../pages/Login"));

const { Content } = Layout;

function AuthLayout() {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Layout className="site-layout">
                <Content>
                    <Switch>
                        <Route
                            exact
                            path="/auth"
                            render={() => <Redirect to="/dashboard" />}
                        />
                        <Route exact path="/auth/login" component={Login} />

                        <Route render={() => <Redirect to='/error' />} />
                    </Switch>
                </Content>
              
            </Layout>
        </Layout>
    );

}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(AuthLayout));
