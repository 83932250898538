import {
	sortGroups,
	sortProducts,
	getGroupProducts,
	addProduct,
	getAllProducts,
	getProduct,
	updateProduct,
	deleteProduct,
	addProductType,
	getAllProductTypes,
	getProductType,
	updateProductType,
	deleteProductType,
	addProductGroup,
	getAllProductGroups,
	getProductGroup,
	updateProductGroup,
	deleteProductGroup,
	addProductCategory,
	getAllProductCategories,
	getProductCategory,
	updateProductCategory,
	deleteProductCategory,
	addProductBrand,
	getAllProductBrands,
	getProductBrand,
	updateProductBrand,
	deleteProductBrand,
	addProductUnit,
	getProductUnit,
	deleteProductUnit,
	updateProductUnit,
	getAllProductUnits,
	uploadProductImage,
	uploadImage,
	getAllProductTypesList,
	getAllProductBrandsList,
	getAllProductGroupsList,
	getAllProductUnitsList,
	getAllProductCategoriesList,
	getAllProductsList
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const GET_GROUP_PRODUCTS = "GET_GROUP_PRODUCTS";
export const GET_GROUP_PRODUCTS_SUCCESS = "GET_GROUP_PRODUCTS_SUCCESS";

export const SORT_PRODUCTS = "SORT_PRODUCTS";
export const SORT_PRODUCTS_SUCCESS = "SORT_PRODUCTS_SUCCESS";

export const SET_MENU_GROUPS = "SET_MENU_GROUPS";

export const SORT_GROUPS = "SORT_GROUPS";
export const SORT_GROUPS_SUCCESS = "SORT_GROUPS_SUCCESS";


export const ADD_PRODUCT_TYPE_SUCCESS = "ADD_PRODUCT_TYPE_SUCCESS";
export const GET_ALL_PRODUCT_TYPES_SUCCESS = "GET_ALL_PRODUCT_TYPES_SUCCESS";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const UPDATE_PRODUCT_TYPE_SUCCESS = "UPDATE_PRODUCT_TYPE_SUCCESS";
export const DELETE_PRODUCT_TYPE_SUCCESS = "DELETE_PRODUCT_TYPE_SUCCESS";

export const ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
export const GET_ALL_PRODUCT_TYPES = "GET_ALL_PRODUCT";
export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";
export const DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";

export const ADD_PRODUCT_UNIT_SUCCESS = "ADD_PRODUCT_UNIT_SUCCESS";
export const GET_ALL_PRODUCT_UNITS_SUCCESS = "GET_ALL_PRODUCT_UNITS_SUCCESS";
export const GET_PRODUCT_UNIT_SUCCESS = "GET_PRODUCT_UNIT_SUCCESS";
export const UPDATE_PRODUCT_UNIT_SUCCESS = "UPDATE_PRODUCT_UNIT_SUCCESS";
export const DELETE_PRODUCT_UNIT_SUCCESS = "DELETE_PRODUCT_UNIT_SUCCESS";

export const ADD_PRODUCT_UNIT = "ADD_PRODUCT_UNIT";
export const GET_ALL_PRODUCT_UNITS = "GET_ALL_PRODUCT_UNITS";
export const GET_PRODUCT_UNIT = "GET_PRODUCT_UNIT";
export const UPDATE_PRODUCT_UNIT = "UPDATE_PRODUCT_UNIT";
export const DELETE_PRODUCT_UNIT = "DELETE_PRODUCT_UNIT";

export const ADD_PRODUCT_GROUP_SUCCESS = "ADD_PRODUCT_GROUP_SUCCESS";
export const GET_ALL_PRODUCT_GROUPS_SUCCESS = "GET_ALL_PRODUCT_GROUPS_SUCCESS";
export const GET_PRODUCT_GROUP_SUCCESS = "GET_PRODUCT_GROUP_SUCCESS";
export const UPDATE_PRODUCT_GROUP_SUCCESS = "UPDATE_PRODUCT_GROUP_SUCCESS";
export const DELETE_PRODUCT_GROUP_SUCCESS = "DELETE_PRODUCT_GROUP_SUCCESS";

export const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
export const GET_ALL_PRODUCT_GROUPS = "GET_ALL_PRODUCT_GROUPS";
export const GET_PRODUCT_GROUP = "GET_PRODUCT_GROUP";
export const UPDATE_PRODUCT_GROUP = "UPDATE_PRODUCT_GROUP";
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP";

export const ADD_PRODUCT_BRAND_SUCCESS = "ADD_PRODUCT_BRAND_SUCCESS";
export const GET_ALL_PRODUCT_BRANDS_SUCCESS = "GET_ALL_PRODUCT_BRANDS_SUCCESS";
export const GET_PRODUCT_BRAND_SUCCESS = "GET_PRODUCT_BRAND_SUCCESS";
export const UPDATE_PRODUCT_BRAND_SUCCESS = "UPDATE_PRODUCT_BRAND_SUCCESS";
export const DELETE_PRODUCT_BRAND_SUCCESS = "DELETE_PRODUCT_BRAND_SUCCESS";

export const ADD_PRODUCT_BRAND = "ADD_PRODUCT_BRAND";
export const GET_ALL_PRODUCT_BRANDS = "GET_ALL_PRODUCT_BRANDS";
export const GET_PRODUCT_BRAND = "GET_PRODUCT_BRAND";
export const UPDATE_PRODUCT_BRAND = "UPDATE_PRODUCT_BRAND";
export const DELETE_PRODUCT_BRAND = "DELETE_PRODUCT_BRAND";

export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const GET_ALL_PRODUCT_CATEGORIES_SUCCESS = "GET_ALL_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";

export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_CATEGORIES = "GET_ALL_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";

export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_LOGO = "UPLOAD_LOGO";

export const GET_ALL_PRODUCT_UNITS_LIST = "GET_ALL_PRODUCT_UNITS_LIST";
export const GET_ALL_PRODUCT_CATEGORIES_LIST = "GET_ALL_PRODUCT_CATEGORIES_LIST";
export const GET_ALL_PRODUCT_TYPES_LIST = "GET_ALL_PRODUCT_TYPES_LIST";
export const GET_ALL_PRODUCT_BRANDS_LIST = "GET_ALL_PRODUCT_BRANDS_LIST";
export const GET_ALL_PRODUCT_GROUPS_LIST = "GET_ALL_PRODUCT_GROUPS_LIST";
export const GET_ALL_PRODUCTS_LIST = "GET_ALL_PRODUCTS_LIST";

export const SAVE_FILTERS="SAVE_FILTERS";
const actions = {
	saveFilters:(data)=>(dispatch)=>{
		dispatch({
			type:SAVE_FILTERS,
			payload:{filters: data}

		})
	},
	setMenuGoups:(data)=>(dispatch)=>{
		dispatch({
			type:SET_MENU_GROUPS,
			payload:{
				menuGroups:data
			}

		})
	},

	uploadLogo: (data) => (dispatch) => {
		dispatch(
			{
				type: UPLOAD_LOGO,
				payload: {
					logoUrl: data,
				},
			}
		)
	},
	//UPLOAD IMAGE
	uploadProductImage: (file, id) => async (dispatch) => {
		//console.log(file)
		var formData = new FormData();
		formData.append("image", file);
		dispatch({
			type: UPLOAD_IMAGE,
			payload: {
				logoLoading: true,
			},
		});
		uploadProductImage(formData, id).then((res) => {
			dispatch({
				type: UPLOAD_IMAGE,
				payload: {
					logoLoading: false,
				},
			});
			if (res?.status === 200) {
				//history.push("/upload/custLogo");
				dispatch({
					type: UPLOAD_IMAGE_SUCCESS,
					payload: {
						logoUrl: res?.data?.fileName,
					},
				});


				// toast.success(res?.data?.message || "Image uploaded", { theme: "colored" });

			} else if (res?.status === 401) {
				window.location = "/session-ended";

			} else if (res?.status === 403) {
				window.location = "/forbidden";

			} else {

				console.log(res?.data?.message || "error");
			}
		});
	},

	//UPLOAD IMAGE
	uploadImage: (file) => async (dispatch) => {
		var formData = new FormData();
		formData.append("image", file);
		dispatch({
			type: UPLOAD_IMAGE,
			payload: {
				logoLoading: true,
			},
		});
		uploadImage(formData).then((res) => {
			dispatch({
				type: UPLOAD_IMAGE,
				payload: {
					logoLoading: false,
				},
			});
			if (res?.status === 200) {
				//history.push("/upload/custLogo");

				dispatch({
					type: UPLOAD_IMAGE_SUCCESS,
					payload: {
						logoUrl: res?.data?.fileName,
					},
				});
				console.log(res?.data?.fileName)


				// toast.success(res?.data?.message || "Image uploaded", { theme: "colored" });

			} else if (res?.status === 401) {
				window.location = "/session-ended";

			} else if (res?.status === 403) {
				window.location = "/forbidden";

			} else {

				console.log(res?.data?.message || "error");
			}
		});
	},

	//ADD PRODUCT
	addProduct: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT,
			payload: {
				addProductLoading: true,
			},
		});

		addProduct(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT,
				payload: {
					addProductLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_PRODUCT_SUCCESS,
					payload: {
						products: res?.data?.products,
						menuGroups:res?.data?.groups
					},
				});
				history?.push("/products");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//SORT PRODUCT
	sortProducts: (data) => (dispatch) => {
		dispatch({
			type: SORT_PRODUCTS,
			payload: {
				sortProductsLoading: true,
			},
		});

		sortProducts(data).then((res) => {
			dispatch({
				type: SORT_PRODUCTS,
				payload: {
					sortProductsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type:SORT_PRODUCTS_SUCCESS,
					payload: {
						groupProducts: res?.data?.products,
						menuGroups:res?.data?.groups
					},
				});
				toast.success("Products order updated", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//SORT GROUPS
	sortGroups: (data) => (dispatch) => {
		dispatch({
			type: SORT_GROUPS,
			payload: {
				sortGroupsLoading: true,
			},
		});

		sortGroups(data).then((res) => {
			dispatch({
				type: SORT_GROUPS,
				payload: {
					sortGroupsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: SORT_GROUPS_SUCCESS,
					payload: {
						productGroupsList:res?.data?.productGroups
					},
				});
				toast.success("Groups order updated", { theme: "colored" });
				
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCTS
	getAllProducts: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCTS,
			payload: {
				getProductsLoading: true,
			},
		});
		getAllProducts().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCTS,
				payload: {
					getProductsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCTS_SUCCESS,
					payload: {
						products: res?.data?.products,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL GROUP PRODUCTS
	getGroupProducts: () => (dispatch) => {
		dispatch({
			type: GET_GROUP_PRODUCTS,
			payload: {
				getGroupProductsLoading: true,
			},
		});
		getGroupProducts().then((res) => {
			dispatch({
				type: GET_GROUP_PRODUCTS,
				payload: {
					getGroupProductsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_GROUP_PRODUCTS_SUCCESS,
					payload: {
						menuGroups: res?.data?.groups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCTS LIST
	getAllProductsList: () => (dispatch) => {
		
		getAllProductsList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCTS_LIST,
					payload: {
						productsList: res?.data?.products,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("products")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//GET SINGLE PRODUCT
	getProduct: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT,
			payload: {
				getProductLoading: true,
			},
		});
		getProduct(id).then((res) => {
			dispatch({
				type: GET_PRODUCT,
				payload: {
					getProductLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_SUCCESS,
					payload: {
						currentProduct: res?.data?.product,

					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT
	updateProduct: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_PRODUCT,
			payload: {
				updateProductLoading: true,
			},
		});

		updateProduct(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT,
				payload: {
					updateProductLoading: false,
				},
			});


			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_SUCCESS,
					payload: {
						products: res?.data?.products,
						menuGroups:res?.data?.groups
					},
				});
				history.push("/products");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT
	deleteProduct: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PRODUCT,
			payload: {
				deleteProductLoading: true,
			},
		});
		deleteProduct(id).then((res) => {
			dispatch({
				type: DELETE_PRODUCT,
				payload: {
					deleteProductLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PRODUCT_SUCCESS,
					payload: {
						products: res?.data?.products,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//ADD PRODUCT BRAND
	addProductBrand: (data) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT_BRAND,
			payload: {
				addProductBrandLoading: true,
			},
		});

		addProductBrand(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT_BRAND,
				payload: {
					addProductBrandLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_PRODUCT_BRAND_SUCCESS,
					payload: {
						productBrands: res?.data?.productBrands,
						addedBrand: res?.data?.addedBrand
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";

			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT BRANDS
	getAllProductBrands: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCT_BRANDS,
			payload: {
				getProductBrandsLoading: true,
			},
		});
		getAllProductBrands().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCT_BRANDS,
				payload: {
					getProductBrandsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_BRANDS_SUCCESS,
					payload: {
						productBrands: res?.data?.productBrands,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT BRANDS LIST
	getAllProductBrandsList: () => (dispatch) => {
		
		getAllProductBrandsList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_BRANDS_LIST,
					payload: {
						productBrandsList: res?.data?.productBrands,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("prouct brands")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE PRODUCT BRAND
	getProductBrand: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT_BRAND,
			payload: {
				getProductBrandLoading: true,
			},
		});
		getProductBrand(id).then((res) => {
			dispatch({
				type: GET_PRODUCT_BRAND,
				payload: {
					getProductBrandLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_BRAND_SUCCESS,
					payload: {
						currentProductBrand: res?.data?.productBrand,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT BRAND
	updateProductBrand: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_PRODUCT_BRAND,
			payload: {
				updateProductBrandLoading: true,
			},
		});

		updateProductBrand(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT_BRAND,
				payload: {
					updateProductBrandLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_BRAND_SUCCESS,
					payload: {
						productBrands: res?.data?.productBrands,
					},
				});
				history.push("/product-brands");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT BRAND
	deleteProductBrand: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PRODUCT_BRAND,
			payload: {
				deleteProductBrandLoading: true,
			},
		});
		deleteProductBrand(id).then((res) => {
			dispatch({
				type: DELETE_PRODUCT_BRAND,
				payload: {
					deleteProductBrandLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PRODUCT_BRAND_SUCCESS,
					payload: {
						productBrands: res?.data?.productBrands,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD PRODUCT TYPE
	addProductType: (data) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT_TYPE,
			payload: {
				addProductTypeLoading: true,
			},
		});

		addProductType(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT_TYPE,
				payload: {
					addProductTypeLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_PRODUCT_TYPE_SUCCESS,
					payload: {
						productTypes: res?.data?.productTypes,
						addedType: res?.data?.addedProductType
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT TYPES
	getAllProductTypes: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCT_TYPES,
			payload: {
				getProductTypesLoading: true,
			},
		});
		getAllProductTypes().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCT_TYPES,
				payload: {
					getProductTypesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_TYPES_SUCCESS,
					payload: {
						productTypes: res?.data?.productTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL PRODUCT TYPES LIST
	getAllProductTypesList: () => (dispatch) => {
		
		getAllProductTypesList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_TYPES_LIST,
					payload: {
						productTypesList: res?.data?.productTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("product types")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE PRODUCT TYPE
	getProductType: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT_TYPE,
			payload: {
				getProductTypeLoading: true,
			},
		});
		getProductType(id).then((res) => {
			dispatch({
				type: GET_PRODUCT_TYPE,
				payload: {
					getProductTypeLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_TYPE_SUCCESS,
					payload: {
						currentProductType: res?.data?.productType,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT TYPE
	updateProductType: (data, id, history) => (dispatch) => {

		dispatch({
			type: UPDATE_PRODUCT_TYPE,
			payload: {
				updateProductTypeLoading: true,
			},
		});

		updateProductType(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT_TYPE,
				payload: {
					updateProductTypeLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_TYPE_SUCCESS,
					payload: {
						productTypes: res?.data?.productTypes,
					},
				});
				history.push("/product-types");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT TYPE
	deleteProductType: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PRODUCT_TYPE,
			payload: {
				deleteProductTypeLoading: true,
			},
		});
		deleteProductType(id).then((res) => {
			dispatch({
				type: DELETE_PRODUCT_TYPE,
				payload: {
					deleteProductTypeLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PRODUCT_TYPE_SUCCESS,
					payload: {
						productTypes: res?.data?.productTypes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD PRODUCT GROUP
	addProductGroup: (data) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT_GROUP,
			payload: {
				addProductGroupLoading: true,
			},
		});

		addProductGroup(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT_GROUP,
				payload: {
					addProductGroupLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_PRODUCT_GROUP_SUCCESS,
					payload: {
						productGroups: res?.data?.productGroups,
						addedGroup: res?.data?.addedGroup
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT GROUPS
	getAllProductGroups: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCT_GROUPS,
			payload: {
				getProductGroupsLoading: true,
			},
		});
		getAllProductGroups().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCT_GROUPS,
				payload: {
					getProductGroupsLoading: false,
				},
			});
			if (res?.status === 200) {


				dispatch({
					type: GET_ALL_PRODUCT_GROUPS_SUCCESS,
					payload: {
						productGroups: res?.data?.productGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT GROUPS
	getAllProductGroupsList: () => (dispatch) => {
		
		getAllProductGroupsList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_GROUPS_LIST,
					payload: {
						productGroupsList: res?.data?.productGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("productgroups")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE PRODUCT GROUP
	getProductGroup: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT_GROUP,
			payload: {
				getProductGroupLoading: true,
			},
		});
		getProductGroup(id).then((res) => {
			dispatch({
				type: GET_PRODUCT_GROUP,
				payload: {
					getProductGroupLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_GROUP_SUCCESS,
					payload: {
						currentProductGroup: res?.data?.productGroup,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT GROUP
	updateProductGroup: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_PRODUCT_GROUP,
			payload: {
				updateProductGroupLoading: true,
			},
		});

		updateProductGroup(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT_GROUP,
				payload: {
					updateProductGroupLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_GROUP_SUCCESS,
					payload: {
						productGroups: res?.data?.productGroups,
					},
				});
				history.push("/product-groups");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT GROUP
	deleteProductGroup: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PRODUCT_GROUP,
			payload: {
				deleteProductGroupLoading: true,
			},
		});
		deleteProductGroup(id).then((res) => {
			dispatch({
				type: DELETE_PRODUCT_GROUP,
				payload: {
					deleteProductGroupLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PRODUCT_GROUP_SUCCESS,
					payload: {
						productGroups: res?.data?.productGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD PRODUCT CATEGORY
	addProductCategory: (data) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT_CATEGORY,
			payload: {
				addProductCategoryLoading: true,
			},
		});

		addProductCategory(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT_CATEGORY,
				payload: {
					addProductCategoryLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_PRODUCT_CATEGORY_SUCCESS,
					payload: {
						productCategories: res?.data?.productCategories,
						addedCategory: res?.data?.addedCategory
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT CATEGORIES
	getAllProductCategories: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCT_CATEGORIES,
			payload: {
				getProductCategoriesLoading: true,
			},
		});
		getAllProductCategories().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCT_CATEGORIES,
				payload: {
					getProductCategoriesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_CATEGORIES_SUCCESS,
					payload: {
						productCategories: res?.data?.productCategories,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT CATEGORIES LIST
	getAllProductCategoriesList: () => (dispatch) => {

		getAllProductCategoriesList().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_CATEGORIES_LIST,
					payload: {
						productCategoriesList: res?.data?.productCategories,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("product categories")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE PRODUCT CATEGORY
	getProductCategory: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT_CATEGORY,
			payload: {
				getProductCategoryLoading: true,
			},
		});
		getProductCategory(id).then((res) => {
			dispatch({
				type: GET_PRODUCT_CATEGORY,
				payload: {
					getProductCategoryLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_CATEGORY_SUCCESS,
					payload: {
						currentProductCategory: res?.data?.productCategory,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT CATEGORY
	updateProductCategory: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_PRODUCT_CATEGORY,
			payload: {
				updateProductCategoryLoading: true,
			},
		});

		updateProductCategory(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT_CATEGORY,
				payload: {
					updateProductCategoryLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
					payload: {
						productCategories: res?.data?.productCategories,
					},
				});
				history.push("/product-categories");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT CATEGORY
	deleteProductCategory: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PRODUCT_CATEGORY,
			payload: {
				deleteProductCategoryLoading: true,
			},
		});
		deleteProductCategory(id).then((res) => {
			dispatch({
				type: DELETE_PRODUCT_CATEGORY,
				payload: {
					deleteProductCategoryLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PRODUCT_CATEGORY_SUCCESS,
					payload: {
						productCategories: res?.data?.productCategories,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//ADD PRODUCT UNIT
	addProductUnit: (data) => (dispatch) => {
		dispatch({
			type: ADD_PRODUCT_UNIT,
			payload: {
				addProductUnitLoading: true,
			},
		});

		addProductUnit(data).then((res) => {
			dispatch({
				type: ADD_PRODUCT_UNIT,
				payload: {
					addProductUnitLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_PRODUCT_UNIT_SUCCESS,
					payload: {
						productUnits: res?.data?.productUnits,
						addedUnit: res?.data?.addedProductUnit
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL PRODUCT UNITS
	getAllProductUnits: () => (dispatch) => {
		dispatch({
			type: GET_ALL_PRODUCT_UNITS,
			payload: {
				getProductUnitsLoading: true,
			},
		});
		getAllProductUnits().then((res) => {
			dispatch({
				type: GET_ALL_PRODUCT_UNITS,
				payload: {
					getProductUnitsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_UNITS_SUCCESS,
					payload: {
						productUnits: res?.data?.productUnits,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL PRODUCT UNITS LIST
	getAllProductUnitsList: () => (dispatch) => {
		
		getAllProductUnitsList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_PRODUCT_UNITS_LIST,
					payload: {
						productUnitsList: res?.data?.productUnits,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("product units")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET SINGLE PRODUCT UNIT
	getProductUnit: (id) => (dispatch) => {
		dispatch({
			type: GET_PRODUCT_UNIT,
			payload: {
				getProductUnitLoading: true,
			},
		});
		getProductUnit(id).then((res) => {
			dispatch({
				type: GET_PRODUCT_UNIT,
				payload: {
					getProductUnitLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_PRODUCT_UNIT_SUCCESS,
					payload: {
						currentProductUnit: res?.data?.productUnit,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PRODUCT  UNIT
	updateProductUnit: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_PRODUCT_UNIT,
			payload: {
				updateProductUnitLoading: true,
			},
		});

		updateProductUnit(data, id).then((res) => {
			dispatch({
				type: UPDATE_PRODUCT_UNIT,
				payload: {
					updateProductUnitLoading: false,
				},
			});


			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PRODUCT_UNIT_SUCCESS,
					payload: {
						productUnits: res?.data?.productUnits,
					},
				});
				history.push("/product-units");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {

				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PRODUCT UNIT
	deleteProductUnit
		: (id) => (dispatch) => {
			dispatch({
				type: DELETE_PRODUCT_UNIT,
				payload: {
					deleteProductUnitLoading: true,
				},
			});
			deleteProductUnit(id).then((res) => {
				dispatch({
					type: DELETE_PRODUCT_UNIT,
					payload: {
						deleteProductUnitLoading: false,
					},
				});
				if (res?.status === 200) {
					toast.success(res?.data?.message || "Success", { theme: "colored" });
					dispatch({
						type: DELETE_PRODUCT_UNIT_SUCCESS,
						payload: {
							productUnits: res?.data?.productUnits,
						},
					});
				} else if (res?.status === 401) {
					window.location = "/session-ended";
				} else if (res?.status === 403) {
					window.location = "/forbidden";
				} else {
					toast.error(res?.data?.message || "error", { theme: "colored" });
				}
			});
		},


};

export default actions;
