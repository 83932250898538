import {
	addSupplier,
	getAllSuppliers,
	getSupplier,
	updateSupplier,
	deleteSupplier,
	getAllSuppliersList
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const GET_ALL_SUPPLIERS_SUCCESS = "GET_ALL_SUPPLIERS_SUCCESS";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";

export const ADD_SUPPLIER= "ADD_SUPPLIER";
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const GET_SUPPLIER = "GET_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const DELETE_SUPPLIER= "DELETE_SUPPLIER";

export const GET_ALL_SUPPLIERS_LIST= "GET_ALL_SUPPLIERS_LIST";

const actions = {
	//ADD SUPPLIER
	addSupplier: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_SUPPLIER,
			payload: {
				addSupplierLoading: true,
			},
		});

		addSupplier(data).then((res) => {
			dispatch({
				type: ADD_SUPPLIER,
				payload: {
					addSupplierLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: ADD_SUPPLIER_SUCCESS,
					payload: {
						suppliers: res?.data?.suppliers,
						addedSupplier:res?.data?.addedSupplier
					},
				});
				history?.push("/suppliers");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL SUPPLIERS
	getAllSuppliers: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SUPPLIERS,
			payload: {
				getSuppliersLoading: true,
			},
		});
		getAllSuppliers().then((res) => {
			dispatch({
				type: GET_ALL_SUPPLIERS,
				payload: {
					getSuppliersLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SUPPLIERS_SUCCESS,
					payload: {
						suppliers: res?.data?.suppliers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL SUPPLIERS LIST
	getAllSuppliersList: () => (dispatch) => {
		getAllSuppliersList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SUPPLIERS_LIST,
					payload: {
						suppliersList: res?.data?.suppliers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("suppliers")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE SUPPLIER
	getSupplier: (id) => (dispatch) => {
		dispatch({
			type: GET_SUPPLIER,
			payload: {
				getSupplierLoading: true,
			},
		});
		getSupplier(id).then((res) => {
			dispatch({
				type: GET_SUPPLIER,
				payload: {
					getSupplierLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SUPPLIER_SUCCESS,
					payload: {
						currentSupplier: res?.data?.supplier,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE SUPPLIER
	updateSupplier: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SUPPLIER,
			payload: {
				updateSupplierLoading: true,
			},
		});

		updateSupplier(data, id).then((res) => {
			dispatch({
				type: UPDATE_SUPPLIER,
				payload: {
					updateSupplierLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SUPPLIER_SUCCESS,
					payload: {
						suppliers: res?.data?.suppliers,
					},
				});
				history.push("/suppliers");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE SUPPLIER
	deleteSupplier: (id) => (dispatch) => {
		dispatch({
			type: DELETE_SUPPLIER,
			payload: {
				deleteSupplierLoading: true,
			},
		});
		deleteSupplier(id).then((res) => {
			dispatch({
				type: DELETE_SUPPLIER,
				payload: {
					deleteSupplierLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SUPPLIER_SUCCESS,
					payload: {
						suppliers: res?.data?.suppliers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
