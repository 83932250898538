import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

function FooterComponent() {
	return (
		<Footer style={{ textAlign: "center" }}>
			Gain-Intel ©{new Date().getFullYear()} Created by Gain-Intel
		</Footer>
	);
}

export default FooterComponent;
