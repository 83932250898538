import React, { useEffect } from "react";
import { Layout } from "antd";
import { Link, useHistory } from "react-router-dom";
import actions from "../redux/actions/Auth.actions";
import { connect } from "react-redux";
import { Dropdown, Menu, Space } from "antd";
import {
  PoweroffOutlined,
  CaretDownOutlined,
  ToolOutlined,
  BellOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ButtonControl from "../components/Buttons/ButtonControl";
import useWindowSize from "../utils/useWindowSize";
import download from "../assets/images/Download.svg"
import { useState } from "react";
const { Header } = Layout;

function HeaderComponent({ myDetails, getMe, LogoutUser,
  onSave, onCancel, onApply, updateHeaderHidden, onPrint, onDownload, onDelivery, onDeliveryPrintLoading,
  onDealClosed, onMobilePrint, userAccessPermissions, onPreview, width: screenWidth,
  fileName, accessToken, onDisplaySave, onPreviewPrint, onPrintLoading, white, hide = false }) {

  const [width] = useWindowSize();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [finalName, setFinalName] = useState("");
  const history = useHistory()

  useEffect(() => {
    if (onSave || onCancel || onDisplaySave || hide) {
      updateHeaderHidden(true)
    }
  }, [onSave, onCancel, onDisplaySave, hide])


  useEffect(() => {
    if (myDetails?.firstName) {
      setFirst(myDetails?.firstName?.toUpperCase()?.charAt(0));
    }
    else {
      setFirst("")
    }
    if (myDetails?.lastName) {
      setLast(myDetails?.lastName?.toUpperCase()?.charAt(0))
    }
    else {
      setLast("")
    }
  }, [myDetails])

  useEffect(() => {
    let name = "";
    if (width > 767) {
      if (myDetails?.firstName) {
        name = myDetails?.firstName
      }
    }
    else {
      name = first ? `${first}.` : "" + last ? `${last}.` : "";
    }
    setFinalName(name);

  }, [width, first, last])

  const menu = (
    <Menu>
      {userAccessPermissions.includes("getUsers") ||
        userAccessPermissions.includes("all") ? (
        <Menu.Item key={`menu-item-users`} icon={<UserOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {userAccessPermissions.includes("getRoles") ||
        userAccessPermissions.includes("all") ? (
        <Menu.Item key={`menu-item-user-roles`} icon={<ToolOutlined />}>
          <Link to="/user-roles">Roles</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      <Menu.Item
        key={`menu-item-logout`}
        icon={<PoweroffOutlined />}
        onClick={() => {
          LogoutUser();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className={`site-layout-background sticky-top-nav ${white ? "white" : null}`}
      style={{ padding: 0 }}
    >

      <div className="right-part">
        {
          onDealClosed &&
          <ButtonControl
            buttonType="isSecondary danger"
            value={"Deal Closed"}
            htmlType="button"
            isBold={false}
            buttonSize="large"
            handleClick={onDealClosed}
          />

        }
        {
          onPreview &&
          <ButtonControl
            buttonType="isSecondary"
            value={"Preview"}
            htmlType="button"
            isBold={false}
            buttonSize="large"
            handleClick={onPreview}
          />

        }
        {
          onPreviewPrint &&
          <ButtonControl
            buttonType="isSecondary"
            value={"Print"}
            htmlType="button"
            buttonSize="large"
            handleClick={() => {
              try {
                const pdfBlob = onPreviewPrint?.blob;
                const blobUrl = URL.createObjectURL(pdfBlob);
                window.open(blobUrl, "_blank");
              } catch (error) {
                console.error("Error opening PDF in new tab:", error);
              }
            }}
          />
        }
        {
          onDelivery &&
          <ButtonControl
            buttonType="isSecondary"
            value={"Print note"}
            htmlType="button"
            buttonSize="large"
            handleClick={onDelivery}
            isLoading={onDeliveryPrintLoading}
          />
        }
        {
          onPrint &&
          <ButtonControl
            buttonType="isSecondary"
            value={"Print"}
            htmlType="button"
            buttonSize="large"
            handleClick={onPrint}
            isLoading={onPrintLoading}
          />
        }


        {
          onDownload &&

          <ButtonControl
            buttonType="isSecondary"
            value={"Download"}
            htmlType="button"
            isBold={false}
            buttonSize="large"
            handleClick={() => {
              const link = document.createElement('a');
              link.href = onDownload;
              link.download = fileName;
              link.click();

            }}
          />
        }


        {
          onMobilePrint && !onMobilePrint?.disabled &&

          <img src={download}
            onClick={() => {
              try {
                const pdfBlob = onMobilePrint.blob;
                const blobUrl = URL.createObjectURL(pdfBlob);
                window.open(blobUrl, "_blank");
              } catch (error) {
                console.error("Error opening PDF in new tab:", error);
              }
            }} />

        }


        {
          (onSave || onApply) &&
          <ButtonControl
            buttonType="isPrimary"
            value={onSave ? "Save" : "Apply"}
            htmlType="submit"
            isLoading={onApply ? onApply?.isLoading : onSave ? onSave?.isLoading : false}
            isDisabled={
              onSave?.isDisabled || false
            }
            isBold={true}
            height="20px"
          />

        }
        {
          onDisplaySave &&
          <ButtonControl
            buttonType="isPrimary"
            value={"Save"}
            htmlType="button"
            isLoading={onDisplaySave?.isLoading}
            isDisabled={
              onDisplaySave?.isDisabled || false
            }
            isBold={true}
            handleClick={onDisplaySave?.submit}
            height="20px"
          />
        }
        {
          onCancel &&
          <ButtonControl
            cancel={true}
            buttonType="isSecondary"
            value={"Cancel"}
            htmlType="button"
            isBold={false}
            height="20px"
            handleClick={onCancel}
            isDisabled={false}
          />
        }

        <div id="notification-icon" >
          <BellOutlined />
        </div>
        <Dropdown overlay={menu}>
          <Space>
            <span id="logout">
              <UserOutlined id="user-icon" />
              <div style={{ display: "flex", flexDirection: "row", whiteSpace: "nowrap" }}>
                {finalName}
              </div>
              <CaretDownOutlined />
            </span>
          </Space>
        </Dropdown>
      </div>
    </Header >
  );
}

const mapStateToProps = (state) => ({
  userAccessPermissions: state.auth.accessPermissions,
  headerHidden: state.auth.headerHidden,
  myDetails: state.auth.myDetails,
  accessToken: state?.auth?.accessToken
});

const mapDispatchToProps = (dispatch) => ({
  LogoutUser: () => {
    dispatch(actions.LogoutUser());
  },
  updateHeaderHidden: (data) => {
    dispatch(actions.updateHeaderHidden(data));
  },
  getMe: () => {
    dispatch(actions?.getMe())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
