
export const SAVE_ALL_FILTERS = "SAVE_ALL_FILTERS";
const actions = {
	saveAllFilters: (page,value) => (dispatch) => {
		dispatch({
			type: SAVE_ALL_FILTERS,
			payload: {
				page,
                value
			}
		});

	},

};

export default actions;
