import {
	getAllCurrencies,
	getAllCurrenciesList,
    addCurrency,
    updateCurrency,
    deleteCurrency,
    getCurrency
} from "../../config/config";

import { toast } from "react-toastify";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";

export const GET_CURRENCIES_LIST = "GET_CURRENCIES_LIST";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";

export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_SUCCESS = "ADD_CURRENCY_SUCCESS";

export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";

export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";

export const UPLOAD_CURRENCY_LOGO = "UPLOAD_CURRENCY_LOGO";

const actions = {
	
	//GET CURRENCIES
	getCurrencies: () => (dispatch) => {
		dispatch({
			type: GET_CURRENCIES,
			payload: {
				getCurrenciesLoading: true
			},
		});
		getAllCurrencies().then((res) => {

			dispatch({
				type: GET_CURRENCIES,
				payload: {
					getCurrenciesLoading: false
				},
			});

			if (res?.status === 200) {
				
				dispatch({
					type: GET_CURRENCIES_SUCCESS,
					payload: {
						currencies: res?.data?.rates,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},


	//GET CURRENCIES LIST
	getCurrenciesList: () => (dispatch) => {
		
		getAllCurrenciesList().then((res) => {

			

			if (res?.status === 200) {
				
				dispatch({
					type: GET_CURRENCIES_LIST,
					payload: {
						currenciesList: res?.data?.rates,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				// window.location = "/forbidden";
				toast?.error("Currency list")
			}
		});
	},
	//GET CURRENCY
	getCurrency: (id) => (dispatch) => {
		dispatch({
			type: GET_CURRENCY,
			payload: {
				getCurrencyLoading: true,
			},
		});

		getCurrency(id).then((res) => {

			dispatch({
				type: GET_CURRENCY,
				payload: {
					getCurrencyLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_CURRENCY_SUCCESS,
					payload: {
						currentCurrency: res?.data?.rate,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//ADD CURRENCY
	addCurrency: (data) => (dispatch) => {
		dispatch({
			type: ADD_CURRENCY,
			payload: {
				addCurrencyLoading: true,
			},
		});

		addCurrency(data).then((res) => {
			dispatch({
				type: ADD_CURRENCY,
				payload: {
					addCurrencyLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_CURRENCY_SUCCESS,
					payload: {
						currencies: res?.data?.rates,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE CURRENCY
	updateCurrency: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_CURRENCY,
			payload: {
				updateCurrencyLoading: true,
			},
		});

		updateCurrency(data, id).then((res) => {
			dispatch({
				type: UPDATE_CURRENCY,
				payload: {
					updateCurrencyLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_CURRENCY_SUCCESS,
					payload: {
						currencies: res?.data?.rates,
					},
				});
				history.push("/currencies");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE CURRENCY
	deleteCurrency: (id) => (dispatch) => {
		dispatch({
			type: DELETE_CURRENCY,
			payload: {
				deleteCurrencyLoading: true,
			},
		});
		deleteCurrency(id).then((res) => {
			dispatch({
				type: DELETE_CURRENCY,
			payload: {
				deleteCurrencyLoading: false,
			},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_CURRENCY_SUCCESS,
					payload: {
						currencies: res?.data?.rates,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	uploadCurrencyLogo: (data) => (dispatch) => {
		dispatch(
			{
				type: UPLOAD_CURRENCY_LOGO,
				payload: {
					logoUrl: data,
				},
			}
		)
	},
};

export default actions;
