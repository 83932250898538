import {
	getTranslations,
	uploadTranslations,
	getGlobalConfig,
	addZone,
	getAllZones,
	getZone,
	updateZone,
	deleteZone,
	uploadImage,
	downloadTranslations,
	updateTranslations,
	getParameters,
	getParametersList,
	editParameters,

	updateAutomation,
	addAutomation,
	getAllAutomations,
	deleteAutomation,
	getAutomation


} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const GET_TRANSLATIONS = "GET_TRANSLATIONS";
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
export const UPDATE_TRANSLATIONS_SUCCESS = "UPDATE_TRANSLATIONS_SUCCESS";
export const UPDATE_TRANSLATIONS = "UPDATE_TRANSLATIONS";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const UPLOAD_TRANSLATIONS_ERROR = "UPLOAD_TRANSLATIONS_ERROR";
export const GET_GLOBAL_CONFIG_SUCCESS = "GET_GLOBAL_CONFIG_SUCCESS";
export const DOWNLOAD_TRANSLATIONS_SUCCESS = "DOWNLOAD_TRANSLATIONS_SUCCESS";

export const ADD_ZONE_SUCCESS = "ADD_ZONE_SUCCESS";
export const GET_ALL_ZONES_SUCCESS = "GET_ALL_ZONES_SUCCESS";
export const GET_ZONE_SUCCESS = "GET_ZONE_SUCCESS";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS";

export const ADD_ZONE = "ADD_ZONE";
export const GET_ALL_ZONES = "GET_ALL_ZONES";
export const GET_ZONE = "GET_ZONE";
export const UPDATE_ZONE = "UPDATE_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";

export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";

export const GET_PARAMETERS = "GET_PARAMETERS";
export const GET_PARAMETERS_LIST = "GET_PARAMETERS_LIST";
export const EDIT_PARAMETERS = "EDIT_PARAMETERS";

export const UPLOAD_FOOTER_LOGO = "UPLOAD_FOOTER_LOGO";
export const UPLOAD_HEADER_LOGO = "UPLOAD_HEADER_LOGO";

export const GET_PARAMETERS_SUCCESS = "GET_PARAMETERS_SUCCESS";
export const EDIT_PARAMETERS_SUCCESS = "EDIT_PARAMETERS_SUCCESS";

export const GET_AUTOMATIONS = "GET_AUTOMATIONS";
export const UPDATE_AUTOMATION = "UPDATE_AUTOMATION";
export const GET_AUTOMATION = "GET_AUTOMATION";
export const ADD_AUTOMATION = "ADD_AUTOMATION";
export const DELETE_AUTOMATION = "DELETE_AUTOMATION";

export const GET_AUTOMATIONS_SUCCESS = "GET_AUTOMATIONS_SUCCESS";
export const UPDATE_AUTOMATION_SUCCESS = "UPDATE_AUTOMATION_SUCCESS";
export const GET_AUTOMATION_SUCCESS = "GET_AUTOMATION_SUCCESS";
export const ADD_AUTOMATION_SUCCESS = "ADD_AUTOMATION_SUCCESS";
export const DELETE_AUTOMATION_SUCCESS = "DELETE_AUTOMATION_SUCCESS";

const actions = {
	uploadHeaderLogo:(data)=>(dispatch)=>{
		dispatch(
			{
				type: UPLOAD_HEADER_LOGO,
				payload: {
					headerLogoUrl: data,
				},
			}
		)
	},
	uploadFooterLogo:(data)=>(dispatch)=>{
		dispatch(
			{
				type: UPLOAD_FOOTER_LOGO,
				payload: {
					footerLogoUrl: data,
				},
			}
		)
	},


	uploadImage: (file) => async (dispatch) => {
		//console.log(file)
		var formData = new FormData();
		formData.append("image", file);
		dispatch({
			type: UPLOAD_IMAGE,
			payload: {
				logoLoading: true,
			},
		});
		uploadImage(formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}).then((res) => {
			dispatch({
				type: UPLOAD_IMAGE,
				payload: {
					logoLoading: false,
				},
			});
			if (res?.status === 200) {
				//history.push("/upload/custLogo");
				dispatch({
					type: UPLOAD_IMAGE_SUCCESS,
					payload: {
						logoUrl: res?.data?.fileName,
					},
				});

				// toast.success("Image uploaded", { theme: "colored" });

			} else if (res?.status === 401) {
				window.location = "/session-ended";

			} else if (res?.status === 403) {
				window.location = "/forbidden";

			} else {
				console.log(res?.data?.message || "error");
			}
		});
	},


	//GET TRANSLATIONS
	getTranslations: () => (dispatch) => {
		dispatch({
			type: GET_TRANSLATIONS,
			payload: {
				getLoading: true
			},
		});
		getTranslations().then((res) => {
			if (res?.status === 200) {
				// const index =res?.data?.translations?.findIndex((trans)=>trans.name==="resto-admin");

				dispatch({
					type: GET_TRANSLATIONS_SUCCESS,
					payload: {
						translations: res?.data?.translations,
						getLoading: false,
						languages: res?.data?.supportedLanguages,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	



	// UPLOAD TRANSLATIONS
	uploadTranslations: (data, history) => async (dispatch) => {
		var formData = new FormData();
		formData.append("file", data[0]);
		uploadTranslations(formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}).then((res) => {
			if (res?.status === 200) {

				history.push("/translations");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	// DOWNLOAD TRANSLATIONS
	downloadTranslations: () => async (dispatch) => {
		downloadTranslations().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: DOWNLOAD_TRANSLATIONS_SUCCESS,
					payload: {
						downloadedTranslations: res?.data?.exportedTranslationUrl,
					},
				});

				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	// UPDATE TRANSLATIONS
	updateTranslations: (data) => async (dispatch) => {
		dispatch({
			type: UPDATE_TRANSLATIONS,
			payload: {
				updateLoading: true,

			},
		});
		updateTranslations(data).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_TRANSLATIONS_SUCCESS,
					payload: {
						translations: res?.data?.translations,
						updateLoading: false,

					},
				});

				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET GLOBAL CONFIG
	getGlobalConfig: () => (dispatch) => {
		getGlobalConfig().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_GLOBAL_CONFIG_SUCCESS,
					payload: {
						countries: res?.data?.countries,
						currencies: res?.data?.currencies,
						modules:res?.data?.modules
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//ADD ZONE
	addZone: (data) => (dispatch) => {
		dispatch({
			type: ADD_ZONE,
			payload: {
				addZoneLoading: true,
			},
		});

		addZone(data).then((res) => {
			dispatch({
				type: ADD_ZONE,
				payload: {
					addZoneLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_ZONE_SUCCESS,
					payload: {
						zones: res?.data?.zones,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL ZONES
	getAllZones: () => (dispatch) => {
		dispatch({
			type: GET_ALL_ZONES,
			payload: {
				getZonesLoading: true,
			},
		});
		getAllZones().then((res) => {
			dispatch({
				type: GET_ALL_ZONES,
				payload: {
					getZonesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_ZONES_SUCCESS,
					payload: {
						zones: res?.data?.zones,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE ZONE
	getZone: (id) => (dispatch) => {
		dispatch({
			type: GET_ZONE,
			payload: {
				getZoneLoading: true,
			},
		});
		getZone(id).then((res) => {
			dispatch({
				type: GET_ZONE,
				payload: {
					getZoneLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ZONE_SUCCESS,
					payload: {
						currentZone: res?.data?.zone,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE ZONE
	updateZone: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_ZONE,
			payload: {
				updateZoneLoading: true,
			},
		});

		updateZone(data, id).then((res) => {
			dispatch({
				type: UPDATE_ZONE,
				payload: {
					updateZoneLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_ZONE_SUCCESS,
					payload: {
						zones: res?.data?.zones,
					},
				});
				history.push("/zones");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE ZONE
	deleteZone: (id) => (dispatch) => {
		dispatch({
			type: DELETE_ZONE,
			payload: {
				deleteZoneLoading: true,
			},
		});
		deleteZone(id).then((res) => {
			dispatch({
				type: DELETE_ZONE,
				payload: {
					deleteZoneLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_ZONE_SUCCESS,
					payload: {
						zones: res?.data?.zones,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
		//GET PARAMETERS
		getParameters: () => (dispatch) => {
			dispatch({
				type: GET_PARAMETERS,
				payload: {
					getParametersLoading: true,
				},
			});
			getParameters().then((res) => {
				dispatch({
					type: GET_PARAMETERS,
					payload: {
						getParametersLoading: false,
					},
				});
				if (res?.status === 200) {

					dispatch({
						type: GET_PARAMETERS_SUCCESS,
						payload: {
							parameters: res?.data?.parameters,
						},
					});

				} else if (res?.status === 401) {
					window.location = "/session-ended";
				} else if (res?.status === 403) {
					window.location = "/forbidden";
				}
			});
		},

		//GET PARAMETERS
		getParametersList: () => (dispatch) => {
			
			getParametersList().then((res) => {
				
				if (res?.status === 200) {

					dispatch({
						type: GET_PARAMETERS_LIST,
						payload: {
							parametersList: res?.data?.parameters,
						},
					});

				} else if (res?.status === 401) {
					window.location = "/session-ended";
				} 
				else if (res?.status === 403) {
					toast.error("parameters")
					// window.location = "/forbidden";
				}
			});
		},

		//EDIT PARAMETERS
		editParameters: (data) => (dispatch) => {
			dispatch({
				type: EDIT_PARAMETERS,
				payload: {
					editParametersLoading: true,
				},
			});
			editParameters(data).then((res) => {
				dispatch({
					type: EDIT_PARAMETERS,
					payload: {
						editParametersLoading: false,
					},
				});
				if (res?.status === 200) {

					dispatch({
						type: EDIT_PARAMETERS_SUCCESS,
						payload: {
							parameters: res?.data?.parameters,
						},
					});

				} else if (res?.status === 401) {
					window.location = "/session-ended";
				} else if (res?.status === 403) {
					window.location = "/forbidden";
				}
			});
		},



	//ADD AUTOMATION
	addAutomation: (data,history) => (dispatch) => {
		dispatch({
			type: ADD_AUTOMATION,
			payload: {
				addAutomationLoading: true,
			},
		});

		addAutomation(data).then((res) => {
			dispatch({
				type: ADD_AUTOMATION,
				payload: {
					addAutomationLoading: false,
				},
			});

			if (res?.status === 200) {
				history.push("/automations");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_AUTOMATION_SUCCESS,
					payload: {
						automations: res?.data?.automations,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL AUTOMATIONS
	getAllAutomations: () => (dispatch) => {
		dispatch({
			type: GET_AUTOMATIONS,
			payload: {
				getAutomationsLoading: true,
			},
		});
		getAllAutomations().then((res) => {
			dispatch({
				type: GET_AUTOMATIONS,
				payload: {
					getAutomationsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_AUTOMATIONS_SUCCESS,
					payload: {
						automations: res?.data?.automations,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE AUTOMATION
	getAutomation: (id) => (dispatch) => {
		dispatch({
			type: GET_AUTOMATION,
			payload: {
				getAutomationLoading: true,
			},
		});
		getAutomation(id).then((res) => {
			dispatch({
				type: GET_AUTOMATION,
				payload: {
					getAutomationLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_AUTOMATION_SUCCESS,
					payload: {
						currentAutomation: res?.data?.automation,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE AUTOMATION
	updateAutomation: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_AUTOMATION,
			payload: {
				updateAutomationLoading: true,
			},
		});

		updateAutomation(data, id).then((res) => {
			dispatch({
				type: UPDATE_AUTOMATION,
				payload: {
					updateAutomationLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_AUTOMATION_SUCCESS,
					payload: {
						automations: res?.data?.automations,
					},
				});
				history.push("/automations");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE AUTOMATION
	deleteAutomation: (id) => (dispatch) => {
		dispatch({
			type: DELETE_AUTOMATION,
			payload: {
				deleteAutomationLoading: true,
			},
		});
		deleteAutomation(id).then((res) => {
			dispatch({
				type: DELETE_AUTOMATION,
				payload: {
					deleteAutomationLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_AUTOMATION_SUCCESS,
					payload: {
						automations: res?.data?.automations,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	
};

export default actions;
