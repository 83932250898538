import {
	CHANGE_FIRST_DAY_OF_WEEK
} from "../actions/ServiceCallsCalendarView.action";

const initialState = {
	
	day: 0,
};

function branch(state = initialState, action) {
	switch (action.type) {
		
		case CHANGE_FIRST_DAY_OF_WEEK:
            return {
                ...state,
               day:action.payload.day
              };

		

		default:
			return state;
	}
}

export default branch;
