import React, { lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const Forbidden = lazy(() => import("../pages/error/Forbidden"));

const UserRoute = ({
	component: Component,
	accessPermission,
	accessToken,
	userAccessPermissions,
	...rest
}) => {
	try {
		return (
			<Route
				{...rest}
				render={(props) =>
					accessToken ? (
						accessPermission !== undefined &&
						(userAccessPermissions.includes(accessPermission) ||
							userAccessPermissions.includes("all") ||
							accessPermission === "dashboard" || accessPermission==='none') ? (
							<Component {...props} />
						) : (
							<Forbidden />
						)
					) : (
						<Redirect
							to={{ pathname: "/auth/login", state: { from: props.location } }}
						/>
					)
				}
			/>
		);
	} catch (err) {
		console.log(err);
	}
};

const mapStateToProps = (state) => ({
	accessToken: state.auth.accessToken,
	userAccessPermissions: state.auth.accessPermissions,
});
export default connect(mapStateToProps)(UserRoute);
