import {
    EMPTY_PAGE_ROUTES,
    SET_PAGE_ROUTES,
    GET_ALL_PAGES,
    GET_ALL_PAGES_SUCCESS,
    EDIT_PAGE,
    EDIT_PAGE_SUCCESS,
    EDIT_PAGES,
    EDIT_PAGES_SUCCESS,
    ADD_PAGE,
    ADD_PAGE_SUCCESS,
    DELETE_PAGE,
    DELETE_PAGE_SUCCESS,
    GET_ROUTES,
    GET_ROUTES_SUCCESS,
    EDIT_ROUTES,
    EDIT_ROUTES_SUCCESS,
    EDIT_SUBACCESS,
    EDIT_SUBACCESS_SUCCESS,
    GET_SUBACCESS,
    GET_SUBACCESS_SUCCESS,
    GET_PAGE_MODULES,
    GET_PAGE_MODULES_SUCCESS,
    EDIT_MODULE_PAGE,
    EDIT_MODULE_PAGE_SUCCESS,
    GET_ALL_PAGES_INSIDE
} from "../actions/Roles.action";

const initialState = {
    addPageLoading: false,
    editPageLoading: false,
    editPagesLoading:false,
    editModulePageLoading: false,
    deletePageLoading: false,
    getAllPagesLoading: false,
    getAllPagesInsideLoading: false,
    editRoutesLoading: false,
    editSubAccessLoading: false,
    getSubAccessLoading: false,
    getPageModules: false,
    getRoutesLoading: false,

    pages: [],
    pageModules: [],
    subAccess: [],
    routes: [],
    pageRoute: [],
    pageAdded: false,
};


const RESET_LOADING = "RESET_LOADING"
function branch(state = initialState, action) {
    switch (action.type) {



        case RESET_LOADING:
            return Object.assign({}, state, {
                addPageLoading: false,
                editPageLoading: false,
                editPagesLoading:false,
                deletePageLoading: false,
                getAllPagesLoading: false,
                getAllPagesInsideLoading: false,
                editRoutesLoading: false,
                editSubAccessLoading: false,
                getSubAccessLoading: false,
                getPageModules: false,
                getRoutesLoading: false,
                editModulePageLoading: false,
            })

        case EMPTY_PAGE_ROUTES:
            return Object.assign({}, state, {

                pageRoute: [],

            });

        case SET_PAGE_ROUTES:
            return Object.assign({}, state, {
                pageRoute: action?.payload?.pageRoute,

            });

        case GET_ROUTES_SUCCESS:
            return Object.assign({}, state, {
                routes: action.payload.routes
            });

        case GET_ROUTES:
            return Object.assign({}, state, {
                getRoutesLoading: action.payload.getRoutesLoading
            });

        case EDIT_ROUTES:
            return Object.assign({}, state, {
                editRoutesLoading: action.payload.editRoutesLoading
            });
        case EDIT_ROUTES_SUCCESS:
            return Object.assign({}, state, {
                routes: action.payload.routes
            });
        case ADD_PAGE:
            return Object.assign({}, state, {
                addPageLoading: action.payload.addPageLoading,
                pageAdded: action.payload.pageAdded
            });
        case ADD_PAGE_SUCCESS:
            return Object.assign({}, state, {
                pages: action.payload.pages,
                pageAdded: action.payload.pageAdded
            });

        case EDIT_PAGE:
            return Object.assign({}, state, {
                editPageLoading: action.payload.editPageLoading
            });
        case EDIT_PAGE_SUCCESS:
            return Object.assign({}, state, {
                pages: action.payload.pages
            });

        case EDIT_PAGES:
            return Object.assign({}, state, {
                editPagesLoading: action.payload.editPagesLoading
            });
        case EDIT_PAGES_SUCCESS:
            return Object.assign({}, state, {
                pages: action.payload.pages
            });

        case EDIT_MODULE_PAGE:
            return Object.assign({}, state, {
                editModulePageLoading: action.payload.editModulePageLoading
            });
        case EDIT_MODULE_PAGE_SUCCESS:
            return Object.assign({}, state, {
                pageModules: action.payload.pageModules
            });


        case DELETE_PAGE:
            return Object.assign({}, state, {
                deletePageLoading: action.payload.deletePageLoading
            });
        case DELETE_PAGE_SUCCESS:
            return Object.assign({}, state, {
                pages: action.payload.pages
            });

        case GET_ALL_PAGES:
            return Object.assign({}, state, {
                getAllPagesLoading: action.payload.getAllPagesLoading
            });
        case GET_ALL_PAGES_INSIDE:
            return Object.assign({}, state, {
                getAllPagesInsideLoading: action.payload.getAllPagesInsideLoading
            });
        case GET_ALL_PAGES_SUCCESS:
            return Object.assign({}, state, {
                pages: action.payload.pages
            });

        case GET_PAGE_MODULES:
            return Object.assign({}, state, {
                getPageModulesLoading: action.payload.getPageModulesLoading
            });
        case GET_PAGE_MODULES_SUCCESS:
            return Object.assign({}, state, {
                pageModules: action.payload.pageModules
            });

        case GET_SUBACCESS_SUCCESS:
            return Object.assign({}, state, {
                subAccess: action.payload.subAccess
            });

        case GET_SUBACCESS:
            return Object.assign({}, state, {
                getSubAccessLoading: action.payload.getSubAccessLoading
            });

        case EDIT_SUBACCESS_SUCCESS:
            return Object.assign({}, state, {
                subAccess: action.payload.subAccess
            });

        case EDIT_SUBACCESS:
            return Object.assign({}, state, {
                editSubAccessLoading: action.payload.editSubAccessLoading
            });

        default:
            return state;
    }
}

export default branch;
