import {
	DELETE_PAYMENT,
	DELETE_PAYMENT_SUCCESS,
	ADD_PAYMENT,
	ADD_PAYMENT_SUCCESS,
	UPDATE_PAYMENT,
	UPDATE_PAYMENT_SUCCESS,
	GET_PAYMENTS,
	GET_PAYMENTS_SUCCESS,
	GET_PAYMENT,
	GET_PAYMENT_SUCCESS,
	UPLOAD_PAYMENT_LOGO,
	GET_PAYMENTS_LIST

} from "../actions/Payment.action";

const RESET_LOADING = "RESET_LOADING"

const initialState = {
	addPaymentLoading: false,
	updatePaymentLoading: false,
	getPaymentLoading: false,
	getPaymentsLoading: false,
	deletePaymentLoading: false,

	payments: [],
	paymentsList: [],
	addedPayment: "",
	currentPayment: "",
	logoUrl: ""

};

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				addPaymentLoading: false,
				updatePaymentLoading: false,
				getPaymentLoading: false,
				getPaymentsLoading: false,
				deletePaymentLoading: false,

			})

		case ADD_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				payments: action.payload.payments,
				addedPayment: action.payload.addedPayment
			});
		case ADD_PAYMENT:
			return Object.assign({}, state, {
				addPaymentLoading: action.payload.addPaymentLoading,
			});

		case GET_PAYMENTS_SUCCESS:
			return Object.assign({}, state, {
				payments: action.payload.payments,
			});

		case GET_PAYMENTS:
			return Object.assign({}, state, {
				getPaymentsLoading: action.payload.getPaymentsLoading,

			});

		case GET_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				currentPayment: action.payload.currentPayment,
				logoUrl: action.payload?.currentPayment?.logo
			});
		case GET_PAYMENTS_LIST:
			return Object.assign({}, state, {
				paymentsList: action.payload.paymentsList
			});
		case UPLOAD_PAYMENT_LOGO:
			return Object.assign({}, state, {
				logoUrl: action.payload.logoUrl
			});

		case GET_PAYMENT:
			return Object.assign({}, state, {
				getPaymentLoading: action.payload.getPaymentLoading,
			});

		case UPDATE_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				payments: action.payload.payments,
			});
		case UPDATE_PAYMENT:
			return Object.assign({}, state, {
				editPaymentLoading: action.payload.editPaymentLoading,
			});

		case DELETE_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				payments: action.payload.payments,
			});
		case DELETE_PAYMENT:
			return Object.assign({}, state, {
				deletePaymentLoading: action.payload.deletePaymentLoading,
			});

		default:
			return state;
	}
}

export default branch;
