import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	MY_ACCESS_PERMISSIONS_SUCCESS,
	LOGOUT_USER,
	UPDATE_HEADER_HIDDEN,
	GET_ME_SUCCESS,
	UPDATE_NAV_HIDDEN,
	SET_OPEN_KEYS,
	SET_PREV_URL
} from "../actions/Auth.actions";

const initialState = {
	openKeys: [],
	allowedBranches:[],
	branchExceptions:[],
	accessToken: "",
	userName: "",
	accessPermissions: [],
	headerHidden: false,
	myDetails: {},
	navHidden: false,
	previousUrl:null
};

function branch(state = initialState, action) {
	switch (action.type) {
		case UPDATE_HEADER_HIDDEN:
			return Object.assign({}, state, {
				headerHidden: action.payload.headerHidden,
			});
		case UPDATE_NAV_HIDDEN:
			return Object.assign({}, state, {
				navHidden: action.payload.navHidden,
			});
		case SET_OPEN_KEYS:
			return Object.assign({}, state, {
				openKeys: action.payload.openKeys,
			});
		case GET_ME_SUCCESS:
			return Object.assign({}, state, {
				myDetails: action.payload.myDetails,
			});
		case SET_PREV_URL:
			return Object.assign({}, state, {
				previousUrl: action.payload.previousUrl,
			});
		case LOGIN_REQUEST:
			return Object.assign({}, state, {
				accessToken: "",
				userName: "",
				accessPermissions: [],
				branchExceptions:[],
				allowedBranches:[]
			});

		case LOGIN_SUCCESS:
			return Object.assign({}, state, {
				accessToken: action.payload.accessToken,
				accessPermissions: action.payload.accessPermissions,
				userName: action.payload.userName,
				branchExceptions:action.payload.branchExceptions,
				allowedBranches:action.payload.allowedBranches
			});

		case MY_ACCESS_PERMISSIONS_SUCCESS:
			return Object.assign({}, state, {
				accessPermissions: action.payload.accessPermissions,
				userName: action.payload.userName,
			});

		case LOGOUT_USER:
			return Object.assign({}, state, {
				accessToken: "",
				userName: "",
				accessPermissions: [],
				allowedBranches:[],
				branchExceptions:[]
			});

		default:
			return state;
	}
}

export default branch;
