
import { equalArrays } from "../../utils/equalArrays";
import {
  ADD_PRODUCT_TO_CART, DELETE_PRODUCT_FROM_CART, ADD_QUOTATION_SUCCESS, ADD_QUOTATION, EDIT_QUOTATION_SUCCESS, EDIT_QUOTATION,
  EMPTY_PRODUCT_CART, CHANGE_PAGE, STORE_QUOTATION, ADD_INVOICE_DISC, GET_ALL_QUOTATIONS
  , DELETE_QUOTATION_SUCCESS, GET_QUOTATION_SUCCESS, STORE_PRODUCTS, EMPTY_QUOTATION, CALCULATE_TAX, GET_QUOTATION,
  CHANGE_PRODUCT_LOADER, DELETE_QUOTATION, SET_VIEW, SET_SELECTED_REASON,
  ADD_QUOTATION_STATUS_SUCCESS,
  ADD_QUOTATION_STATUS,
  DELETE_QUOTATION_STATUS,
  GET_ALL_QUOTATION_STATUS_SUCCESS,
  GET_ALL_QUOTATION_STATUS,
  GET_QUOTATION_STATUS_SUCCESS,
  GET_QUOTATION_STATUS,
  UPDATE_QUOTATION_STATUS_SUCCESS,
  UPDATE_QUOTATION_STATUS,
  DELETE_QUOTATION_STATUS_SUCCESS,
  GET_ALL_QUOTATION_STATUS_LIST,
  GET_ALL_QUOTATION_LOST_REASON,
  GET_ALL_QUOTATION_LOST_REASON_SUCCESS,
  GET_ALL_QUOTATION_LOST_REASON_LIST,
  GET_QUOTATION_LOST_REASON,
  GET_QUOTATION_LOST_REASON_SUCCESS,
  DELETE_QUOTATION_LOST_REASON,
  DELETE_QUOTATION_LOST_REASON_SUCCESS,
  ADD_QUOTATION_LOST_REASON,
  ADD_QUOTATION_LOST_REASON_SUCCESS,
  UPDATE_QUOTATION_LOST_REASON,
  UPDATE_QUOTATION_LOST_REASON_SUCCESS,
  GET_ALL_QUOTATIONS_FILTER,
  GET_ALL_QUOTATIONS_FILTER_SUCCESS,
  SET_QUOTATION_FILTERS,
  GET_QUOTATION_USERS
} from "../actions/Quotations.action";

const initialState = {

  addQuotationStatusLoading: false,
  getQuotationStatusesLoading: false,
  getQuotationStatusLoading: false,
  updateQuotationStatusLoading: false,
  deleteQuotationStatusLoading: false,

  addQuotationLostReasonLoading: false,
  getQuotationLostReasonsLoading: false,
  getQuotationLostReasonLoading: false,
  updateQuotationLostReasonLoading: false,
  deleteQuotationLostReasonLoading: false,

  quotationStatus: [],
  quotationStatusList: [],
  currentQuotationStatus: "",
  currentReason: "",
  addedStatus: "",
  addedReason: "",

  salesmen: [],
  users: [],
  selectedReason: null,
  reasons: [],
  reasonsList: [],
  products: [],
  quotations: {},
  subTotal: 0,
  discount: 0,
  cost: 0,
  productLoader: false,
  addQuotationLoading: false,
  getQuotationLoading: false,
  getQuotationsLoading: false,
  getQuotationsFilterLoading: false,
  editQuotationLoading: false,
  deleteQuotationLoading: false,
  quotationData: {},
  tax: [],
  page: 1,
  invoiceDisc: { percentageDiscount: 0, amountDiscount: 0 },
  currentQuotation: {},
  flag: false,
  view: true,
  max: 0,
  min: 0,
  sum: 0


};
let taxes = [];
const RESET_LOADING = "RESET_LOADING"
function branch(state = initialState, action) {
  switch (action.type) {
    case RESET_LOADING:
      return Object.assign({}, state, {

        addQuotationStatusLoading: false,
        getQuotationStatusesLoading: false,
        getQuotationStatusLoading: false,
        updateQuotationStatusLoading: false,
        deleteQuotationStatusLoading: false,

        addQuotationLostReasonLoading: false,
        getQuotationLostReasonsLoading: false,
        getQuotationLostReasonLoading: false,
        updateQuotationLostReasonLoading: false,
        deleteQuotationLostReasonLoading: false,

        productLoader: false,
        addQuotationLoading: false,
        getQuotationLoading: false,
        getQuotationsLoading: false,
        getQuotationsFilterLoading: false,
        editQuotationLoading: false,
        deleteQuotationLoading: false,


      })
    case SET_VIEW:
      return Object.assign({}, state, {
        view: action.payload.view,
      });
    case SET_QUOTATION_FILTERS:
      return Object.assign({}, state, {
        filters: action.payload.filters,
      });
    case SET_SELECTED_REASON:
      return Object.assign({}, state, {
        selectedReason: action.payload.selectedReason,
      });
    case GET_QUOTATION_USERS:
      return Object.assign({}, state, {
        users: action.payload.users,
        salesmen: action.payload.salesmen
      });
    case ADD_PRODUCT_TO_CART:
      const productIndex = state.products.findIndex((product) => product.id === action.payload.product.id);
      let match = false;
      let newTotalQty=action.payload.product?.qty+action.payload.product?.linkedCustomers?.reduce((acc,obj)=>acc+obj.qty,0);
        
      taxes = [...state.tax]
      //edit
      if (productIndex !== -1) {
        const updatedProducts = [...state.products];
        if (
          action.payload.product.price === updatedProducts[productIndex]?.price &&
          action.payload.product.discount === updatedProducts[productIndex]?.discount &&
          action.payload.product.qty === updatedProducts[productIndex]?.qty &&
          action.payload.product.remark === updatedProducts[productIndex]?.remark &&
          action.payload.product.validity === updatedProducts[productIndex]?.validity &&
          equalArrays(action.payload.product.linkedCustomers, updatedProducts[productIndex]?.linkedCustomers)
        ) {
          match = true
        }

        let oldTotalQty=updatedProducts[productIndex]?.qty+updatedProducts[productIndex]?.linkedCustomers?.reduce((acc,obj)=>acc+obj.qty,0);
       
        if (!match) {
          state.subTotal -= parseFloat(oldTotalQty * updatedProducts[productIndex]?.price)
          state.cost -= parseFloat(oldTotalQty* updatedProducts[productIndex]?.unitCost * ((updatedProducts[productIndex]?.validity || 12)/12))
          state.discount -= parseFloat(updatedProducts[productIndex]?.discount)

          updatedProducts[productIndex]?.newTaxes.forEach((obj) => {
            const { taxUuid, taxAmount } = obj;
            const index = taxes.findIndex(t => t.taxUuid === taxUuid)
            if (index != -1) {
              taxes[index].taxAmount -= taxAmount;
            }
            else {
              taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
            }
          });

          updatedProducts[productIndex] = {
            ...action.payload.product
          };

          state.subTotal += parseFloat(newTotalQty* action.payload.product?.price)
          state.cost += parseFloat(newTotalQty * action.payload.product?.unitCost * ((action.payload.poduct?.validity || 12)/12))
          state.discount += parseFloat(action.payload.product?.discount);

          action.payload.product?.newTaxes.forEach((obj) => {
            const { taxUuid, taxAmount } = obj;
            const index = taxes.findIndex(t => t.taxUuid === taxUuid)
            if (index !== -1) {
              taxes[index].taxAmount += taxAmount;
            }
            else {
              taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
            }
          });
          state.tax = [...taxes];

          return {
            ...state,
            products: updatedProducts
          };
        }
        else {
          return {
            ...state
          };
        }

      }
      //add
      else {

        state.subTotal += parseFloat(newTotalQty * action.payload.product?.price)
        state.cost += parseFloat(newTotalQty * action.payload.product?.unitCost * ((action.payload.product?.validity || 12)/12))
        state.discount += parseFloat(action.payload.product?.discount);

        action.payload.product?.newTaxes.forEach((obj) => {
          const { taxUuid, taxAmount } = obj;
          const index = taxes.findIndex(t => t.taxUuid === taxUuid)
          if (index != -1) {
            taxes[index].taxAmount += taxAmount;
          }
          else {
            taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
          }
        });

        state.tax = [...taxes];

        return {
          ...state,
          products: [...state.products, action.payload.product]
        };
      }



    case DELETE_PRODUCT_FROM_CART:

      taxes = [...state.tax];
      const oldProducts = [...state.products];
      const index = oldProducts.findIndex((prod) => prod.id === action.payload.id);
      const totalQty=oldProducts[index].qty+oldProducts[index].linkedCustomers.reduce((acc,obj)=>acc+obj.qty,0);
      
      state.subTotal -= parseFloat(totalQty * oldProducts[index]?.price)
      state.cost -= parseFloat(totalQty * oldProducts[index]?.unitCost*((oldProducts[index]?.validity || 12)/12))
      state.discount -= parseFloat(oldProducts[index]?.discount);

      oldProducts[index]?.newTaxes.forEach((obj) => {
        const { taxUuid, taxAmount } = obj;
        const index = taxes.findIndex(t => t.taxUuid === taxUuid)
        if (index !== -1) {
          taxes[index].taxAmount -= taxAmount;
        }
      });
      const updatedProducts = state.products.filter((product) => product.id !== action.payload.id);

      if (updatedProducts?.length === 0) {
        state.invoiceDisc = { percentageDiscount: 0, amountDiscount: 0 }
      }
      return {
        ...state,
        products: updatedProducts,
      };
    case EMPTY_PRODUCT_CART:
      return Object.assign({}, state, {
        subTotal: 0,
        products: [],
        cost: 0,
        tax: [],
        discount: 0,
        quotationData: {},
        invoiceDisc: { percentageDiscount: 0, amountDiscount: 0 }
      });

    case ADD_QUOTATION_SUCCESS:
      return Object.assign({}, state, {
        quotations: { data: action.payload.quotations },
      });
    case ADD_QUOTATION:
      return Object.assign({}, state, {
        addQuotationLoading: action.payload.addQuotationLoading
      });
    case EDIT_QUOTATION_SUCCESS:

      return Object.assign({}, state, {
        currentQuotation: action.payload.editedQuotation
      });
    case EDIT_QUOTATION:
      return Object.assign({}, state, {
        editQuotationLoading: action.payload.editQuotationLoading
      });
    case GET_QUOTATION_SUCCESS:
      return Object.assign({}, state, {
        currentQuotation: action.payload.quotation,
      });
    case GET_QUOTATION:
      return Object.assign({}, state, {
        getQuotationLoading: action.payload.getQuotationLoading
      });

    case DELETE_QUOTATION_SUCCESS:
      return Object.assign({}, state, {
        quotations: action.payload.quotations,
      });
    case DELETE_QUOTATION:
      return Object.assign({}, state, {
        deleteQuotationLoading: action.payload.deleteQuotationLoading,
      });
    case GET_ALL_QUOTATIONS:
      return Object.assign({}, state, {
        getQuotationsLoading: action.payload.getQuotationsLoading,
      });

    case GET_ALL_QUOTATIONS_FILTER_SUCCESS:
      let oldQuotations = [];

      if (action.payload.page === 1 || !action.payload.view)
        oldQuotations = [];

      else {
        oldQuotations = [...state?.quotations?.newQuotations];
      }


      return Object.assign({}, state, {
        quotations: {
          newQuotations: action.payload.quotations,
          oldQuotations: [...oldQuotations],
          totalRecords: action.payload.totalRecords,
          totalPages: action.payload.totalPages,
          page: action.payload.page,
          sum: action.payload.sum,
          min: action.payload.min,
          max: action.payload.max
        }
      });
    case GET_ALL_QUOTATIONS_FILTER:
      return Object.assign({}, state, {
        getQuotationsFilterLoading: action.payload.getQuotationsFilterLoading,
      });
    case CALCULATE_TAX:
      taxes = []
      action.payload?.data?.forEach((product) => {

        product?.newTaxes.forEach((obj) => {
          const { taxUuid, taxAmount } = obj;
          const index = taxes.findIndex(t => t.taxUuid === taxUuid)
          if (index !== -1) {
            taxes[index].taxAmount += taxAmount;
          }
          else {
            taxes.push({ taxUuid: taxUuid, taxAmount: taxAmount })
          }
        });

      })

      return Object.assign({}, state, {
        tax: taxes,
      });

    case ADD_INVOICE_DISC:
      return Object.assign({}, state, {
        invoiceDisc: action.payload.invoiceDisc,
      });
    case STORE_QUOTATION:
      return Object.assign({}, state, {
        quotationData: action.payload.data,
      });
    case CHANGE_PRODUCT_LOADER:
      return Object.assign({}, state, {
        productLoader: action.payload.productLoader,
      });
    case EMPTY_QUOTATION:
      return Object.assign({}, state, {
        quotationData: {},
      });
    case STORE_PRODUCTS:
      taxes = [];
      action.payload?.data?.quotationDetails?.forEach((obj) => {
        const { quotationDetailsTaxes } = obj;
        quotationDetailsTaxes?.forEach((t) => {
          const { amount, tax } = t;
          const index = taxes.findIndex(o => o.taxUuid == tax.uuid)

          if (index != -1) {
            taxes[index].taxAmount += amount;
          }
          else {
            taxes.push({ taxUuid: tax.uuid, taxAmount: amount })
          }


        })


      });
      return Object.assign({}, state, {
        products: action.payload.data.quotationDetails,
        subTotal: action.payload.data.subtotal,
        discount: action.payload.data.discount,
        cost: action?.payload?.data?.cost,
        tax: taxes,

      });

    case CHANGE_PAGE:
      return Object.assign({}, state, {
        page: action.payload.page,
        flag: action.payload.flag
      });

    case ADD_QUOTATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        quotationStatus: action.payload.quotationStatus,
        addedStatus: action.payload.addedStatus

      });
    case ADD_QUOTATION_STATUS:
      return Object.assign({}, state, {
        addQuotationStatusLoading: action.payload.addQuotationStatusLoading,
      });

    case GET_ALL_QUOTATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        quotationStatus: action.payload.quotationStatus,
        currentQuotationStatus: "",
      });

    case GET_ALL_QUOTATION_STATUS_LIST:
      return Object.assign({}, state, {
        quotationStatusList: action.payload.quotationStatusList,
        currentQuotationStatus: "",
      });
    case GET_ALL_QUOTATION_STATUS:
      return Object.assign({}, state, {
        getQuotationStatusesLoading: action.payload.getQuotationStatusesLoading,

      });

    case GET_QUOTATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        currentQuotationStatus: action.payload.currentQuotationStatus,
      });
    case GET_QUOTATION_STATUS:
      return Object.assign({}, state, {
        geQuotationStatusLoading: action.payload.getQuotationStatusLoading,
      });


    case UPDATE_QUOTATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        quotationStatus: action.payload.quotationStatus,
      });

    case UPDATE_QUOTATION_STATUS:
      return Object.assign({}, state, {
        updateQuotationStatusLoading: action.payload.updateQuotationStatusLoading,
      });

    case DELETE_QUOTATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        quotationStatus: action.payload.quotationStatus,
      });
    case DELETE_QUOTATION_STATUS:
      return Object.assign({}, state, {
        deleteQuotationStatusLoading: action.payload.deleteQuotationStatusLoading,
      });

    case ADD_QUOTATION_LOST_REASON_SUCCESS:
      return Object.assign({}, state, {
        reasons: action.payload.reasons,
        addedReason: action.payload.addedReason

      });
    case ADD_QUOTATION_LOST_REASON:
      return Object.assign({}, state, {
        addQuotationLostReasonLoading: action.payload.addQuotationLostReasonLoading,
      });

    case GET_ALL_QUOTATION_LOST_REASON_SUCCESS:
      return Object.assign({}, state, {
        reasons: action.payload.reasons,
      });

    case GET_ALL_QUOTATION_LOST_REASON_LIST:
      return Object.assign({}, state, {
        reasonsList: action.payload.reasonsList,
      });
    case GET_ALL_QUOTATION_LOST_REASON:
      return Object.assign({}, state, {
        getQuotationLostReasonsLoading: action.payload.getQuotationLostReasonsLoading,

      });

    case GET_QUOTATION_LOST_REASON_SUCCESS:
      return Object.assign({}, state, {
        currentReason: action.payload.currentReason,
      });
    case GET_QUOTATION_LOST_REASON:
      return Object.assign({}, state, {
        geQuotationLostReasonLoading: action.payload.getQuotationLostReasonLoading,
      });


    case UPDATE_QUOTATION_LOST_REASON_SUCCESS:
      return Object.assign({}, state, {
        reasons: action.payload.reasons,
      });

    case UPDATE_QUOTATION_LOST_REASON:
      return Object.assign({}, state, {
        updateQuotationLostReasonLoading: action.payload.updateQuotationLostReasonLoading,
      });

    case DELETE_QUOTATION_LOST_REASON_SUCCESS:
      return Object.assign({}, state, {
        reasons: action.payload.reasons,
      });
    case DELETE_QUOTATION_LOST_REASON:
      return Object.assign({}, state, {
        deleteQuotationLostReasonLoading: action.payload.deleteQuotationLostReasonLoading,
      });

    default:
      return state;
  }

}

export default branch;
