import {
	addLeadSource,
	getAllLeadSource,
	getSalesLeads,
	getLeadSource,
	updateLeadSource,
	deleteLeadSource,
	addLeadProcess,
	getAllLeadProcess,
	getLeadProcess,
	updateLeadProcess,
	deleteLeadProcess,
	addSaleRegion,
	getAllSaleRegions,
	getSaleRegion,
	updateSaleRegion,
	deleteSaleRegion,
	addLead,
	getLinkedLeads,
	getAllLeads,
	getAllUnlinkedLeads,
	getAllLeadsList,
	getLead,
	updateLead,
	deleteLead,
	getLeadQuotations,

	getAllLeadProcessList,
	getAllLeadSourceList,
	getAllSaleRegionsList
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const ADD_LEAD_SOURCE_SUCCESS = "ADD_LEAD_SOURCE_SUCCESS";
export const GET_ALL_LEAD_SOURCE_SUCCESS = "GET_ALL_LEAD_SOURCE_SUCCESS";
export const GET_LEAD_SOURCE_SUCCESS = "GET_LEAD_SOURCE_SUCCESS";
export const UPDATE_LEAD_SOURCE_SUCCESS = "UPDATE_LEAD_SOURCE_SUCCESS";
export const DELETE_LEAD_SOURCE_SUCCESS = "DELETE_LEAD_SOURCE_SUCCESS";

export const ADD_LEAD_SOURCE = "ADD_LEAD_SOURCE";
export const GET_ALL_LEAD_SOURCE = "GET_ALL_LEAD_SOURCE";
export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE";
export const UPDATE_LEAD_SOURCE = "UPDATE_LEAD_SOURCE";
export const DELETE_LEAD_SOURCE = "DELETE_LEAD_SOURCE";

export const GET_SALES_LEADS = "GET_SALES_LEADS"
export const GET_SALES_LEADS_SUCCESS = "GET_SALES_LEADS_SUCCESS"

export const ADD_LEAD_PROCESS_SUCCESS = "ADD_LEAD_PROCESS_SUCCESS";
export const GET_ALL_LEAD_PROCESS_SUCCESS = "GET_ALL_LEAD_PROCESS_SUCCESS";
export const GET_LEAD_PROCESS_SUCCESS = "GET_LEAD_PROCESS_SUCCESS";
export const UPDATE_LEAD_PROCESS_SUCCESS = "UPDATE_LEAD_PROCESS_SUCCESS";
export const DELETE_LEAD_PROCESS_SUCCESS = "DELETE_LEAD_PROCESS_SUCCESS";

export const ADD_LEAD_PROCESS = "ADD_LEAD_PROCESS";
export const GET_ALL_LEAD_PROCESS = "GET_ALL_LEAD_PROCESS";
export const GET_LEAD_PROCESS = "GET_LEAD_PROCESS";
export const UPDATE_LEAD_PROCESS = "UPDATE_LEAD_PROCESS";
export const DELETE_LEAD_PROCESS = "DELETE_LEAD_PROCESS";

export const ADD_SALE_REGION_SUCCESS = "ADD_SALE_REGION_SUCCESS";
export const GET_ALL_SALE_REGIONS_SUCCESS = "GET_ALL_SALE_REGIONS_SUCCESS";
export const GET_SALE_REGION_SUCCESS = "GET_SALE_REGION_SUCCESS";
export const UPDATE_SALE_REGION_SUCCESS = "UPDATE_SALE_REGION_SUCCESS";
export const DELETE_SALE_REGION_SUCCESS = "DELETE_SALE_REGION_SUCCESS";

export const ADD_SALE_REGION = "ADD_SALE_REGION";
export const GET_ALL_SALE_REGIONS = "GET_ALL_SALE_REGIONS";
export const GET_SALE_REGION = "GET_SALE_REGION";
export const UPDATE_SALE_REGION = "UPDATE_SALE_REGION";
export const DELETE_SALE_REGION = "DELETE_SALE_REGION";

export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const GET_ALL_LEADS_SUCCESS = "GET_ALL_LEADS_SUCCESS";
export const GET_ALL_UNLINKED_LEADS_SUCCESS = "GET_ALL_UNLINKED_LEADS_SUCCESS";
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";

export const ADD_LEAD = "ADD_LEAD";
export const GET_ALL_LEADS = "GET_ALL_LEADS";
export const GET_ALL_UNLINKED_LEADS = "GET_ALL_UNLINKED_LEADS";
export const GET_LEAD = "GET_LEAD";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";

export const GET_LEAD_QUOTATIONS = "GET_LEAD_QUOTATIONS";
export const GET_LEAD_QUOTATIONS_SUCCESS = "GET_LEAD_QUOTATIONS_SUCCESS";

export const GET_LINKED_LEADS="GET_LINKED_LEADS"
export const GET_LINKED_LEADS_SUCCESS="GET_LINKED_LEADS_SUCCESS"

export const GET_ALL_LEAD_SOURCE_LIST = "GET_ALL_LEAD_SOURCE_LIST";
export const GET_ALL_LEADS_LIST = "GET_ALL_LEADS_LIST";
export const GET_ALL_LEAD_STATUS_LIST = "GET_ALL_LEAD_STATUS_LIST";
export const GET_ALL_LEAD_PROCESS_LIST = "GET_ALL_LEAD_PROCESS_LIST";
export const GET_ALL_SALE_REGIONS_LIST = "GET_ALL_SALE_REGIONS_LIST";

export const  GET_LINKED_LEADS_CUSTOMERS_SUCCESS="GET_LINKED_LEADS_CUSTOMERS_SUCCES";

const actions = {

	//GET LINKED LEADS
	getLinkedLeads: (id) => (dispatch) => {
		dispatch({
			type: GET_LINKED_LEADS,
			payload: {
				getLinkedLeadsLoading: true,
			},
		});
		getLinkedLeads(id).then((res) => {
			dispatch({
				type: GET_LINKED_LEADS,
				payload: {
					getLinkedLeadsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type:  GET_LINKED_LEADS_SUCCESS,
					payload: {
						linkedLeads: res?.data?.linkedCustomers,
						
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//ADD LEAD SOURCE
	addLeadSource: (data) => (dispatch) => {
		dispatch({
			type: ADD_LEAD_SOURCE,
			payload: {
				addLeadSourceLoading: true,
			},
		});

		addLeadSource(data).then((res) => {
			dispatch({
				type: ADD_LEAD_SOURCE,
				payload: {
					addLeadSourceLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_LEAD_SOURCE_SUCCESS,
					payload: {
						leadSource: res?.data?.sources,
						addedSource: res?.data?.addedSource
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL LEAD SOURCE
	getAllLeadSource: () => (dispatch) => {
		dispatch({
			type: GET_ALL_LEAD_SOURCE,
			payload: {
				getLeadSourcesLoading: true,
			},
		});
		getAllLeadSource().then((res) => {
			dispatch({
				type: GET_ALL_LEAD_SOURCE,
				payload: {
					getLeadSourcesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEAD_SOURCE_SUCCESS,
					payload: {
						leadSource: res?.data?.sources,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL LEAD SOURCE LIST
	getAllLeadSourceList: () => (dispatch) => {
		getAllLeadSourceList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEAD_SOURCE_LIST,
					payload: {
						leadSourceList: res?.data?.sources,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("lead source")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET SINGLE LEAD SOURCE
	getLeadSource: (id) => (dispatch) => {
		dispatch({
			type: GET_LEAD_SOURCE,
			payload: {
				getLeadSourceLoading: true,
			},
		});
		getLeadSource(id).then((res) => {
			dispatch({
				type: GET_LEAD_SOURCE,
				payload: {
					getLeadSourceLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LEAD_SOURCE_SUCCESS,
					payload: {
						currentLeadSource: res?.data?.source,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE LEAD SOURCE
	updateLeadSource: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_LEAD_SOURCE,
			payload: {
				updateLeadSourceLoading: true,
			},
		});

		updateLeadSource(data, id).then((res) => {
			dispatch({
				type: UPDATE_LEAD_SOURCE,
				payload: {
					updateLeadSourceLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_LEAD_SOURCE_SUCCESS,
					payload: {
						leadSource: res?.data?.sources,

					},
				});
				history.push("/lead-source");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE LEAD SOURCE
	deleteLeadSource: (id) => (dispatch) => {
		dispatch({
			type: DELETE_LEAD_SOURCE,
			payload: {
				deleteLeadSourceLoading: true,
			},
		});

		deleteLeadSource(id).then((res) => {
			dispatch({
				type: DELETE_LEAD_SOURCE,
				payload: {
					deleteLeadSourceLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_LEAD_SOURCE_SUCCESS,
					payload: {
						leadSource: res?.data?.sources,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//ADD LEAD PROCESS
	addLeadProcess: (data) => (dispatch) => {
		dispatch({
			type: ADD_LEAD_PROCESS,
			payload: {
				addLeadProcessLoading: true,
			},
		});
		addLeadProcess(data).then((res) => {
			dispatch({
				type: ADD_LEAD_PROCESS,
				payload: {
					addLeadProcessLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_LEAD_PROCESS_SUCCESS,
					payload: {
						leadProcess: res?.data?.processes,
						addedProcess: res?.data?.addedProcess
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL LEAD PROCESS
	getAllLeadProcess: () => (dispatch) => {
		dispatch({
			type: GET_ALL_LEAD_PROCESS,
			payload: {
				getLeadProcessesLoading: true,
			},
		});
		getAllLeadProcess().then((res) => {
			dispatch({
				type: GET_ALL_LEAD_PROCESS,
				payload: {
					getLeadProcessesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEAD_PROCESS_SUCCESS,
					payload: {
						leadProcess: res?.data?.processes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL LEAD PROCESS
	getAllLeadProcessList: () => (dispatch) => {
		getAllLeadProcessList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEAD_PROCESS_LIST,
					payload: {
						leadProcessList: res?.data?.processes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("lead process")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE LEAD PROCESS
	getLeadProcess: (id) => (dispatch) => {
		dispatch({
			type: GET_LEAD_PROCESS,
			payload: {
				getLeadProcessLoading: true,
			},
		});
		getLeadProcess(id).then((res) => {
			dispatch({
				type: GET_LEAD_PROCESS,
				payload: {
					getLeadProcessLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LEAD_PROCESS_SUCCESS,
					payload: {
						currentLeadProcess: res?.data?.process,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE LEAD PROCESS
	updateLeadProcess: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_LEAD_PROCESS,
			payload: {
				updateLeadProcessLoading: true,
			},
		});

		updateLeadProcess(data, id).then((res) => {
			dispatch({
				type: UPDATE_LEAD_PROCESS,
				payload: {
					updateLeadProcessLoading: false,
				},
			});


			if (res?.status === 200) {
				dispatch({
					type: UPDATE_LEAD_PROCESS_SUCCESS,
					payload: {
						leadProcess: res?.data?.processes,
					},
				});
				history.push("/lead-process");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE LEAD PROCESS
	deleteLeadProcess: (id) => (dispatch) => {
		dispatch({
			type: DELETE_LEAD_PROCESS,
			payload: {
				deleteLeadProcessLoading: true,
			},
		});
		deleteLeadProcess(id).then((res) => {
			dispatch({
				type: DELETE_LEAD_PROCESS,
				payload: {
					deleteLeadProcessLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_LEAD_PROCESS_SUCCESS,
					payload: {
						leadProcess: res?.data?.processes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD SALE REGION
	addSaleRegion: (data) => (dispatch) => {
		dispatch({
			type: ADD_SALE_REGION,
			payload: {
				addSaleRegionLoading: true,
			},
		});

		addSaleRegion(data).then((res) => {
			dispatch({
				type: ADD_SALE_REGION,
				payload: {
					addSaleRegionLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_SALE_REGION_SUCCESS,
					payload: {
						saleRegions: res?.data?.regions,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL SALE REGIONS
	getAllSaleRegions: () => (dispatch) => {
		dispatch({
			type: GET_ALL_SALE_REGIONS,
			payload: {
				getSalesRegionLoading: true,
			},
		});
		getAllSaleRegions().then((res) => {
			dispatch({
				type: GET_ALL_SALE_REGIONS,
				payload: {
					getSalesRegionLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALE_REGIONS_SUCCESS,
					payload: {
						saleRegions: res?.data?.regions,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL SALE REGIONS
	getAllSaleRegionsList: () => (dispatch) => {
		getAllSaleRegionsList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALE_REGIONS_LIST,
					payload: {
						saleRegionsList: res?.data?.regions,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("sales region")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE SALE REGION
	getSaleRegion: (id) => (dispatch) => {
		dispatch({
			type: GET_SALE_REGION,
			payload: {
				getSaleRegionLoading: true,
			},
		});
		getSaleRegion(id).then((res) => {
			dispatch({
				type: GET_SALE_REGION,
				payload: {
					getSaleRegionLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALE_REGION_SUCCESS,
					payload: {
						currentSaleRegion: res?.data?.region,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE SALE REGION
	updateSaleRegion: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_SALE_REGION,
			payload: {
				updateSaleRegionLoading: true,
			},
		});

		updateSaleRegion(data, id).then((res) => {
			dispatch({
				type: UPDATE_SALE_REGION,
				payload: {
					updateSaleRegionLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALE_REGION_SUCCESS,
					payload: {
						saleRegions: res?.data?.regions,
					},
				});
				history.push("/sale-regions");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE SALE REGION
	deleteSaleRegion: (id) => (dispatch) => {
		dispatch({
			type: DELETE_SALE_REGION,
			payload: {
				deleteSaleRegionLoading: true,
			},
		});
		deleteSaleRegion(id).then((res) => {
			dispatch({
				type: DELETE_SALE_REGION,
				payload: {
					deleteSaleRegionLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SALE_REGION_SUCCESS,
					payload: {
						saleRegions: res?.data?.regions,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD LEAD
	addLead: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_LEAD,
			payload: {
				addLeadLoading: true,
			},
		});

		addLead(data).then((res) => {
			dispatch({
				type: ADD_LEAD,
				payload: {
					addLeadLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_LEAD_SUCCESS,
					payload: {
						leads: res?.data?.leads,
						addedLead: res?.data?.addedLead
					},
				});
				history?.push("/leads");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL LEADS
	getAllLeads: () => (dispatch) => {
		dispatch({
			type: GET_ALL_LEADS,
			payload: {
				getLeadsLoading: true,
			},
		});
		getAllLeads().then((res) => {
			dispatch({
				type: GET_ALL_LEADS,
				payload: {
					getLeadsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEADS_SUCCESS,
					payload: {
						leads: res?.data?.leads,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL UNLINKED LEADS
	getAllUnlinkedLeads: () => (dispatch) => {
		dispatch({
			type: GET_ALL_UNLINKED_LEADS,
			payload: {
				getUnlinkedLeadsLoading: true,
			},
		});
		getAllUnlinkedLeads().then((res) => {
			dispatch({
				type: GET_ALL_UNLINKED_LEADS,
				payload: {
					getUnlinkedLeadsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_UNLINKED_LEADS_SUCCESS,
					payload: {
						unlinkedLeads: res?.data?.leads,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL SALES LEADS
	getSalesLeads: () => (dispatch) => {
		dispatch({
			type: GET_SALES_LEADS,
			payload: {
				getSalesLeadsLoading: true,
			},
		});
		getSalesLeads().then((res) => {
			dispatch({
				type: GET_SALES_LEADS,
				payload: {
					getSalesLeadsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_SALES_LEADS_SUCCESS,
					payload: {
						leads: res?.data?.leads,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL LEADS LIST
	getAllLeadsList: () => (dispatch) => {

		getAllLeadsList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_LEADS_LIST,
					payload: {
						leadsList: res?.data?.leads,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("leads")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET SINGLE LEAD
	getLead: (id) => (dispatch) => {
		dispatch({
			type: GET_LEAD,
			payload: {
				getLeadLoading: true,
			},
		});
		getLead(id).then((res) => {
			dispatch({
				type: GET_LEAD,
				payload: {
					getLeadLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LEAD_SUCCESS,
					payload: {
						currentLead: res?.data?.lead,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET LEAD QUOTATION
	getLeadQuotations: (id) => (dispatch) => {
		dispatch({
			type: GET_LEAD_QUOTATIONS,
			payload: {
				getLeadQuotationsLoading: true,
			},
		});
		getLeadQuotations(id).then((res) => {
			dispatch({
				type: GET_LEAD_QUOTATIONS,
				payload: {
					getLeadQuotationsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LEAD_QUOTATIONS_SUCCESS,
					payload: {
						leadQuotations: res?.data?.quotations,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("lead quotations")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//UPDATE LEAD
	updateLead: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_LEAD,
			payload: {
				updateLeadLoading: true,
			},
		});

		updateLead(data, id).then((res) => {
			dispatch({
				type: UPDATE_LEAD,
				payload: {
					updateLeadLoading: false,
				},
			});

			if (res?.status === 200) {
				console.log(res?.data)
				dispatch({
					type: UPDATE_LEAD_SUCCESS,
					payload: {
						leads: res?.data?.leads,
						updatedLead: res?.data?.edittedLead
					},
				});
				history?.push("/leads");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE LEAD
	deleteLead: (id) => (dispatch) => {
		dispatch({
			type: DELETE_LEAD,
			payload: {
				deleteLeadLoading: true,
			},
		});
		deleteLead(id).then((res) => {
			dispatch({
				type: DELETE_LEAD,
				payload: {
					deleteLeadLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_LEAD_SUCCESS,
					payload: {
						leads: res?.data?.leads,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
