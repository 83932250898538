import reportWebVitals from "./reportWebVitals";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { configurePersistor, configureStore } from "./redux/store/store";
import { createRoot } from 'react-dom/client';
import App from "./App";
import packageJson from '../package.json';
import localforage from 'localforage';

//CSS
import "antd/dist/reset.css";
import "./assets/styles/index.scss";
import dayjs from "dayjs";


dayjs.Ls.en.weekStart = 1;

// Redux setup
const store = configureStore();
const persistor = configurePersistor(store);
const wrapper = document.getElementById("root");
const root = createRoot(wrapper);

root.render(<Provider store={store}>
  <PersistGate loading={<div />} persistor={persistor}>
    <Router>
      <App />
    </Router>
  </PersistGate>
</Provider>);

function checkForNewVersionAndClearCache() {

  const currentVersion = localStorage.getItem('appVersion');
  const newVersion = packageJson.version;

  if (currentVersion !== newVersion) {

    localStorage.clear();
    localforage.clear();

    setTimeout(() => {
      window.location.reload();
    }, 0);

    localStorage.setItem('appVersion', newVersion);
  }
}

checkForNewVersionAndClearCache();

window.onload = () => {
  checkForNewVersionAndClearCache();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
