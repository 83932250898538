import {
    getAllPages,editPage,deletePage,addPage,getPageModules,editPages,
     getRoutes,editRoutes, getSubAccess, editSubAccess,editModulePage
} from "../../config/config";

import { toast } from "react-toastify";

export const EMPTY_PAGE_ROUTES = "EMPTY_PAGE_ROUTES";
export const SET_PAGE_ROUTES = "SET_PAGE_ROUTES";

export const GET_ALL_PAGES_INSIDE = "GET_ALL_PAGES_INSIDE"
export const GET_ALL_PAGES = "GET_ALL_PAGES"
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS"

export const GET_PAGE_MODULES = "GET_PAGE_MODULES"
export const GET_PAGE_MODULES_SUCCESS = "GET_PAGE_MODULES_SUCCESS"

export const EDIT_PAGE = "EDIT_PAGE"
export const EDIT_PAGE_SUCCESS = "EDIT_PAGE_SUCCESS"
export const EDIT_PAGES = "EDIT_PAGES"
export const EDIT_PAGES_SUCCESS = "EDIT_PAGES_SUCCESS"

export const EDIT_MODULE_PAGE = "EDIT_MODULE_PAGE"
export const EDIT_MODULE_PAGE_SUCCESS = "EDIT_MODULE_PAGE_SUCCESS"

export const ADD_PAGE = "ADD_PAGE"
export const ADD_PAGE_SUCCESS = "ADD_PAGE_SUCCESS"

export const DELETE_PAGE = "DELETE_PAGE"
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS"

export const GET_ROUTES = "GET_ROUTES"
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS"

export const EDIT_ROUTES = "EDIT_ROUTES"
export const EDIT_ROUTES_SUCCESS = "EDIT_ROUTES_SUCCESS"

export const GET_SUBACCESS = "GET_SUBACCESS"
export const GET_SUBACCESS_SUCCESS = "GET_SUBACCESS_SUCCESS"

export const EDIT_SUBACCESS = "EDIT_SUBACCESS"
export const EDIT_SUBACCESS_SUCCESS = "EDIT_SUBACCESS_SUCCESS";

const actions = {
	
	emptyPageRoutes: () => (dispatch) => {

		dispatch({type:EMPTY_PAGE_ROUTES});
		
	},
	setPageRoutes: (data) => (dispatch) => {

		dispatch({type:SET_PAGE_ROUTES,payload:{pageRoute:data}});
		
	},

    
    //GET SUBACCESS
    getSubAccess: () => (dispatch) => {
        dispatch({
            type: GET_SUBACCESS,
            payload: {
                getSubAccessLoading: true,
            },
        });
        getSubAccess().then((res) => {
            dispatch({
                type: GET_SUBACCESS,
                payload: {
                    getSubAccessLoading: false,
                },
            });
            if (res?.status === 200) {
               
                dispatch({
                    type: GET_SUBACCESS_SUCCESS,
                    payload: {
                        subAccess: res?.data?.rolesAcceptedRoutes,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //EDIT SUBACCESS
    editSubAccess: (data, id) => (dispatch) => {
        dispatch({
            type: EDIT_SUBACCESS,
            payload: {
                editSubAccessLoading: true,
            },
        });

        editSubAccess(data, id).then((res) => {
            dispatch({
                type: EDIT_SUBACCESS,
                payload: {
                    editSubAccessLoading: false,
                },
            });

            if (res?.status === 200) {
                dispatch({
                    type: EDIT_SUBACCESS_SUCCESS,
                    payload: {
                        subAccess: res?.data?.subAccess,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },


    //GET ROUTES
    getRoutes: () => (dispatch) => {
        dispatch({
            type: GET_ROUTES,
            payload: {
                getRoutesLoading: true,
            },
        });
        getRoutes().then((res) => {
            dispatch({
                type: GET_ROUTES,
                payload: {
                    getRoutesLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_ROUTES_SUCCESS,
                    payload: {
                        routes: res?.data?.rolesAcceptedRoutes,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                toast.error("routes")
                // window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //EDIT ROUTES
    editRoutes: (data, id) => (dispatch) => {
        dispatch({
            type: EDIT_ROUTES,
            payload: {
                editRoutesLoading: true,
            },
        });

        editRoutes(data, id).then((res) => {
            dispatch({
                type: EDIT_ROUTES,
                payload: {
                    editRoutesLoading: false,
                },
            });


            if (res?.status === 200) {
                dispatch({
                    type: EDIT_ROUTES_SUCCESS,
                    payload: {
                        routes: res?.data?.rolesAcceptedRoutes,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //FETCH ALL PAGES
    getAllPages: () => (dispatch) => {
        dispatch({
            type: GET_ALL_PAGES,
            payload: {
                getAllPagesLoading: true,
            },
        });
        getAllPages().then((res) => {
            dispatch({
                type: GET_ALL_PAGES,
                payload: {
                    getAllPagesLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_ALL_PAGES_SUCCESS,
                    payload: {
                        pages: res?.data?.pages,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    
    //FETCH ALL PAGES INSIDE
    getAllPagesInside: () => (dispatch) => {
        dispatch({
            type: GET_ALL_PAGES_INSIDE,
            payload: {
                getAllPagesInsideLoading: true,
            },
        });
        getAllPages().then((res) => {
            dispatch({
                type: GET_ALL_PAGES_INSIDE,
                payload: {
                    getAllPagesInsideLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_ALL_PAGES_SUCCESS,
                    payload: {
                        pages: res?.data?.pages,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //FETCH PAGE MODULES
    getPageModules: () => (dispatch) => {
        dispatch({
            type: GET_PAGE_MODULES,
            payload: {
                getPageModulesLoading: true,
            },
        });
        getPageModules().then((res) => {
            dispatch({
                type: GET_PAGE_MODULES,
                payload: {
                    getPageModulesLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_PAGE_MODULES_SUCCESS,
                    payload: {
                        pageModules: res?.data?.moduleHierarchy,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //ADD PAGE
    addPage: (data) => (dispatch) => {
        dispatch({
            type: ADD_PAGE,
            payload: {
                addPageLoading: true,
            },
        });

        addPage(data).then((res) => {
            dispatch({
                type: ADD_PAGE,
                payload: {
                    addPageLoading: false,
                    pageAdded:false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: ADD_PAGE_SUCCESS,
                    payload: {
                        pages: res?.data?.pages,
                        pageAdded:true,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

     //EDIT PAGES
     editPages: (data) => (dispatch) => {
        dispatch({
            type: EDIT_PAGES,
            payload: {
                editPagesLoading: true,
            },
        });

        editPages(data).then((res) => {
            dispatch({
                type: EDIT_PAGES,
                payload: {
                    editPagesLoading: false,
                },
            });


            if (res?.status === 200) {
                dispatch({
                    type: EDIT_PAGES_SUCCESS,
                    payload: {
                       pages: res?.data?.pages,
                       routes: res?.data?.rolesAcceptedRoutes,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //EDIT PAGE
    editPage: (data, id) => (dispatch) => {
        dispatch({
            type: EDIT_PAGE,
            payload: {
                editPageLoading: true,
            },
        });

        editPage(data, id).then((res) => {
            dispatch({
                type: EDIT_PAGE,
                payload: {
                    editPageLoading: false,
                },
            });


            if (res?.status === 200) {
                dispatch({
                    type: EDIT_PAGE_SUCCESS,
                    payload: {
                       pages: res?.data?.pages,
                       routes: res?.data?.rolesAcceptedRoutes,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

     //EDIT PAGE
     editModulePage: (data, id) => (dispatch) => {
        dispatch({
            type: EDIT_MODULE_PAGE,
            payload: {
                editModulePageLoading: true,
            },
        });

        editModulePage(data, id).then((res) => {
            dispatch({
                type: EDIT_MODULE_PAGE,
                payload: {
                    editModulePageLoading: false,
                },
            });


            if (res?.status === 200) {
                dispatch({
                    type: EDIT_MODULE_PAGE_SUCCESS,
                    payload: {
                       pageModules:res?.data?.moduleHierarchy
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },

    //DELETE  PAGE
    deletePage: (id) => (dispatch) => {
        dispatch({
            type: DELETE_PAGE,
            payload: {
                deletePageLoading: true,
            },
        });

        deletePage(id).then((res) => {
            dispatch({
                type: DELETE_PAGE,
                payload: {
                    deletePageLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: DELETE_PAGE_SUCCESS,
                    payload: {
                        pages: res?.data?.pages,
                    },
                });
                toast.success(res?.data?.message || "Success", { theme: "colored" });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        });
    },




};

export default actions;
