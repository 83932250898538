import {
	addWarehouse,
	getAllWarehouses,
	getWarehouse,
	updateWarehouse,
	deleteWarehouse,
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const ADD_WAREHOUSE_SUCCESS = "ADD_WAREHOUSE_SUCCESS";
export const GET_ALL_WAREHOUSES_SUCCESS = "GET_ALL_WAREHOUSES_SUCCESS";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";

const actions = {
	//ADD WAREHOUSE
	addWarehouse: (data, history) => (dispatch) => {
		dispatch({
			type: API_REQUEST,
			payload: {
				isLoading: true,
			},
		});

		addWarehouse(data).then((res) => {
			dispatch({
				type: API_REQUEST,
				payload: {
					isLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_WAREHOUSE_SUCCESS,
					payload: {
						warehouses: res?.data?.warehouses,
					},
				});
				history.push("/warehouses");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL WAREHOUSES
	getAllWarehouses: () => (dispatch) => {
		getAllWarehouses().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_WAREHOUSES_SUCCESS,
					payload: {
						warehouses: res?.data?.warehouses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE WAREHOUSE
	getWarehouse: (id) => (dispatch) => {
		getWarehouse(id).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_WAREHOUSE_SUCCESS,
					payload: {
						currentWarehouse: res?.data?.warehouse,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE WAREHOUSE
	updateWarehouse: (data, id, history) => (dispatch) => {
		dispatch({
			type: API_REQUEST,
			payload: {
				isLoading: true,
			},
		});

		updateWarehouse(data, id).then((res) => {
			dispatch({
				type: API_REQUEST,
				payload: {
					isLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_WAREHOUSE_SUCCESS,
					payload: {
						warehouses: res?.data?.warehouses,
					},
				});
				history.push("/warehouses");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE WAREHOUSE
	deleteWarehouse: (id) => (dispatch) => {
		deleteWarehouse(id).then((res) => {
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_WAREHOUSE_SUCCESS,
					payload: {
						warehouses: res?.data?.warehouses,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
