import {
	addInvoice,
	getAllInvoices,
	deleteInvoice,
	getInvoice,
	editInvoice,
	getAllInvoicesFilter,
	getInvoiceParameters,
	getInvoiceParametersList,
	editInvoiceParameters,
	getInvoiceUsers
} from "../../config/config";

import { toast } from "react-toastify";
export const API_REQUEST = "API_REQUEST";

export const UPLOAD_INVOICE_FOOTER_LOGO = "UPLOAD_INVOICE_FOOTER_LOGO";
export const UPLOAD_INVOICE_HEADER_LOGO = "UPLOAD_INVOICE_HEADER_LOGO";

export const SET_AS_DELIVERED= "SET_AS_DELIVERED";

export const ADD_INVOICE_PRODUCT_TO_CART = "ADD_INVOICE_PRODUCT_TO_CART";
export const DELETE_INVOICE_PRODUCT_FROM_CART = "DELETE_INVOICE_PRODUCT_FROM_CART"
export const EMPTY_INVOICE_PRODUCT_CART = "EMPTY_INVOICE_PRODUCT_CART"
export const CHANGE_INVOICE_PAGE = "CHANGE_INVOICE_PAGE"
export const SET_INVOICE_VIEW = "SET_INVOICE_VIEW"
export const ADD_INVOICE_DISC = "ADD_INVOICE_DISC"
export const GET_ALL_INVOICES_SUCCESS = "GET_ALL_INVOICES_SUCCESS"
export const GET_ALL_INVOICES = "GET_ALL_INVOICES"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE = "GET_INVOICE"
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS"
export const ADD_INVOICE = "ADD_INVOICE"
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS"
export const EDIT_INVOICE = "EDIT_INVOICE"
export const STORE_INVOICE = "STORE_INVOICE"
export const EMPTY_INVOICE = "EMPTY_INVOICE"
export const STORE_INVOICE_PRODUCTS = "STORE_INVOICE_PRODUCTS"
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
export const DELETE_INVOICE = "DELETE_INVOICE"
export const SET_INVOICE_RANGE_VALUES = "SET_INVOICE_RANGE_VALUES"
export const CALCULATE_INVOICE_TAX = "CALCULATE_INVOICE_TAX"
export const CHANGE_INVOICE_PRODUCT_LOADER = "CHANGE_INVOICE_PRODUCT_LOADER"

export const GET_ALL_INVOICES_FILTER_SUCCESS = "GET_ALL_INVOICES_FILTER_SUCCESS"
export const GET_ALL_INVOICES_FILTER = "GET_ALL_INVOICES_FILTER"

export const GET_INVOICE_PARAMETERS = "GET_INVOICE_PARAMETERS";
export const GET_INVOICE_PARAMETERS_LIST = "GET_INVOICE_PARAMETERS_LIST";
export const EDIT_INVOICE_PARAMETERS = "EDIT_INVOICE_PARAMETERS";

export const GET_INVOICE_PARAMETERS_SUCCESS = "GET_INVOICE_PARAMETERS_SUCCESS";
export const EDIT_INVOICE_PARAMETERS_SUCCESS = "EDIT_INVOICE_PARAMETERS_SUCCESS";

export const SET_ALL_DELIVERED = "SET_ALL_DELIVERED";
export const GET_INVOICE_USERS="GET_INVOICE_USERS";

const getInvoices = (page, limit, filters, loading, setPage, setFailedPage, view, dispatch) => {
	if (loading)
		dispatch({
			type: GET_ALL_INVOICES_FILTER,
			payload: {
				getInvoicesFilterLoading: true
			},
		});
	else
		dispatch({
			type: GET_ALL_INVOICES,
			payload: {
				getInvoicesLoading: true
			},
		});
	getAllInvoices(page, limit, filters).then((res) => {

		if (loading)
			dispatch({
				type: GET_ALL_INVOICES_FILTER,
				payload: {
					getInvoicesFilterLoading: false
				},
			});
		else
			dispatch({
				type: GET_ALL_INVOICES,
				payload: {
					getInvoicesLoading: false
				},
			});
		if (res?.status === 200) {

			if (typeof setPage === 'function' && view) {
				setPage(page + 1);
			}
			if (typeof setPage === 'function' && !view) {
				setPage();
			}
			dispatch({
				type: "SET_PAGE_DETAILS",
				payload: {
					page: "invoices",
					currentPage: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords
				},
			});
			dispatch({
				type: GET_ALL_INVOICES_FILTER_SUCCESS,
				payload: {
					invoices: res?.data?.sales,
					page: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords,
					view,
					max: res?.data?.maxNetValue,
					min: res?.data?.minNetValue,
					sum: res?.data?.totalSumValue
				},
			});
		} else if (res?.status === 401) {
			window.location = "/session-ended";
		} else if (res?.status === 403) {
			window.location = "/forbidden";
		} else {
			toast.error(res?.data?.message || "error", { theme: "colored" });
		}
	}).catch(() => {
		if (typeof setFailedPage === 'function') {
			setFailedPage(page)
		}
	});
}
const actions = {

	//GET INVOICE USERS
	getInvoiceUsers: () => (dispatch) => {
		getInvoiceUsers().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_INVOICE_USERS,
					payload: {
						salesmen: res?.data?.salesmen,
						users:res?.data?.users
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	uploadHeaderLogo:(data)=>(dispatch)=>{
		dispatch(
			{
				type: UPLOAD_INVOICE_HEADER_LOGO,
				payload: {
					headerLogoUrl: data,
				},
			}
		)
	},
	uploadFooterLogo:(data)=>(dispatch)=>{
		dispatch(
			{
				type: UPLOAD_INVOICE_FOOTER_LOGO,
				payload: {
					footerLogoUrl: data,
				},
			}
		)
	},

	setView: (data) => (dispatch) => {
		dispatch({ type: SET_INVOICE_VIEW, payload: { view: data } })
	},

	//ADD PRODUCT TO CART
	addProductToCart: (data) => (dispatch) => {
		dispatch({ type: ADD_INVOICE_PRODUCT_TO_CART, payload: { product: data } })
	},

	//SET AS DELIVERED
	setAsDelivered: (data) => (dispatch) => {
		dispatch({ type: SET_AS_DELIVERED, payload: { product: data } })
	},

	//SET ALL DELIVERED
	setAllDelivered: (data) => (dispatch) => {
		dispatch({ type: SET_ALL_DELIVERED, payload: { product: data } })
	},

	changeProductLoader: (data) => (dispatch) => {
		dispatch({ type: CHANGE_INVOICE_PRODUCT_LOADER, payload: { productLoader: data } })
	},
	changePage: (data, flag = false) => (dispatch) => {
		dispatch({ type: CHANGE_INVOICE_PAGE, payload: { page: data, flag: flag } })
	},

	//DELETE PRODUCT FORM CART
	deleteProductFromCart: (data) => (dispatch) => {
		dispatch({ type: DELETE_INVOICE_PRODUCT_FROM_CART, payload: { id: data } })
	},

	//EMPTY PRODUCT CART
	emptyProductCart: () => (dispatch) => {
		dispatch({ type: EMPTY_INVOICE_PRODUCT_CART })
	},

	calculateTax: (data) => (dispatch) => {
		dispatch({ type: CALCULATE_INVOICE_TAX, payload: { data: data } })
	},

	addInvoiceDisc: (data) => (dispatch) => {
		dispatch({ type: ADD_INVOICE_DISC, payload: { invoiceDisc: data } })
	},

	setRangeValues: (data) => (dispatch) => {
		dispatch({ type: SET_INVOICE_RANGE_VALUES, payload: { rangeValues: data } })
	},

	//STORE INVOICE
	storeInvoice: (data) => (dispatch) => {
		dispatch({ type: STORE_INVOICE, payload: { data: data } })
	},

	//EMPTY INVOICE
	emptyInvoice: (data) => (dispatch) => {
		dispatch({ type: EMPTY_INVOICE })
	},

	//STORE PRODUCTS
	storeProducts: (data) => (dispatch) => {
		dispatch({ type: STORE_INVOICE_PRODUCTS, payload: { data: data } })
	},

	//GET INVOICE
	getInvoice: (id) => (dispatch) => {
		dispatch({
			type: GET_INVOICE,
			payload: {
				getInvoiceLoading: true,
			},
		});

		getInvoice(id).then((res) => {
			dispatch({
				type: GET_INVOICE,
				payload: {
					getInvoiceLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_INVOICE_SUCCESS,
					payload: {
						invoice: res?.data?.sale,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL INVOICES
	getAllInvoices: (page, limit, filters, loading, setPage, setFailedPage = null, view) => (dispatch) => {

		getInvoices(page, limit, filters, loading, setPage, setFailedPage, view, dispatch)
	},
	//FETCH ALL INVOICES
	getAllInvoicesFilter: (filter) => (dispatch) => {

		dispatch({
			type: GET_ALL_INVOICES_FILTER,
			payload: {
				getInvoicesFilterLoading: true
			},
		});

		getAllInvoicesFilter(filter).then((res) => {
			dispatch({
				type: GET_ALL_INVOICES_FILTER,
				payload: {
					getInvoicesFilterLoading: false
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_INVOICES_FILTER_SUCCESS,
					payload: {
						invoices: res?.data?.sales,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//ADD INVOICE
	addInvoice: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_INVOICE,
			payload: {
				addInvoiceLoading: true,
			},
		});


		addInvoice(data).then((res) => {
			dispatch({
				type: ADD_INVOICE,
				payload: {
					addInvoiceLoading: false,
				},
			});

			if (res?.status === 200) {
				

				toast.success(res?.data?.message || "Success", { theme: "colored" });
				history.push(`edit-invoice/${res?.data?.addedSale?.uuid}`)
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		})
	},
	//EDIT INVOICE
	editInvoice: (data, id, history) => (dispatch) => {
		dispatch({
			type: EDIT_INVOICE,
			payload: {
				editInvoiceLoading: true,
			},
		});

		editInvoice(data, id).then((res) => {
			dispatch({
				type: EDIT_INVOICE,
				payload: {
					editInvoiceLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: EDIT_INVOICE_SUCCESS,
					payload: {
						editedInvoice: res?.data?.editedSale
					},
				});

				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//DELETE INVOICE
	deleteInvoice: (id, page, limit, filters, loading, setPage, setFailedPage, view, deleteInv) => (dispatch) => {
		dispatch({
			type: DELETE_INVOICE,
			payload: {
				deleteQuotationLoading: true,
			},
		});
		deleteInvoice(id).then((res) => {
			dispatch({
				type: DELETE_INVOICE,
				payload: {
					deleteInvoiceLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				if (view) {
					deleteInv(id)
				}
				else {
					getInvoices(page, limit, filters, loading, setPage, setFailedPage, view, dispatch)
				}

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//GET PARAMETERS
	getParameters: () => (dispatch) => {
		dispatch({
			type: GET_INVOICE_PARAMETERS,
			payload: {
				getParametersLoading: true,
			},
		});
		getInvoiceParameters().then((res) => {
			dispatch({
				type: GET_INVOICE_PARAMETERS,
				payload: {
					getParametersLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_INVOICE_PARAMETERS_SUCCESS,
					payload: {
						parameters: res?.data?.parameters,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//GET PARAMETERS
	getParametersList: () => (dispatch) => {

		getInvoiceParametersList().then((res) => {

			if (res?.status === 200) {

				dispatch({
					type: GET_INVOICE_PARAMETERS_LIST,
					payload: {
						parametersList: res?.data?.parameters,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			}
			else if (res?.status === 403) {
				toast.error("parameters")
				// window.location = "/forbidden";
			}
		});
	},

	//EDIT PARAMETERS
	editParameters: (data) => (dispatch) => {
		dispatch({
			type: EDIT_INVOICE_PARAMETERS,
			payload: {
				editParametersLoading: true,
			},
		});
		editInvoiceParameters(data).then((res) => {
			dispatch({
				type: EDIT_INVOICE_PARAMETERS,
				payload: {
					editParametersLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: EDIT_INVOICE_PARAMETERS_SUCCESS,
					payload: {
						parameters: res?.data?.parameters,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},


};

export default actions;
