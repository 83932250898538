import {
	addSale,
	getAllSales,
	getAllSalesList,
	updateSale,
	deleteSale
} from "../../config/config";

import { toast } from "react-toastify";

export const ADD_SALE_SUCCESS = "ADD_SALE_SUCCESS";
export const GET_ALL_SALES_SUCCESS = "GET_ALL_SALES_SUCCESS";
export const UPDATE_SALE_SUCCESS = "UPDATE_SALE_SUCCESS";
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS";


export const ADD_SALE = "ADD_SALE";
export const GET_ALL_SALES = "GET_ALL_SALES";
export const UPDATE_SALE= "UPDATE_SALE";

export const GET_ALL_SALES_LIST = "GET_ALL_SALES_LIST";

const actions = {
	//ADD SALE
	addSale: (data, history) => (dispatch) => {
		addSale(data).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: ADD_SALE_SUCCESS,
					payload: {
						sales: res?.data?.sales,
					},
				});
				history.push("/sales");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	

	//FETCH ALL SALES
	getAllSales: () => (dispatch) => {
		getAllSales().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_SUCCESS,
					payload: {
						sales: res?.data?.sales,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//FETCH ALL SALES LIST
	getAllSalesList: () => (dispatch) => {
		getAllSalesList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_SALES_LIST,
					payload: {
						salesList: res?.data?.sales,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("sales")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//UPDATE SALES
	updateSale: (data, id, history) => (dispatch) => {
		updateSale(data, id).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: UPDATE_SALE_SUCCESS,
					payload: {
						sales: res?.data?.sales,
					},
				});
				history.push("/sales");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE SALE
	deleteSale: (id) => (dispatch) => {
		deleteSale(id).then((res) => {
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_SALE_SUCCESS,
					payload: {
						sales: res?.data?.sales,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
