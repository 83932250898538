import { isEditable } from "@testing-library/user-event/dist/utils";
import {
    SAVE_FILTER_DATA_SOURCE,
    SET_CURRENT_PAGES,
    SET_SEARCH_VALUE,
    SAVE_SORT_VALUE,
    SET_FILTER_CHANGED,
    SET_IS_EDITING
} from "../actions/Table.actions";

const initialState = {
    currentPages: [],
    searchValue: null,
    filterDataSource: [],
    sortValue: "",
    filterChanged: [],
    isEditing: [],
    pageDetails:[]
};

function branch(state = initialState, action) {
    switch (action.type) {

        case SET_CURRENT_PAGES:

            let copy = [...state?.currentPages];
            let index = copy?.findIndex(page => page?.key === action.payload.key);

            if (index != -1 && copy?.length > 0 && copy[index]) {
                copy[index].page = action?.payload?.page;
                copy[index].pageSize = action?.payload?.pageSize;
            }
            else {
                copy?.push({ key: action?.payload?.key, page: action?.payload?.page, pageSize: action?.payload?.pageSize })
            }
            return Object.assign({}, state, {
                currentPages: [...copy],
            });

        case "SET_PAGE_DETAILS":

            let copyPageDetails = [...state?.pageDetails];
            let pageIndex = copyPageDetails?.findIndex(item => item?.page === action.payload.page);

            if (pageIndex != -1 && copyPageDetails?.length > 0 && copyPageDetails[pageIndex]) {
                copyPageDetails[pageIndex].currentPage = action?.payload?.currentPage;
                copyPageDetails[pageIndex].totalPages = action?.payload?.totalPages;
                copyPageDetails[pageIndex].totalRecords = action?.payload?.totalRecords;
            }
            else {
                copyPageDetails?.push({
                    page: action?.payload?.page,
                    currentPage: action?.payload?.currentPage,
                    totalPages: action?.payload?.totalPages,
                    totalRecords: action?.payload?.totalRecords,
                })
            }
            return Object.assign({}, state, {
                pageDetails: [...copyPageDetails],
            });

        case SET_SEARCH_VALUE:
            return Object.assign({}, state, {
                searchValue: action?.payload?.searchValue,
            });
        case SET_IS_EDITING:
            let copyEdit = [...state?.isEditing];
            let indexEdit = copyEdit?.findIndex(page => page?.key === action.payload.page);

            if (indexEdit != -1 && copyEdit?.length > 0 && copyEdit[indexEdit]) {
                copyEdit[indexEdit].key = action?.payload?.page;
                copyEdit[indexEdit].isEditing = action?.payload?.isEditing;
            }
            else {
                copyEdit?.push({ key: action?.payload?.page, isEditing: action?.payload?.isEditing })
            }
            return Object.assign({}, state, {
                isEditing: [...copyEdit],
            });
        case SAVE_SORT_VALUE:
            return Object.assign({}, state, {
                sortValue: action?.payload?.sortValue,
            });
        case SET_FILTER_CHANGED:
            return Object.assign({}, state, {
                filterChanged: action?.payload?.filterChanged,
            });
        case SAVE_FILTER_DATA_SOURCE:
            return Object.assign({}, state, {
                filterDataSource: action?.payload?.filterDataSource,
            });

        default:
            return state;
    }
}

export default branch;
