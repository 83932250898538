import {
	getAllPayments,
    addPayment,
    updatePayment,
    deletePayment,
    getPayment,
	getAllPaymentsList
} from "../../config/config";

import { toast } from "react-toastify";
export const GET_PAYMENTS_LIST = "GET_PAYMENTS_LIST";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";

export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";

export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";

export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";

export const UPLOAD_PAYMENT_LOGO = "UPLOAD_PAYMENT_LOGO";

const actions = {
	
	//GET PAYMENTS
	getPayments: () => (dispatch) => {
		dispatch({
			type: GET_PAYMENTS,
			payload: {
				getPaymentsLoading: true
			},
		});
		getAllPayments().then((res) => {

			dispatch({
				type: GET_PAYMENTS,
				payload: {
					getPaymentsLoading: false
				},
			});

			if (res?.status === 200) {
				
				dispatch({
					type: GET_PAYMENTS_SUCCESS,
					payload: {
						payments: res?.data?.paymentsType,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//GET PAYMENTS LIST
	getPaymentsList: () => (dispatch) => {
		
		getAllPaymentsList().then((res) => {

			if (res?.status === 200) {
				
				dispatch({
					type: GET_PAYMENTS_LIST,
					payload: {
						paymentsList: res?.data?.paymentsType,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				// window.location = "/forbidden";
				toast?.error("Payments list")
			}
		});
	},

	//GET PAYMENT
	getPayment: (id) => (dispatch) => {
		dispatch({
			type: GET_PAYMENT,
			payload: {
				getPaymentLoading: true,
			},
		});

		getPayment(id).then((res) => {

			dispatch({
				type: GET_PAYMENT,
				payload: {
					getPaymentLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_PAYMENT_SUCCESS,
					payload: {
						currentPayment: res?.data?.paymentType,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//ADD PAYMENT
	addPayment: (data) => (dispatch) => {
		dispatch({
			type: ADD_PAYMENT,
			payload: {
				addPaymentLoading: true,
			},
		});

		addPayment(data).then((res) => {
			dispatch({
				type: ADD_PAYMENT,
				payload: {
					addPaymentLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_PAYMENT_SUCCESS,
					payload: {
						payments: res?.data?.paymentsType,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE PAYMENT
	updatePayment: (data, id) => (dispatch) => {
		dispatch({
			type: UPDATE_PAYMENT,
			payload: {
				updatePaymentLoading: true,
			},
		});

		updatePayment(data, id).then((res) => {
			dispatch({
				type: UPDATE_PAYMENT,
				payload: {
					updatePaymentLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_PAYMENT_SUCCESS,
					payload: {
						payments: res?.data?.paymentsType,
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE PAYMENT
	deletePayment: (id) => (dispatch) => {
		dispatch({
			type: DELETE_PAYMENT,
			payload: {
				deletePaymentLoading: true,
			},
		});
		deletePayment(id).then((res) => {
			dispatch({
				type: DELETE_PAYMENT,
			payload: {
				deletePaymentLoading: false,
			},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_PAYMENT_SUCCESS,
					payload: {
						payments: res?.data?.paymentsType,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	uploadPaymentLogo: (data) => (dispatch) => {
		dispatch(
			{
				type: UPLOAD_PAYMENT_LOGO,
				payload: {
					logoUrl: data,
				},
			}
		)
	},
};

export default actions;
