
import {
	UPDATE_SERVICE_CALL_DELIVERY,
	UPDATE_SERVICE_CALL_DELIVERY_SUCCESS,
	DELETE_SERVICE_COMMENT_SUCCESS,
	SAVE_SERVICE_CHECKLIST,
	SAVE_SERVICE_TEMP_TASKS,
	SET_SERVICE_EDIT_ID,
	SET_SERVICE_EDIT_VISIBLE,
	SET_SERVICE_CHECK_DATA,
	SET_SERVICE_CHECK_VISIBLE,
	ADD_SERVICE_CALL_SUCCESS,
	ADD_SERVICE_CALL,
	GET_ALL_SERVICE_CALLS_FILTER,
	GET_ALL_SERVICE_CALLS_FILTER_SUCCESS,
	GET_ALL_SERVICE_CALLS_SUCCESS,
	GET_ALL_CALLS_FILTER_SUCCESS,
	GET_ALL_CALLS_FILTER,
	GET_ALL_SERVICE_CALLS,
	GET_SERVICE_CALL_DELIVERY,
	GET_SERVICE_CALL_DELIVERY_SUCCESS,
	GET_SERVICE_CALL_SUCCESS,
	GET_SERVICE_CALL,
	UPDATE_SERVICE_CALL_SUCCESS,
	UPDATE_SERVICE_CALL,
	DELETE_SERVICE_CALL_SUCCESS,
	ADD_SERVICE_CALL_TYPE_SUCCESS,
	ADD_SERVICE_CALL_TYPE,
	GET_ALL_SERVICE_CALL_TYPE_SUCCESS,
	GET_ALL_SERVICE_CALL_TYPE,
	GET_SERVICE_CALL_TYPE_SUCCESS,
	GET_SERVICE_CALL_TYPE,
	UPDATE_SERVICE_CALL_TYPE_SUCCESS,
	UPDATE_SERVICE_CALL_TYPE,
	DELETE_SERVICE_CALL_TYPE_SUCCESS,
	ADD_SERVICE_CALL_STATUS_SUCCESS,
	ADD_SERVICE_CALL_STATUS,
	GET_ALL_SERVICE_CALL_STATUS_SUCCESS,
	GET_ALL_SERVICE_CALL_STATUS,
	GET_SERVICE_CALL_STATUS_SUCCESS,
	GET_SERVICE_CALL_STATUS,
	UPDATE_SERVICE_CALL_STATUS_SUCCESS,
	UPDATE_SERVICE_CALL_STATUS,
	DELETE_SERVICE_CALL_STATUS_SUCCESS,
	DELETE_SERVICE_CALL,
	DELETE_SERVICE_CALL_STATUS,
	DELETE_SERVICE_CALL_TYPE,
	GET_ALL_SERVICE_CALL_STATUS_LIST,
	GET_ALL_SERVICE_CALL_TYPE_LIST,
	GET_ALL_SERVICE_CALL_CATEGORY,
	GET_SERVICE_CALL_CATEGORY,
	UPDATE_SERVICE_CALL_CATEGORY,
	DELETE_SERVICE_CALL_CATEGORY,
	ADD_SERVICE_CALL_CATEGORY,
	GET_ALL_SERVICE_CALL_CATEGORY_SUCCESS,
	GET_SERVICE_CALL_CATEGORY_SUCCESS,
	UPDATE_SERVICE_CALL_CATEGORY_SUCCESS,
	DELETE_SERVICE_CALL_CATEGORY_SUCCESS,
	ADD_SERVICE_CALL_CATEGORY_SUCCESS,
	GET_ALL_SERVICE_CALL_CATEGORY_LIST,
	GET_ALL_SERVICE_CALL_TYPE_COUNT,
	GET_SERVICE_COMMENTS,
	GET_SERVICE_COMMENTS_SUCCESS,
	UPDATE_SERVICE_COMMENTS_SUCCESS,
	UPDATE_SERVICE_COMMENTS,
	ADD_SERVICE_COMMENTS,
	ADD_SERVICE_COMMENTS_SUCCESS,
	DELETE_SERVICE_COMMENT,
	GET_CUSTOMER_SERVICE_CALLS,
	GET_CUSTOMER_SERVICE_CALLS_SUCCESS
} from "../actions/ServiceCalls.action";
const RESET_LOADING = "RESET_LOADING"
const initialState = {
	deleteCommentLoading: false,
	getCommentsLoading: false,
	addCommentLoading: false,
	updateCommentsLoading: false,
	addServiceCallLoading: false,
	updateServiceCallLoading: false,
	getServiceCallLoading: false,
	getServiceCallsLoading: false,
	getServiceCallDeliveryLoading: false,
	getServiceCallsFilterLoading: false,
	deleteServiceCallLoading: false,

	addServiceCallCategoryLoading: false,
	updateServiceCallCategoryLoading: false,
	getServiceCallCategoryLoading: false,
	getServiceCallCategoriesLoading: false,
	deleteServiceCallCategoryLoading: false,
	getCustomerServiceCallsLoading: false,

	addServiceCallTypeLoading: false,
	updateServiceCallTypeLoading: false,
	getServiceCallTypeLoading: false,
	getServiceCallTypesLoading: false,
	deleteServiceCallTypeLoading: false,

	addServiceCallStatusLoading: false,
	updateServiceCallStatusLoading: false,
	getServiceCallStatusLoading: false,
	getServiceCallStatusesLoading: false,
	deleteServiceCallStatusLoading: false,
	getCallsFilterLoading: false,
	updateServiceCallDeliveryLoading:false,

	customerServiceCalls: [],
	comments: [],
	serviceCalls: [],
	serviceCallDelivery: [],
	addedServiceCall: "",
	groupedServiceCalls: [],
	currentServiceCall: "",
	currentServiceCallCategory: "",
	serviceCallTypes: [],
	serviceCallCategories: [],
	serviceCallTypesList: [],
	serviceCallCategoriesList: [],
	currentServiceCallType: "",
	serviceCallStatus: [],
	serviceCallStatusList: [],
	currentServiceCallStatus: "",
	allServiceCalls: {
		oldServiceCalls: [],
		newServiceCalls: [],
		groupedTypes: {},
		groupedServiceCalls: {}
	},
	addedType: "",
	addedStatus: "",
	addedCategory: "",
	checklist: [],
	tempTasks: {},
	editId: null,
	serviceEditVisible: false,
	checkData: null,
	checkVisible: false,
	serviceCallTypesCount: [],
	dashboardGroupedSalesCalls: [],
	dashboardGroupedServiceCalls: [],
	dashboardSalesCalls: [],
	dashboardServiceCalls: [],

	newServiceCalls: [],
	oldServiceCalls: [],

};

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {

				deleteCommentLoading: false,
				getCommentsLoading: false,
				addCommentLoading: false,
				updateCommentsLoading: false,
				addServiceCallLoading: false,
				getServiceCallDeliveryLoading: false,
				updateServiceCallLoading: false,
				getServiceCallLoading: false,
				getServiceCallsLoading: false,
				getServiceCallsFilterLoading: false,
				deleteServiceCallLoading: false,
				updateServiceCallDeliveryLoading:false,

				addServiceCallCategoryLoading: false,
				updateServiceCallCategoryLoading: false,
				getServiceCallCategoryLoading: false,
				getServiceCallCategoriesLoading: false,
				deleteServiceCallCategoryLoading: false,
				getCustomerServiceCallsLoading: false,

				addServiceCallTypeLoading: false,
				updateServiceCallTypeLoading: false,
				getServiceCallTypeLoading: false,
				getServiceCallTypesLoading: false,
				deleteServiceCallTypeLoading: false,

				addServiceCallStatusLoading: false,
				updateServiceCallStatusLoading: false,
				getServiceCallStatusLoading: false,
				getServiceCallStatusesLoading: false,
				deleteServiceCallStatusLoading: false,
				getCallsFilterLoading: false,

				serviceEditVisible: false,
				checkVisible: false,
				editId: null,
				checkData: null
			})
		case ADD_SERVICE_CALL_SUCCESS:
			return Object.assign({}, state, {
				serviceCalls: action.payload.serviceCalls,
				groupedServiceCalls: action.payload.groupedServiceCalls,
				addedServiceCall: action.payload.addedServiceCall

			});
		case ADD_SERVICE_CALL:
			return Object.assign({}, state, {
				addServiceCallLoading: action.payload.addServiceCallLoading,
			});

		case GET_ALL_SERVICE_CALLS_FILTER_SUCCESS:
			let oldCalls = [];
			let newCalls = [];

			if (action.payload.page === 1 || !action.payload.view) {
				oldCalls = [];
				// newCalls = [...action.payload.serviceCalls]
			}
			else {
				oldCalls = [...state.allServiceCalls.newServiceCalls];
				// newCalls = [...state.allServiceCalls.oldServiceCalls, ...action.payload.serviceCalls]
			}
			return Object.assign({}, state, {
				allServiceCalls: {
					newServiceCalls: action.payload.serviceCalls,
					oldServiceCalls: [...oldCalls],
					grouped: action.payload.groupedServiceCalls,
					groupedTypes: action.payload.groupedTypes,
					totalRecords: action.payload.totalRecords,
					totalPages: action.payload.totalPages,
					page: action.payload.page,
				}
			});

		case GET_ALL_SERVICE_CALLS_FILTER:
			return Object.assign({}, state, {
				getServiceCallsFilterLoading: action.payload.getServiceCallsFilterLoading
			});
		case GET_ALL_SERVICE_CALLS_SUCCESS:
			return Object.assign({}, state, {
				serviceCalls: action.payload.serviceCalls,
				currentServiceCall: "",
				groupedServiceCalls: action.payload.groupedServiceCalls,
			});

		case GET_ALL_SERVICE_CALLS:
			return Object.assign({}, state, {
				getServiceCallsLoading: action.payload.getServiceCallsLoading
			});
		case GET_ALL_CALLS_FILTER_SUCCESS:
			return Object.assign({}, state, {
				serviceCalls: action.payload.serviceCalls,
				groupedServiceCalls: action.payload.groupedServiceCalls,
			});

		case GET_ALL_CALLS_FILTER:
			return Object.assign({}, state, {
				getCallsFilterLoading: action.payload.getCallsFilterLoading
			});

		case GET_SERVICE_CALL_SUCCESS:
			return Object.assign({}, state, {
				currentServiceCall: action.payload.currentServiceCall,
			});

		case GET_SERVICE_CALL:
			return Object.assign({}, state, {
				getServiceCallLoading: action.payload.getServiceCallLoading
			});

		case GET_SERVICE_CALL_DELIVERY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallDelivery: action.payload.serviceCallDelivery,
			});

		case GET_SERVICE_CALL_DELIVERY:
			return Object.assign({}, state, {
				getServiceCallDeliveryLoading: action.payload.getServiceCallDeliveryLoading
			});
		case GET_CUSTOMER_SERVICE_CALLS_SUCCESS:
			return Object.assign({}, state, {
				customerServiceCalls: action.payload.customerServiceCalls,
			});

		case GET_CUSTOMER_SERVICE_CALLS:
			return Object.assign({}, state, {
				getCustomerServiceCallsLoading: action.payload.getCustomerServiceCallsLoading
			});

		case UPDATE_SERVICE_CALL_DELIVERY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallDelivery: action.payload.serviceCallDelivery,
			});

		case UPDATE_SERVICE_CALL_DELIVERY:
			return Object.assign({}, state, {
				updateServiceCallDeliveryLoading: action.payload.updateServiceCallDeliveryLoading
			});
		case UPDATE_SERVICE_CALL_SUCCESS:
			return Object.assign({}, state, {
				serviceCalls: action.payload.serviceCalls,
				groupedServiceCalls: action.payload.groupedServiceCalls,
				addedServiceCall: action.payload.addedServiceCall
			});

		case UPDATE_SERVICE_CALL:
			return Object.assign({}, state, {
				updateServiceCallLoading: action.payload.updateServiceCallLoading
			});

		case DELETE_SERVICE_CALL_SUCCESS:
			return Object.assign({}, state, {
				serviceCalls: action.payload.serviceCalls,
				groupedServiceCalls: action.payload.groupedServiceCalls,
			});
		case DELETE_SERVICE_CALL:
			return Object.assign({}, state, {
				deleteServiceCallLoading: action.payload.deleteServiceCallLoading,
			});

		case ADD_SERVICE_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				serviceCallTypes: action.payload.serviceCallTypes,
				addedType: action.payload.addedType
			});
		case ADD_SERVICE_CALL_TYPE:
			return Object.assign({}, state, {
				addServiceCallTypeLoading: action.payload.addServiceCallTypeLoading
			});
		case GET_ALL_SERVICE_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				serviceCallTypes: action.payload.serviceCallTypes,
				currentServiceCallType: "",

			});

		case GET_ALL_SERVICE_CALL_TYPE_LIST:
			return Object.assign({}, state, {
				serviceCallTypesList: action.payload.serviceCallTypesList,
				currentServiceCallType: "",

			});
		case GET_ALL_SERVICE_CALL_TYPE_COUNT:
			return Object.assign({}, state, {
				serviceCallTypesCount: action.payload.serviceCallTypesCount,


			});

		case GET_SERVICE_COMMENTS:
			return Object.assign({}, state, {
				getCommentsLoading: action.payload.getCommentsLoading

			});

		case GET_SERVICE_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case UPDATE_SERVICE_COMMENTS:
			return Object.assign({}, state, {
				updateCommentsLoading: action.payload.updateCommentsLoading

			});

		case UPDATE_SERVICE_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case DELETE_SERVICE_COMMENT:
			return Object.assign({}, state, {
				deleteCommentLoading: action.payload.deleteCommentLoading

			});

		case DELETE_SERVICE_COMMENT_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});

		case ADD_SERVICE_COMMENTS:
			return Object.assign({}, state, {
				addCommentsLoading: action.payload.addCommentsLoading

			});

		case ADD_SERVICE_COMMENTS_SUCCESS:
			return Object.assign({}, state, {
				comments: action.payload.comments

			});
		case GET_ALL_SERVICE_CALL_TYPE:
			return Object.assign({}, state, {
				getServiceCallTypesLoading: action.payload.getServiceCallTypesLoading
			});
		case SAVE_SERVICE_CHECKLIST:
			return Object.assign({}, state, {
				checklist: action.payload.checklist
			});
		case SET_SERVICE_EDIT_ID:
			return Object.assign({}, state, {
				editId: action.payload.editId
			});
		case SET_SERVICE_EDIT_VISIBLE:
			return Object.assign({}, state, {
				serviceEditVisible: action.payload.editVisible
			});
		case SET_SERVICE_CHECK_DATA:
			return Object.assign({}, state, {
				checkData: action.payload.checkData
			});
		case SET_SERVICE_CHECK_VISIBLE:
			return Object.assign({}, state, {
				checkVisible: action.payload.checkVisible
			});
		case SAVE_SERVICE_TEMP_TASKS:
			return Object.assign({}, state, {
				tempTasks: action.payload.tasks
			});

		case GET_SERVICE_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				currentServiceCallType: action.payload.currentServiceCallType,
			});
		case GET_SERVICE_CALL_TYPE:
			return Object.assign({}, state, {
				getServiceCallTypeLoading: action.payload.getServiceCallTypeLoading
			});

		case UPDATE_SERVICE_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				serviceCallTypes: action.payload.serviceCallTypes,
			});

		case UPDATE_SERVICE_CALL_TYPE:
			return Object.assign({}, state, {
				updateServiceCallTypeLoading: action.payload.updateServiceCallTypeLoading
			});

		case DELETE_SERVICE_CALL_TYPE_SUCCESS:
			return Object.assign({}, state, {
				serviceCallTypes: action.payload.serviceCallTypes,
			});
		case DELETE_SERVICE_CALL_TYPE:
			return Object.assign({}, state, {
				deleteServiceCallTypeLoading: action.payload.deleteServiceCallTypeLoading,
			});

		case ADD_SERVICE_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				serviceCallStatus: action.payload.serviceCallStatus,
				addedStatus: action.payload.addedStatus
			});

		case ADD_SERVICE_CALL_STATUS:
			return Object.assign({}, state, {
				addServiceCallStatusLoading: action.payload.addServiceCallStatusLoading
			});

		case GET_ALL_SERVICE_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				serviceCallStatus: action.payload.serviceCallStatus,
				currentServiceCallStatus: "",
			});

		case GET_ALL_SERVICE_CALL_STATUS_LIST:
			return Object.assign({}, state, {
				serviceCallStatusList: action.payload.serviceCallStatusList,
				currentServiceCallStatus: "",
			});

		case GET_ALL_SERVICE_CALL_STATUS:
			return Object.assign({}, state, {
				getServiceCallStatusesLoading: action.payload.getServiceCallStatusesLoading,
			});


		case GET_SERVICE_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				currentServiceCallStatus: action.payload.currentServiceCallStatus,
			});

		case GET_SERVICE_CALL_STATUS:
			return Object.assign({}, state, {
				getServiceCallStatusLoading: action.payload.getServiceCallStatusLoading
			});

		case UPDATE_SERVICE_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				serviceCallStatus: action.payload.serviceCallStatus,
			});

		case UPDATE_SERVICE_CALL_STATUS:
			return Object.assign({}, state, {
				updateServiceCallStatusLoading: action.payload.updateServiceCallStatusLoading
			});

		case DELETE_SERVICE_CALL_STATUS_SUCCESS:
			return Object.assign({}, state, {
				serviceCallStatus: action.payload.serviceCallStatus,
			});
		case DELETE_SERVICE_CALL_STATUS:
			return Object.assign({}, state, {
				deleteServiceCallStatusLoading: action.payload.deleteServiceCallStatusLoading,
			});



		case ADD_SERVICE_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallCategories: action.payload.serviceCallCategories,
				addedCategory: action.payload.addedCategory
			});
		case ADD_SERVICE_CALL_CATEGORY:
			return Object.assign({}, state, {
				addServiceCallCategoryLoading: action.payload.addServiceCallCategoryLoading
			});
		case GET_ALL_SERVICE_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallCategories: action.payload.serviceCallCategories,
				currentServiceCallCategory: "",

			});

		case GET_ALL_SERVICE_CALL_CATEGORY_LIST:
			return Object.assign({}, state, {
				serviceCallCategoriesList: action.payload.serviceCallCategoriesList,

			});
		case GET_ALL_SERVICE_CALL_CATEGORY:
			return Object.assign({}, state, {
				getServiceCallCategoriesLoading: action.payload.getServiceCallCategoriesLoading
			});

		case GET_SERVICE_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				currentServiceCallCategory: action.payload.currentServiceCallCategory,
			});
		case GET_SERVICE_CALL_CATEGORY:
			return Object.assign({}, state, {
				getServiceCallCategoryLoading: action.payload.getServiceCallCategoryLoading
			});

		case UPDATE_SERVICE_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallCategories: action.payload.serviceCallCategories,
			});

		case UPDATE_SERVICE_CALL_CATEGORY:
			return Object.assign({}, state, {
				updateServiceCallCategoryLoading: action.payload.updateServiceCallCategoryLoading
			});

		case DELETE_SERVICE_CALL_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				serviceCallCategories: action.payload.serviceCallCategories,
			});
		case DELETE_SERVICE_CALL_CATEGORY:
			return Object.assign({}, state, {
				deleteServiceCallCategoryLoading: action.payload.deleteServiceCallCategoryLoading
			});
		default:
			return state;
	}
}

export default branch;
