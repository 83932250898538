import {
    GET_EMAIL_DESTINATIONS,
    GET_EMAIL_DESTINATIONS_SUCCESS,
    GET_EMAIL_SERVER,
    GET_EMAIL_SERVER_SUCCESS,
    GET_REQUESTS,
    GET_REQUESTS_SUCCESS,
    UPDATE_REQUEST,
    UPDATE_REQUEST_SUCCESS,
    ADD_EMAIL_DESTINATIONS,
    ADD_EMAIL_SERVER,
    ADD_EMAIL_SERVER_SUCCESS,
    ADD_EMAIL_DESTINATIONS_SUCCESS,
    GET_REQUESTS_COUNT
} from "../actions/Requests.action";
const RESET_LOADING = "RESET_LOADING"
const initialState = {

    addServerLoading: false,
    addDestinationsLoading: false,
    getRequestsLoading: false,
    updateRequestLoading: false,
    getServerLoading: false,
    getDestinationsLoading: false,

    requests: [],
    emailServer: {},
    emailDestinations: {},
    requestsCount:0

};

function branch(state = initialState, action) {
    switch (action.type) {

        case RESET_LOADING:
            return Object.assign({}, state, {
                addServerLoading: false,
                addDestinationsLoading: false,
                getRequestsLoading: false,
                updateRequestLoading: false,
                getServerLoading: false,
                getDestinationsLoading: false,
            })

        case GET_REQUESTS:
            return Object.assign({}, state, {
                getRequestsLoading: action.payload.getRequestsLoading,

            });
        case GET_REQUESTS_COUNT:
            return Object.assign({}, state, {
                requestsCount: action.payload.requestsCount,

            });
        case GET_REQUESTS_SUCCESS:
            return Object.assign({}, state, {
                requests: action.payload.requests,

            });


        case UPDATE_REQUEST:
            return Object.assign({}, state, {
                updateRequestLoading: action.payload.updateRequestLoading,

            });
        case UPDATE_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                requests: action.payload.requests,

            });


        case GET_EMAIL_SERVER:
            return Object.assign({}, state, {
                getServerLoading: action.payload.getServerLoading,

            });
        case GET_EMAIL_SERVER_SUCCESS:
            return Object.assign({}, state, {
                emailServer: action.payload.emailServer,

            });
        case ADD_EMAIL_SERVER:
            return Object.assign({}, state, {
                addServerLoading: action.payload.addServerLoading
            });

        case ADD_EMAIL_SERVER_SUCCESS:
            return Object.assign({}, state, {
                emailServer: action.payload.emailServer,
            });

        case GET_EMAIL_DESTINATIONS:
            return Object.assign({}, state, {
                getDestinationsLoading: action.payload.getDestinationsLoading,

            });
        case GET_EMAIL_DESTINATIONS_SUCCESS:
            return Object.assign({}, state, {
                emailDestinations: action.payload.emailDestinations,

            });
        case ADD_EMAIL_DESTINATIONS:
            return Object.assign({}, state, {
                addDestinationsLoading: action.payload.addDestinationsLoading
            });
        case ADD_EMAIL_DESTINATIONS_SUCCESS:
            return Object.assign({}, state, {
                emailDestinations: action.payload.emailDestinations
            });

        default:
            return state;
    }
}

export default branch;
