import {
    SAVE_ALL_FILTERS
} from "../actions/Filter.actions";

const initialState = {
    allFilters: {}
};

function branch(state = initialState, action) {
    switch (action.type) {

        case SAVE_ALL_FILTERS:

            let copy = { ...state?.allFilters };
            if (action?.payload?.page) {
                if (!copy?.hasOwnProperty(action?.payload?.page)) {
                    copy[action?.payload?.page] = {};
                }
                for (let property in action?.payload?.value) {
                    copy[action?.payload?.page][property] = action?.payload?.value[property];
                }
            }
            return Object.assign({}, state, {
                allFilters: copy,
            });


        default:
            return state;
    }
}

export default branch;
