import {
	getAllReceipts,
	getCustomerReceipts,
	addReceipt,
	updateReceipt,
	deleteReceipt,
	getReceipt
} from "../../config/config";

import { toast } from "react-toastify";

export const GET_RECEIPTS = "GET_RECEIPTS";
export const GET_RECEIPTS_SUCCESS = "GET_RECEIPTS_SUCCESS";

export const GET_CUSTOMER_RECEIPTS = "GET_CUSTOMER_RECEIPTS";
export const GET_CUSTOMER_RECEIPTS_SUCCESS = "GET_CUSTOMER_RECEIPTS_SUCCESS";

export const UPDATE_RECEIPT = "UPDATE_RECEIPT";
export const UPDATE_RECEIPT_SUCCESS = "UPDATE_RECEIPT_SUCCESS";

export const ADD_RECEIPT = "ADD_RECEIPT";
export const ADD_RECEIPT_SUCCESS = "ADD_RECEIPT_SUCCESS";

export const DELETE_RECEIPT = "DELETE_RECEIPT";
export const DELETE_RECEIPT_SUCCESS = "DELETE_RECEIPT_SUCCESS";

export const GET_RECEIPT = "GET_RECEIPT";
export const GET_RECEIPT_SUCCESS = "GET_RECEIPT_SUCCESS";

export const SET_RECEIPT_RANGE_VALUES = "SET_RECEIPT_RANGE_VALUES";

const actions = {
	setRangeValues: (data) => (dispatch) => {

		dispatch({ type: SET_RECEIPT_RANGE_VALUES, payload: { rangeValues: data } })
	},

	//GET RECEIPTS
	getAllReceipts: (from, to) => (dispatch) => {
		dispatch({
			type: GET_RECEIPTS,
			payload: {
				getReceiptsLoading: true
			},
		});
		getAllReceipts(from, to).then((res) => {

			dispatch({
				type: GET_RECEIPTS,
				payload: {
					getReceiptsLoading: false
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: GET_RECEIPTS_SUCCESS,
					payload: {
						receipts: res?.data?.receipts,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//GET CUSTOMER RECEIPTS
	getCustomerReceipts: (id, from, to) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_RECEIPTS,
			payload: {
				getCustomerReceiptsLoading: true
			},
		});
		getCustomerReceipts(id, from, to).then((res) => {

			dispatch({
				type: GET_CUSTOMER_RECEIPTS,
				payload: {
					getCustomerReceiptsLoading: false
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: GET_CUSTOMER_RECEIPTS_SUCCESS,
					payload: {
						customerReceipts: res?.data?.receipts,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//GET RECEIPT
	getReceipt: (id) => (dispatch) => {
		dispatch({
			type: GET_RECEIPT,
			payload: {
				getReceiptLoading: true,
			},
		});

		getReceipt(id).then((res) => {

			dispatch({
				type: GET_RECEIPT,
				payload: {
					getReceiptLoading: false,
				},
			});
			if (res?.status === 200) {

				dispatch({
					type: GET_RECEIPT_SUCCESS,
					payload: {
						currentReceipt: res?.data?.receipt,
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			}
		});
	},

	//ADD RECEIPT
	addReceipt: (data) => (dispatch) => {
		dispatch({
			type: ADD_RECEIPT,
			payload: {
				addReceiptLoading: true,
			},
		});

		addReceipt(data).then((res) => {
			dispatch({
				type: ADD_RECEIPT,
				payload: {
					addReceiptLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_RECEIPT_SUCCESS,
					payload: {
						receipts: res?.data?.receipts,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE RECEIPT
	updateReceipt: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_RECEIPT,
			payload: {
				updateReceiptLoading: true,
			},
		});

		updateReceipt(data, id).then((res) => {
			dispatch({
				type: UPDATE_RECEIPT,
				payload: {
					updateReceiptLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_RECEIPT_SUCCESS,
					payload: {
						receipts: res?.data?.receipts,
					},
				});
				history.push("/receipts");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE RECEIPT
	deleteReceipt: (id, customerId) => (dispatch) => {
		dispatch({
			type: DELETE_RECEIPT,
			payload: {
				deleteReceiptLoading: true,
			},
		});
		deleteReceipt(id).then((res) => {
			dispatch({
				type: DELETE_RECEIPT,
				payload: {
					deleteReceiptLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_RECEIPT_SUCCESS,
					payload: {
						receipts: res?.data?.receipts,
					},
				});
				if (customerId)
					dispatch({
						type: GET_CUSTOMER_RECEIPTS_SUCCESS,
						payload: {
							customerReceipts: res?.data?.receipts?.filter((cust) => cust?.customer?.uuid === customerId),
						},
					});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

};

export default actions;
