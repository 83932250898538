import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import useWindowSize from '../utils/useWindowSize';
import { connect } from "react-redux";
import actions from '../redux/actions/Auth.actions';
import { routes as allRoutes } from './routes';
import requestActions from "../redux/actions/Requests.action";
import { findParentLayout } from '../utils/findParentLayout';
import NotificationIcon from '../components/Notification/NotificationIcon';

const findSelectedKey = (routes, targetRoute) => {

    const newRoutes = routes?.find((subroute) =>
        subroute?.route === targetRoute
    )
    if (newRoutes)
        return newRoutes?.key;
    return null;
};
const getValue = (data, route) => {
    const key = findSelectedKey(data, route);
    return key;
};

const TopNavigation = ({
    routes = findParentLayout(allRoutes, window.location.pathname)?.content,
    updateNavHidden,
    inside,
    getRequestsCount,
    requestsCount

}) => {
    const location = useLocation();
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const lastDiv = useRef(null);
    const firstDiv = useRef(null);
    const [selectedKey, setSelectedKey] = useState(getValue(routes, location?.pathname))
    const contentDiv = useRef(null);
    const [width] = useWindowSize();

    const options = {
        rootMargin: "0px",
        threshold: 1.0,
    }
    const [isRightIntersecting, setIsRightIntersecting] = useState(true);
    const [isLeftIntersecting, setIsLeftIntersecting] = useState(true);

    const leftObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setIsLeftIntersecting(entry.isIntersecting)

        }, options), [firstDiv?.current, options])

    const rightObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setIsRightIntersecting(entry.isIntersecting)

        }, options), [lastDiv?.current, options])

    function scrollRight() {
        if (contentDiv?.current) {
            contentDiv.current.scrollLeft += contentDiv.current.clientWidth;
        }
    }

    function scrollLeft() {
        if (contentDiv?.current) {
            contentDiv.current.scrollLeft -= contentDiv.current.clientWidth;
        }
    }

    useEffect(() => {
        setSelectedKey(getValue(routes, location?.pathname));
    }, [location?.pathname])

    useEffect(() => {
        if (inside) {
            updateNavHidden(true)
        }
    }, [inside])

    useEffect(() => {

        let requestFound = routes?.find((obj) => obj?.route === '/requests');
        if (requestFound)
            getRequestsCount()
        if (routes?.length >= 2 && width > 767) {
            leftObserver.observe(firstDiv?.current);
            rightObserver.observe(lastDiv?.current);
        }
        else {
            setIsLeftIntersecting(true);
            setIsRightIntersecting(true);
        }
        return () => {
            leftObserver.disconnect();
            rightObserver?.disconnect();
        }
    }, [routes, width])

    const renderIcon = (icon, selected, route) => {

        let fill = "var(--light-grey)";
        let stroke = "var(--light-grey)";
        if (selected) {
            stroke = "var(--primary)";
            fill = "var(--primary)"
        }

        return React.cloneElement(icon, { fill, stroke });
    };

    return (
        routes?.length > 0 ?
            <div className='tabs-container' >
                {!isLeftIntersecting && width > 767 ?
                    <LeftOutlined style={{ marginLeft: "10px" }} ref={leftRef} onClick={scrollLeft} id="menu-scroll-left" />
                    :
                    ""
                }
                <div className='tabs' ref={contentDiv}>
                    {
                        routes?.map((acc, key) =>
                            <div
                                ref={key === 0 ? firstDiv : key === routes?.length - 1 ? lastDiv : null}
                                key={acc?.key}

                            // onClick={() => handleClick(acc.key, acc?.route)}
                            >
                                <Link
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                        color: "var(--light-grey)",
                                        fontSize: "18px",
                                        cursor: "pointer",
                                        padding: "15px 0px",
                                        whiteSpace: "nowrap",
                                    }}
                                    className={selectedKey === acc?.key ? "selectedKey" : null}

                                    to={acc?.route}>
                                    {renderIcon(acc?.icon, selectedKey === acc?.key)}
                                    {acc.name}
                                    {acc?.route === '/requests' && <NotificationIcon count={requestsCount} />}
                                </Link>
                            </div>)
                    }
                </div>
                {
                    !isRightIntersecting && width > 767 ?
                        <RightOutlined onClick={scrollRight} ref={rightRef} id="menu-scroll-right" />
                        :
                        ""
                }
            </div >
            : ""

    );
};

const mapStateToProps = (state) => ({
    navHidden: state.auth.navHidden,
    requestsCount: state.requests.requestsCount
});

const mapDispatchToProps = (dispatch) => ({

    updateNavHidden: (data) => {
        dispatch(actions.updateNavHidden(data));
    },
    getRequestsCount: () => {
        dispatch(requestActions.getRequestsCount())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
