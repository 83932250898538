import { login, getMyAccessPermissions, forgotPass, getMe } from "../../config/config";

import { toast } from "react-toastify";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const MY_ACCESS_PERMISSIONS_SUCCESS = "MY_ACCESS_PERMISSIONS_SUCCESS";

export const FORGOT_REQUEST = "FORGOT_REQUEST";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_ERROR = "FORGOT_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";

export const GET_ME_SUCCESS = "GET_ME_SUCCESS";

export const UPDATE_HEADER_HIDDEN = "UPDATE_HEADER_HIDDEN";
export const UPDATE_NAV_HIDDEN = "UPDATE_NAV_HIDDEN";

export const SET_OPEN_KEYS = "SET_OPEN_KEYS";

export const SET_PREV_URL = "SET_PREV_URL";
const actions = {
	// LOGIN USER
	login: (data, history) => async (dispatch) => {
		dispatch({
			type: LOGIN_REQUEST,
		});
		await login(data).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: {
						accessToken: res?.data?.access_token,
						accessPermissions: res?.data?.settings?.actions,
						userName: res?.data?.settings?.name,
						branchExceptions:res?.data?.branchExceptions,
						allowedBranches:res?.data?.allowedBranches
					},
				});
				localStorage.setItem("access_token", res?.data?.access_token);
				history.push("/dashboard");
			} else {
				toast.error(res?.data?.message || "Login error", { theme: "colored" });
			}
		});
	},
	// LOGIN USER
	getMe: () => async (dispatch) => {

		await getMe().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ME_SUCCESS,
					payload: {
						myDetails: res?.data?.data
					},

				});

			}
		});
	},

	// GET CURRENT LOGGED IN USER ACCESS PERMISSIONS
	getMyAccessPermissions: () => async (dispatch) => {
		await getMyAccessPermissions().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: MY_ACCESS_PERMISSIONS_SUCCESS,
					payload: {
						accessPermissions: res?.data?.role?.action,
						userName: res?.data?.role?.name,
					},
				});
			}
		});
	},

	// FORGOT PASS USER
	forgotPass: (data) => async (dispatch) => {
		dispatch({
			type: FORGOT_REQUEST,
		});
		await forgotPass(data).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: FORGOT_SUCCESS,
					payload: {
						message: "Please check your email",
					},
				});
			} else {
				dispatch({
					type: FORGOT_ERROR,
					payload: {
						message: res?.data?.message || "Forgot message error",
					},
				});
			}
		});
	},

	//LOGOUT USER
	LogoutUser: () => (dispatch) => {
		dispatch({
			type: LOGOUT_USER,
		});
	},

	setPrevUrl: (data) => (dispatch) => {
		dispatch({
			type: SET_PREV_URL,
			payload: { previousUrl: data }
		});
	},
	//UPDATE HEADER
	updateHeaderHidden: (data) => (dispatch) => {
		dispatch({
			type: UPDATE_HEADER_HIDDEN,
			payload: {
				headerHidden: data,
			},
		});
	},

	//UPDATE Nav
	updateNavHidden: (data) => (dispatch) => {
		dispatch({
			type: UPDATE_NAV_HIDDEN,
			payload: {
				navHidden: data,
			},
		});
	},
	//SET OPEN KEYS
	setOpenKeys: (keys) => (dispatch) => {
		dispatch({
			type: SET_OPEN_KEYS,
			payload: {
				openKeys: keys,
			},
		});
	},
};

export default actions;
