import {
	API_REQUEST,
	ADD_WAREHOUSE_SUCCESS,
	GET_ALL_WAREHOUSES_SUCCESS,
	GET_WAREHOUSE_SUCCESS,
	UPDATE_WAREHOUSE_SUCCESS,
	DELETE_WAREHOUSE_SUCCESS,
} from "../actions/Warehouses.action";

const initialState = {
	isLoading: false,
	warehouses: [],
	currentWarehouse: "",
};

const RESET_LOADING="RESET_LOADING"

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				isLoading: false,
			})
		case API_REQUEST:
			return Object.assign({}, state, {
				isLoading: action.payload.isLoading,
			});

		case ADD_WAREHOUSE_SUCCESS:
			return Object.assign({}, state, {
				warehouses: action.payload.warehouses,
			});

		case GET_ALL_WAREHOUSES_SUCCESS:
			return Object.assign({}, state, {
				warehouses: action.payload.warehouses,
				currentWarehouse: "",
			});

		case GET_WAREHOUSE_SUCCESS:
			return Object.assign({}, state, {
				currentWarehouse: action.payload.currentWarehouse,
			});

		case UPDATE_WAREHOUSE_SUCCESS:
			return Object.assign({}, state, {
				warehouses: action.payload.warehouses,
			});

		case DELETE_WAREHOUSE_SUCCESS:
			return Object.assign({}, state, {
				warehouses: action.payload.warehouses,
			});

		default:
			return state;
	}
}

export default branch;
