import { editCustomerSchema, getAllCustomerSchema, getCustomerSchema,editCustomerSchemaProducts,addCustomerSchemaProducts,deleteCustomerSchemaProducts } from "../../config/config";
import { toast } from "react-toastify";

export const EDIT_CUSTOMER_SCHEMA_SUCCESS = "EDIT_CUSTOMER_SCHEMA_SUCCESS";
export const EDIT_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS = "EDIT_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS";
export const ADD_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS = "ADD_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS";
export const GET_CUSTOMER_ALL_SCHEMA_SUCCESS = "GET_CUSTOMER_ALL_SCHEMA_SUCCESS";
export const GET_CUSTOMER_SCHEMA_SUCCESS = "GET_CUSTOMER_SCHEMA_SUCCESS";
export const DELETE_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS = "DELETE_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS";

export const EDIT_CUSTOMER_SCHEMA = "EDIT_CUSTOMER_SCHEMA";
export const EDIT_CUSTOMER_SCHEMA_PRODUCTS= "EDIT_CUSTOMER_SCHEMA_PRODUCTS";
export const DELETE_CUSTOMER_SCHEMA_PRODUCTS= "DELETE_CUSTOMER_SCHEMA_PRODUCTS";
export const ADD_CUSTOMER_SCHEMA_PRODUCTS= "ADD_CUSTOMER_SCHEMA_PRODUCTS";
export const GET_CUSTOMER_SCHEMA = "GET_CUSTOMER_SCHEMA";
export const GET_CUSTOMER_ALL_SCHEMA = "GET_CUSTOMER_ALL_SCHEMA";

const actions = {
	//EDIT CUSTOMER SCHEMA
	editCustomerSchema: (data, id, history) => (dispatch) => {
		dispatch({
			type: EDIT_CUSTOMER_SCHEMA,
			payload: {
				editLoading: true,
			},
		});

		editCustomerSchema(data, id).then((res) => {
			dispatch({
				type: EDIT_CUSTOMER_SCHEMA,
				payload: {
					editLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: EDIT_CUSTOMER_SCHEMA_SUCCESS,
					payload: {
					allCustomersSchema: res?.data?.allCustomersSchema,
					currentCustomerSchema:res?.data?.updatedCustomer

						
					},
				});

				history.push(`/customer-schema`);
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//EDIT CUSTOMER SCHEMA PRODUCTS
	editCustomerSchemaProducts: (data, id,history) => (dispatch) => {
		dispatch({
			type: EDIT_CUSTOMER_SCHEMA_PRODUCTS,
			payload: {
				editCustomerProductsLoading: true,
			},
		});

		editCustomerSchemaProducts(data, id).then((res) => {
			dispatch({
				type: EDIT_CUSTOMER_SCHEMA_PRODUCTS,
				payload: {
					editCustomerProductsLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: EDIT_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS,
					payload: {
					allCustomersSchema: res?.data?.allCustomersSchema,
					currentCustomerSchema:res?.data?.updatedCustomer

						
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//DELETE CUSTOMER SCHEMA PRODUCTS
	deleteCustomerSchemaProducts: ( id,history) => (dispatch) => {
		dispatch({
			type: DELETE_CUSTOMER_SCHEMA_PRODUCTS,
			payload: {
				deleteCustomerProductsLoading: true,
			},
		});

		deleteCustomerSchemaProducts( id).then((res) => {
			dispatch({
				type: DELETE_CUSTOMER_SCHEMA_PRODUCTS,
				payload: {
					deleteCustomerProductsLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: DELETE_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS,
					payload: {
					allCustomersSchema: res?.data?.allCustomersSchema,
					currentCustomerSchema:res?.data?.updatedCustomer

						
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD CUSTOMER SCHEMA PRODUCTS
	addCustomerSchemaProducts: (data, id,history) => (dispatch) => {
		dispatch({
			type: ADD_CUSTOMER_SCHEMA_PRODUCTS,
			payload: {
				addCustomerProductsLoading: true,
			},
		});

		addCustomerSchemaProducts(data, id).then((res) => {
			dispatch({
				type: ADD_CUSTOMER_SCHEMA_PRODUCTS,
				payload: {
					addCustomerProductsLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS,
					payload: {
					allCustomersSchema: res?.data?.allCustomersSchema,
					currentCustomerSchema:res?.data?.updatedCustomer

						
					},
				});

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET  SINGLE CUSTOMER SCHEMA
	getCustomerSchema: (id) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_SCHEMA,
			payload: {
				getLoading: true,
			},
		});

		getCustomerSchema(id).then((res) => {
			dispatch({
				type: GET_CUSTOMER_SCHEMA,
				payload: {
					getLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_SCHEMA_SUCCESS,
					payload: {
						currentCustomerSchema: res?.data?.customer,

					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET ALL CUSTOMER SCHEMA
	getAllCustomerSchema: () => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_ALL_SCHEMA,
			payload: {
				getAllLoading: true,
			},
		});
		getAllCustomerSchema().then((res) => {
			dispatch({
				type: GET_CUSTOMER_ALL_SCHEMA,
				payload: {
					getAllLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_ALL_SCHEMA_SUCCESS,
					payload: {
						allCustomersSchema: res?.data?.allCustomersSchema,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

};

export default actions;
