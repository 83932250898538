

export const ADD_CONTACT_INFO = "ADD_CONTACT_INFO";
export const DELETE_CONTACT_INFO = "DELETE_CONTACT_INFO";
export const EDIT_CONTACT_INFO = "EDIT_CONTACT_INFO";
export const EMPTY_CONTACT_INFOS = "EMPTY_CONTACT_INFOS";
export const SET_CONTACT_INFOS = "SET_CONTACT_INFOS";
const actions = {
	
	addContactInfo: (data) => (dispatch) => {

		dispatch({type:ADD_CONTACT_INFO,payload:{contactInfo:data}});
		
	},
	editContactInfo: (data) => (dispatch) => {

		dispatch({type:EDIT_CONTACT_INFO,payload:{contactInfo:data}});
		
	},
	deleteContactInfo: (data) => (dispatch) => {

		dispatch({type:DELETE_CONTACT_INFO,payload:{key:data}});
		
	},
	emptyContactInfos: () => (dispatch) => {

		dispatch({type:EMPTY_CONTACT_INFOS});
		
	},
	setContactInfos: (data) => (dispatch) => {

		dispatch({type:SET_CONTACT_INFOS,payload:{contactInfo:data}});
		
	},

	


	
};

export default actions;
