import React from "react";
import { Button } from "antd";

// Styling
import "./ButtonControl.css";

const ButtonControl = ({
	cancel=false,
	buttonType,
	buttonSize,
	isBold,
	isDisabled,
	handleClick,
	isLoading,
	htmlType,
	buttonIcon,
	value,
	red,
	danger,
	hidden,
	black,
	style,
	width="100%",
	onKeyDown,
	tabIndex
}) => (
	<Button
		className={`main-button ${buttonType} ${buttonSize} ${isBold ? "font-bold" : null} ${black? "black" : null} ${hidden ?"hidden" : null} ${danger ? "danger" : null} ${
			isDisabled ? "btn-disabled" : null}  ${red? "red" : null} 
		 ${cancel? "cancel" : null}`}
		onClick={handleClick}
		onKeyDown={onKeyDown}
		disabled={isDisabled}
		tabIndex={tabIndex}
		loading={isLoading}
		htmlType={htmlType}
		icon={value ? buttonIcon : null}
		style={{...style,width:width}}
		
	>
		{value ? value : buttonIcon}
	</Button>
);

export default ButtonControl;
