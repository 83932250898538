export const equalArrays = (array1, array2) => {

    if (array1?.length !== array2?.length) {
        return false;
    }

    for (let i = 0; i < array1?.length; i++) {
        const obj1 = array1[i];
        const obj2 = array2[i];

        // Get the keys of the objects
        const keys1 = obj1 ? Object.keys(obj1) : [];
        const keys2 = obj2? Object.keys(obj2) : [];

        // Compare the number of keys
        if (keys1?.length !== keys2?.length) {
            return false;
        }

        // Compare each key-value pair
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    }

    return true;
};