import {
    getBusinessCards,
    getBusinessCard,
    addBusinessCard,
    updateBusinessCard,
    deleteBusinessCard
} from "../../config/config";

import { toast } from "react-toastify";

export const GET_BUSINESS_CARDS_SUCCESS = "GET_BUSINESS_CARDS_SUCCESS"
export const GET_BUSINESS_CARD_SUCCESS = "GET_BUSINESS_CARD_SUCCESS"
export const DELETE_BUSINESS_CARDS_SUCCESS = "DELETE_BUSINESS_CARDS_SUCCESS"
export const ADD_BUSINESS_CARD_SUCCESS = "ADD_BUSINESS_CARD_SUCCESS"
export const EDIT_BUSINESS_CARD_SUCCESS = "EDIT_BUSINESS_CARD_SUCCESS"

export const GET_BUSINESS_CARDS = "GET_BUSINESS_CARDS"
export const GET_BUSINESS_CARD = "GET_BUSINESS_CARD"
export const ADD_BUSINESS_CARD = "ADD_BUSINESS_CARD"
export const EDIT_BUSINESS_CARD = "EDIT_BUSINESS_CARD"
export const DELETE_BUSINESS_CARD = "DELETE_BUSINESS_CARD"

const actions = {
    // GET BUSINESS CARD
    getBusinessCards: () => (dispatch) => {
        dispatch({
            type: GET_BUSINESS_CARDS,
            payload: {
                getBusinessCardsLoading: true,
                
            },
        });
         getBusinessCards().then((res) => {
            dispatch({
                type: GET_BUSINESS_CARDS,
                payload: {
                    getBusinessCardsLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_BUSINESS_CARDS_SUCCESS,
                    payload: {
                        businessCards: res?.data?.cards,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        })
    },

    getBusinessCard: (uuid) => (dispatch) => {
        dispatch({
            type: GET_BUSINESS_CARD,
            payload: {
                getBusinessCardLoading: true,
            },
        });
        getBusinessCard(uuid).then((res) => {
            dispatch({
                type: GET_BUSINESS_CARD,
                payload: {
                    getBusinessCardLoading: false,
                },
            });
            if (res?.status === 200) {
                dispatch({
                    type: GET_BUSINESS_CARD_SUCCESS,
                    payload: {
                        businessCard: res?.data?.card,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        })
    },

    addBusinessCard: (data, history)=>  (dispatch)=> {
        dispatch({
            type: ADD_BUSINESS_CARD,
            payload: {
                addBusinessCardLoading: true,
            },
        });

        addBusinessCard(data).then((res)=>{
            dispatch({
                type: ADD_BUSINESS_CARD,
                payload: {
                    addBusinessCardLoading: false,
                },
            });
            if (res?.status === 200) {
                toast.success(res?.data?.message || "Success", { theme: "colored" });
                dispatch({
                    type: ADD_BUSINESS_CARD_SUCCESS,
                    payload: {
                        businessCards: res?.data?.cards,
                    },
                });
                history.push('/business-card')
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        })
    },

    editBusinessCard: (data,uuid, history)=> (dispatch)=> {
        dispatch({
            type: EDIT_BUSINESS_CARD,
            payload: {
                editBusinessCardLoading: true,
            },
        });
       updateBusinessCard(data, uuid).then((res)=>{
            dispatch({
                type: EDIT_BUSINESS_CARD,
                payload: {
                    editBusinessCardLoading: false,
                },
            });
            if (res?.status === 200) {
                toast.success(res?.data?.message || "Success", { theme: "colored" });
                dispatch({
                    type: EDIT_BUSINESS_CARD_SUCCESS,
                    payload: {
                        businessCards: res?.data?.cards,
                    },
                });
                history.push('/business-card')
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        })
    },

    // DELETE BUSINESS CARD
    deleteBusinessCard: (uuid) => async (dispatch) => {
       
        dispatch({
            type: DELETE_BUSINESS_CARD,
            payload: {
                deleteBusinessCardLoading: true,
            },
        });
        await deleteBusinessCard(uuid).then((res) => {
            dispatch({
                type: DELETE_BUSINESS_CARD,
                payload: {
                    deleteBusinessCardLoading: false,
                },
            });
            if (res?.status === 200) {
                toast.success(res?.data?.message || "Success", { theme: "colored" });
                dispatch({
                    type: DELETE_BUSINESS_CARDS_SUCCESS,
                    payload: {
                        businessCards: res?.data?.cards,
                    },
                });
            } else if (res?.status === 401) {
                window.location = "/session-ended";
            } else if (res?.status === 403) {
                window.location = "/forbidden";
            } else {
                toast.error(res?.data?.message || "error", { theme: "colored" });
            }
        })
    }
    

};

export default actions;
