import {

    GET_BUSINESS_CARDS_SUCCESS,
    GET_BUSINESS_CARDS,
    GET_BUSINESS_CARD_SUCCESS,
    GET_BUSINESS_CARD,
    DELETE_BUSINESS_CARDS_SUCCESS,
    ADD_BUSINESS_CARD_SUCCESS,
    ADD_BUSINESS_CARD,
    EDIT_BUSINESS_CARD_SUCCESS,
    EDIT_BUSINESS_CARD,
    DELETE_BUSINESS_CARD


} from "../actions/BusinessCard.actions";

const initialState = {
    addBusinessCardLoading: false,
    getBusinessCardLoading: false,
    editBusinessCardLoading: false,
    getBusinessCardsLoading: false,
    deleteBusinessCardLoading: false,
    businessCards: [],
    businessCard: {},

};

function branch(state = initialState, action) {
    switch (action.type) {

        case GET_BUSINESS_CARD_SUCCESS:
            return Object.assign({}, state, {
                businessCard: action.payload.businessCard,

            });

        case GET_BUSINESS_CARD:
            return Object.assign({}, state, {

                getBusinessCardLoading: action.payload.getBusinessCardLoading
            });

        case GET_BUSINESS_CARDS_SUCCESS:
            return Object.assign({}, state, {
                businessCards: action.payload.businessCards,

            });
        case GET_BUSINESS_CARDS:
            return Object.assign({}, state, {
                getBusinessCardsLoading: action.payload.getBusinessCardsLoading

            });

        case ADD_BUSINESS_CARD_SUCCESS:
            return Object.assign({}, state, {
                businessCards: action.payload.businessCards,

            });
        case ADD_BUSINESS_CARD:
            return Object.assign({}, state, {
                addBusinessCardLoading: action.payload.addBusinessCardLoading,

            });

        case EDIT_BUSINESS_CARD_SUCCESS:
            return Object.assign({}, state, {
                businessCards: action.payload.businessCards,

            });
        case EDIT_BUSINESS_CARD:
            return Object.assign({}, state, {
                editBusinessCardLoading: action.payload.editBusinessCardLoading,

            });

        case DELETE_BUSINESS_CARDS_SUCCESS:
            return Object.assign({}, state, {
                businessCards: action.payload.businessCards,

            });
            case DELETE_BUSINESS_CARD:
                return Object.assign({}, state, {
                    deleteBusinessCardLoading: action.payload.deleteBusinessCardLoading,
    
                });

        default:
            return state;
    }
}

export default branch;
