import {
	addTax,
	getAllTaxes,
	getTax,
	updateTax,
	deleteTax,
	getAllTaxesList
} from "../../config/config";

import { toast } from "react-toastify";



export const ADD_TAX_SUCCESS = "ADD_TAX_SUCCESS";
export const GET_ALL_TAXES_SUCCESS = "GET_ALL_TAXES_SUCCESS";
export const GET_TAX_SUCCESS = "GET_TAX_SUCCESS";
export const UPDATE_TAX_SUCCESS = "UPDATE_TAX_SUCCESS";
export const DELETE_TAX_SUCCESS = "DELETE_TAX_SUCCESS";


export const ADD_TAX = "ADD_TAX";
export const GET_ALL_TAXES = "GET_ALL_TAXES";
export const GET_ALL_TAXES_LIST = "GET_ALL_TAXES_LIST";
export const GET_TAX = "GET_TAX";
export const UPDATE_TAX = "UPDATE_TAX";
export const DELETE_TAX = "DELETE_TAX";

const actions = {
	//ADD TAX
	addTax: (data) => (dispatch) => {
		dispatch({
			type: ADD_TAX,
			payload: {
				addTaxLoading: true,
			},
		});

		addTax(data).then((res) => {
			dispatch({
				type: ADD_TAX,
				payload: {
					addTaxLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_TAX_SUCCESS,
					payload: {
						taxes: res?.data?.taxes,
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL TAXES
	getAllTaxes: () => (dispatch) => {
		dispatch({
			type: GET_ALL_TAXES,
			payload: {
				getTaxesLoading: true,
			},
		});
		getAllTaxes().then((res) => {
			dispatch({
				type: GET_ALL_TAXES,
				payload: {
					getTaxesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_TAXES_SUCCESS,
					payload: {
						taxes: res?.data?.taxes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL TAXES LIST
	getAllTaxesList: () => (dispatch) => {
		
		getAllTaxesList().then((res) => {
			
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_TAXES_LIST,
					payload: {
						taxesList: res?.data?.taxes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("taxes")
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET SINGLE TAX
	getTax: (id) => (dispatch) => {
		dispatch({
			type: GET_TAX,
			payload: {
				getTaxLoading: true,
			},
		});
		getTax(id).then((res) => {
			dispatch({
				type: GET_TAX,
				payload: {
					getTaxLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_TAX_SUCCESS,
					payload: {
						currentTax: res?.data?.tax,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE TAX
	updateTax: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_TAX,
			payload: {
				updateTaxLoading: true,
			},
		});
		

		updateTax(data, id).then((res) => {
			dispatch({
				type: UPDATE_TAX,
				payload: {
					updateTaxLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_TAX_SUCCESS,
					payload: {
						taxes: res?.data?.taxes,
					},
				});
				history.push("/tax");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE TAX
	deleteTax: (id) => (dispatch) => {
		dispatch({
			type: DELETE_TAX,
			payload: {
				deleteTaxLoading: true,
			},
		});
		deleteTax(id).then((res) => {
			dispatch({
				type: DELETE_TAX,
				payload: {
					deleteTaxLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_TAX_SUCCESS,
					payload: {
						taxes: res?.data?.taxes,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
};

export default actions;
