import {
	EDIT_CUSTOMER_SCHEMA_SUCCESS,
	EDIT_CUSTOMER_SCHEMA,
	GET_CUSTOMER_SCHEMA_SUCCESS,
	GET_CUSTOMER_SCHEMA,
	GET_CUSTOMER_ALL_SCHEMA_SUCCESS,
	GET_CUSTOMER_ALL_SCHEMA,
	EDIT_CUSTOMER_SCHEMA_PRODUCTS,
	EDIT_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS,
	ADD_CUSTOMER_SCHEMA_PRODUCTS,
	ADD_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS,
	DELETE_CUSTOMER_SCHEMA_PRODUCTS,
	DELETE_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS
} from "../actions/CustomerSchema.action";

const initialState = {
	getLoading: false,
	editLoading: false,
	editCustomerProductsLoading: false,
	addCustomerProductsLoading: false,
	deleteCustomerProductsLoading: false,
	getAllLoading: false,
	allCustomersSchema: [],
	currentCustomerSchema: {},
};
const RESET_LOADING="RESET_LOADING"

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				getLoading: false,
				editLoading: false,
				editCustomerProductsLoading: false,
				addCustomerProductsLoading: false,
				deleteCustomerProductsLoading: false,
				getAllLoading: false,
			})

		case GET_CUSTOMER_ALL_SCHEMA_SUCCESS:
			return Object.assign({}, state, {
				allCustomersSchema: action.payload.allCustomersSchema,
			})
		case GET_CUSTOMER_ALL_SCHEMA:
			return Object.assign({}, state, {
				getAllLoading: action.payload.getAllLoading,
			})

		case EDIT_CUSTOMER_SCHEMA_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerSchema: action.payload.currentCustomerSchema,
				allCustomersSchema: action?.payload?.allCustomersSchema
			});

		case EDIT_CUSTOMER_SCHEMA:
			return Object.assign({}, state, {
				editLoading: action.payload.editLoading,
			});

		case DELETE_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				// currentCustomerSchema: action.payload.currentCustomerSchema,
				allCustomersSchema: action?.payload?.allCustomersSchema
			});

		case DELETE_CUSTOMER_SCHEMA_PRODUCTS:
			return Object.assign({}, state, {
				deleteCustomerProductsLoading: action.payload.deleteCustomerProductsLoading,
			});

		case ADD_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerSchema: action.payload.currentCustomerSchema,
				allCustomersSchema: action?.payload?.allCustomersSchema
			});

		case ADD_CUSTOMER_SCHEMA_PRODUCTS:
			return Object.assign({}, state, {
				addCustomerProductsLoading: action.payload.addCustomerProductsLoading,
			});

		case EDIT_CUSTOMER_SCHEMA_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerSchema: action.payload.currentCustomerSchema,
				allCustomersSchema: action?.payload?.allCustomersSchema
			});

		case EDIT_CUSTOMER_SCHEMA_PRODUCTS:
			return Object.assign({}, state, {
				editCustomerProductsLoading: action.payload.editCustomerProductsLoading,
			});

		case GET_CUSTOMER_SCHEMA_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerSchema: action.payload.currentCustomerSchema,
			});
		case GET_CUSTOMER_SCHEMA:
			return Object.assign({}, state, {
				getLoading: action.payload.getLoading,
			});

		default:
			return state;
	}
}

export default branch;
